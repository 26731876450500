import { AdminApi } from '../../api/admin'
import {
    DraftPostViewMode,
    ListDraftPostsQueryDto,
    ListUsersQueryDto,
} from '../../types/admin-types'
import { AdminActionType } from './types'

export const fetchUsers = async (dispatch: React.Dispatch<any>, query: ListUsersQueryDto = {}) => {
    try {
        dispatch({ type: AdminActionType.FETCH_USERS_REQUEST })
        const response = await AdminApi.listUsers(query)
        dispatch({
            type: AdminActionType.FETCH_USERS_SUCCESS,
            payload: {
                users: response.data.users,
                total: response.data.total,
            },
        })
    } catch (error) {
        dispatch({
            type: AdminActionType.FETCH_USERS_FAILURE,
            payload: error instanceof Error ? error.message : 'Failed to fetch users',
        })
    }
}

export const selectUser = (dispatch: React.Dispatch<any>, userId: string | null) => {
    dispatch({
        type: AdminActionType.SELECT_USER,
        payload: userId,
    })
}

export const fetchDraftPosts = async (
    dispatch: React.Dispatch<any>,
    query: ListDraftPostsQueryDto = {},
) => {
    try {
        dispatch({ type: AdminActionType.FETCH_DRAFT_POSTS_REQUEST })
        const response = await AdminApi.listDraftPosts(query)
        dispatch({
            type: AdminActionType.FETCH_DRAFT_POSTS_SUCCESS,
            payload: {
                draftPosts: response.data.draft_posts,
                pagination: response.data.pagination,
            },
        })
    } catch (error) {
        dispatch({
            type: AdminActionType.FETCH_DRAFT_POSTS_FAILURE,
            payload: error instanceof Error ? error.message : 'Failed to fetch draft posts',
        })
    }
}

export const selectDraftPost = (
    dispatch: React.Dispatch<any>,
    postId: string,
    currentlySelected?: string,
) => {
    // If the same post is already selected, do nothing
    if (currentlySelected === postId) {
        return
    }

    dispatch({
        type: AdminActionType.SELECT_DRAFT_POST,
        payload: postId,
    })
}

export const fetchDraftPostDetail = async (dispatch: React.Dispatch<any>, postId: string) => {
    try {
        dispatch({ type: AdminActionType.FETCH_DRAFT_POST_DETAIL_REQUEST })
        const response = await AdminApi.getDraftPost(postId)
        dispatch({
            type: AdminActionType.FETCH_DRAFT_POST_DETAIL_SUCCESS,
            payload: response.data.draft_post,
        })
    } catch (error) {
        dispatch({
            type: AdminActionType.FETCH_DRAFT_POST_DETAIL_FAILURE,
            payload: error instanceof Error ? error.message : 'Failed to fetch draft post details',
        })
    }
}

export const setViewMode = (dispatch: React.Dispatch<any>, viewMode: DraftPostViewMode) => {
    dispatch({
        type: AdminActionType.SET_VIEW_MODE,
        payload: viewMode,
    })
}

export const setStatusFilter = (dispatch: React.Dispatch<any>, status: string | null) => {
    dispatch({
        type: AdminActionType.SET_STATUS_FILTER,
        payload: status,
    })
}

export const setSearchTerm = (dispatch: React.Dispatch<any>, searchTerm: string) => {
    dispatch({
        type: AdminActionType.SET_SEARCH_TERM,
        payload: searchTerm,
    })
}

export const setCurrentPage = (dispatch: React.Dispatch<any>, page: number) => {
    dispatch({
        type: AdminActionType.SET_CURRENT_PAGE,
        payload: page,
    })
}

export const toggleUserList = (dispatch: React.Dispatch<any>) => {
    dispatch({
        type: AdminActionType.TOGGLE_USER_LIST,
    })
}

export const addFeedback = async (
    dispatch: React.Dispatch<any>,
    postId: string,
    content: string,
) => {
    try {
        dispatch({ type: AdminActionType.ADD_FEEDBACK_REQUEST })
        const response = await AdminApi.addFeedback(postId, content)

        // Include the current timestamp with the response
        const timestamp = new Date().toISOString()

        dispatch({
            type: AdminActionType.ADD_FEEDBACK_SUCCESS,
            payload: {
                id: response.data.id,
                content: response.data.content,
                timestamp: timestamp,
            },
        })
    } catch (error) {
        dispatch({
            type: AdminActionType.ADD_FEEDBACK_FAILURE,
            payload: error instanceof Error ? error.message : 'Failed to add feedback',
        })
    }
}

export const updateStatus = async (
    dispatch: React.Dispatch<any>,
    postId: string,
    status: string,
) => {
    try {
        dispatch({ type: AdminActionType.UPDATE_STATUS_REQUEST })
        const response = await AdminApi.updateStatus(postId, status)
        dispatch({
            type: AdminActionType.UPDATE_STATUS_SUCCESS,
            payload: {
                id: response.data.id,
                status: response.data.status,
            },
        })
    } catch (error) {
        dispatch({
            type: AdminActionType.UPDATE_STATUS_FAILURE,
            payload: error instanceof Error ? error.message : 'Failed to update status',
        })
    }
}

// Bulk selection actions
export const selectDraftPostBulk = (dispatch: React.Dispatch<any>, postId: string) => {
    dispatch({
        type: AdminActionType.SELECT_DRAFT_POST_BULK,
        payload: postId,
    })
}

export const deselectDraftPostBulk = (dispatch: React.Dispatch<any>, postId: string) => {
    dispatch({
        type: AdminActionType.DESELECT_DRAFT_POST_BULK,
        payload: postId,
    })
}

export const clearBulkSelection = (dispatch: React.Dispatch<any>) => {
    dispatch({ type: AdminActionType.CLEAR_BULK_SELECTION })
}

// Bulk status update action
export const bulkUpdateStatus = async (
    dispatch: React.Dispatch<any>,
    ids: string[],
    status: string,
) => {
    if (ids.length === 0) return // Don't do anything if no IDs are selected

    try {
        dispatch({ type: AdminActionType.BULK_UPDATE_STATUS_REQUEST })
        const response = await AdminApi.bulkUpdateStatus({ ids, status })
        dispatch({
            type: AdminActionType.BULK_UPDATE_STATUS_SUCCESS,
            // Use the count from the response if available, otherwise count the input ids
            payload: { count: response.data.successful_updates ?? ids.length },
        })
    } catch (error) {
        dispatch({
            type: AdminActionType.BULK_UPDATE_STATUS_FAILURE,
            payload: error instanceof Error ? error.message : 'Failed to bulk update status',
        })
    }
}
