import { ChapterConnectionStatus, ResearchResult, TranscriptChapter } from '../../types/admin-types'
import { formatQAContent } from '../../utils/formatQAContent'
import React, { useState } from 'react'
import ReactMarkdown from 'react-markdown'

interface SourceItemProps {
    type: 'article' | 'conversation'
    title: string
    content: ResearchResult | TranscriptChapter
}

const SourceItem: React.FC<SourceItemProps> = ({ type, title, content }) => {
    const [expanded, setExpanded] = useState(false)

    const isArticle = type === 'article'
    const article = isArticle ? (content as ResearchResult) : null
    const conversation = !isArticle ? (content as TranscriptChapter) : null

    // Format date in a readable format
    const formatDate = (dateString: string) => {
        if (!dateString) return ''
        const date = new Date(dateString)
        return date.toLocaleDateString('en-US', {
            month: 'short',
            day: 'numeric',
            year: 'numeric',
        })
    }

    return (
        <div className="border border-gray-200 rounded-lg">
            <div className="flex items-center p-3">
                <div className="flex items-center mr-2">
                    <span
                        className={`inline-flex mr-2 items-center px-2 py-1 rounded-full text-xs font-medium ${
                            isArticle
                                ? 'bg-blue-100 text-blue-800'
                                : 'bg-purple-100 text-purple-800'
                        }`}
                    >
                        {isArticle ? 'Article' : 'Conversation'}
                    </span>
                    {(isArticle && article?.connection_status !== undefined) ||
                    (!isArticle && conversation?.connection_status !== undefined) ? (
                        <span
                            className={`px-2 py-1 text-xs rounded ${
                                (isArticle &&
                                    article?.connection_status === ChapterConnectionStatus.USED) ||
                                (!isArticle &&
                                    conversation?.connection_status ===
                                        ChapterConnectionStatus.USED)
                                    ? 'bg-green-100 text-green-800'
                                    : 'bg-gray-100 text-gray-800'
                            }`}
                        >
                            {(isArticle &&
                                article?.connection_status === ChapterConnectionStatus.USED) ||
                            (!isArticle &&
                                conversation?.connection_status === ChapterConnectionStatus.USED)
                                ? 'Used'
                                : 'Not Used'}
                        </span>
                    ) : null}
                </div>
                <div className="flex-1">
                    <h4 className="text-sm font-medium">{title}</h4>
                    {(isArticle && article?.id) || (!isArticle && conversation?.id) ? (
                        <p className="text-xs text-gray-500">
                            ID:{' '}
                            <span className="select-all font-mono">
                                {isArticle ? article?.id : conversation?.id}
                            </span>
                        </p>
                    ) : null}
                    {!isArticle && conversation?.meeting && (
                        <div className="mt-1">
                            <p className="text-xs font-medium text-gray-600">
                                {conversation.meeting.title || 'Untitled Meeting'}
                            </p>
                            <p className="text-xs text-gray-500">
                                {formatDate(conversation.meeting.created_at)}
                            </p>
                        </div>
                    )}
                </div>
                <button
                    onClick={() => setExpanded(!expanded)}
                    className="ml-2 text-gray-500 hover:text-gray-700"
                >
                    {expanded ? '−' : '+'}
                </button>
            </div>

            {expanded && (
                <div className="px-3 pb-3 border-t border-gray-100 pt-2">
                    <div className="text-sm text-gray-700">
                        {isArticle ? (
                            <>
                                <ReactMarkdown>{formatQAContent(article!.summary)}</ReactMarkdown>
                                <a
                                    href={article!.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-blue-500 hover:underline text-sm mt-1 block"
                                >
                                    {article!.domain || article!.url}
                                </a>
                            </>
                        ) : (
                            <>
                                <ReactMarkdown>
                                    {formatQAContent(conversation!.summary)}
                                </ReactMarkdown>
                                {conversation!.key_quotes &&
                                    conversation!.key_quotes.length > 0 && (
                                        <div className="mt-2">
                                            <h5 className="text-xs font-medium mb-1">
                                                Key Quotes:
                                            </h5>
                                            <ul className="list-disc pl-4 space-y-1">
                                                {conversation!.key_quotes.map((quote, index) => (
                                                    <li
                                                        key={index}
                                                        className="text-sm text-gray-700"
                                                    >
                                                        {quote}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    )}

                                {!isArticle && conversation?.meeting && (
                                    <div className="mt-3 pt-2 border-t border-gray-100">
                                        <h5 className="text-xs font-medium mb-1">
                                            Meeting Details:
                                        </h5>
                                        {conversation.meeting.attendees &&
                                            conversation.meeting.attendees.length > 0 && (
                                                <div className="mt-1">
                                                    <h6 className="text-xs font-medium">
                                                        Attendees:
                                                    </h6>
                                                    <ul className="list-disc pl-4 space-y-0.5">
                                                        {conversation.meeting.attendees.map(
                                                            (attendee, index) => (
                                                                <li
                                                                    key={index}
                                                                    className="text-xs text-gray-600"
                                                                >
                                                                    {attendee}
                                                                </li>
                                                            ),
                                                        )}
                                                    </ul>
                                                </div>
                                            )}
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </div>
            )}
        </div>
    )
}

export default SourceItem
