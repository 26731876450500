import { formatDateTime, formatTime, isToday } from '../../lib/utils'
import type { Meeting } from '../../types/MeetingDashboard'
import MeetingDetailsModal from './meeting-details-modal'
import MeetingStatusBadge from './meeting-status-badge'
import { Card, Table, Button, Badge, Pagination } from 'flowbite-react'
import { AlertCircle, Filter, Search, Clock, CheckCircle, XCircle } from 'lucide-react'
import { useState } from 'react'

;('use client')

interface TroubleTicketsProps {
    meetings: Meeting[]
}

export default function TroubleTickets({ meetings }: TroubleTicketsProps) {
    const [currentPage, setCurrentPage] = useState(1)
    const [selectedMeeting, setSelectedMeeting] = useState<Meeting | null>(null)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [statusFilter, setStatusFilter] = useState<string>('all')
    const [searchTerm, setSearchTerm] = useState('')

    const itemsPerPage = 10

    // Filter meetings with issues
    const troubleMeetings = meetings.filter(
        (meeting) =>
            meeting.status === 'fatal' ||
            meeting.status === 'in_waiting_room' ||
            meeting.status === 'in_call_not_recording',
    )

    const filteredMeetings = troubleMeetings.filter((meeting) => {
        // Apply status filter
        if (statusFilter !== 'all') {
            if (statusFilter === 'fatal' && meeting.status !== 'fatal') return false
            if (statusFilter === 'in_waiting_room' && meeting.status !== 'in_waiting_room')
                return false
            if (
                statusFilter === 'in_call_not_recording' &&
                meeting.status !== 'in_call_not_recording'
            )
                return false
            if (
                statusFilter === 'recall_issues' &&
                (!meeting.recallStatus || meeting.recallStatus === 'done')
            )
                return false
        }

        // Apply search filter
        if (searchTerm) {
            const searchLower = searchTerm.toLowerCase()
            return (
                meeting.title.toLowerCase().includes(searchLower) ||
                meeting.host.name.toLowerCase().includes(searchLower) ||
                meeting.meeting_type.toLowerCase().includes(searchLower)
            )
        }

        return true
    })

    // Sort by most recent first
    const sortedMeetings = [...filteredMeetings].sort(
        (a, b) => new Date(b.start).getTime() - new Date(a.start).getTime(),
    )

    // Paginate
    const totalPages = Math.ceil(sortedMeetings.length / itemsPerPage)
    const currentMeetings = sortedMeetings.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage,
    )

    const openMeetingDetails = (meeting: Meeting) => {
        setSelectedMeeting(meeting)
        setIsModalOpen(true)
    }

    // Function to display date/time with "Today" label if applicable
    const displayDateTime = (dateString: string) => {
        if (isToday(dateString)) {
            return (
                <div>
                    <span className="text-xs font-medium text-blue-600 bg-blue-100 rounded px-1.5 py-0.5 mr-1">
                        Today
                    </span>
                    <span>{formatTime(dateString)}</span>
                </div>
            )
        }
        return formatDateTime(dateString)
    }

    const getIssueType = (meeting: Meeting) => {
        if (meeting.status === 'fatal') {
            if (!meeting.calendarSynced) return 'Calendar Sync Failed'
            if (meeting.status === 'fatal') return 'Recall AI Fatal Error'
            return 'Join Failed'
        }
        if (meeting.status === 'in_waiting_room') return 'Stuck in Waiting Room'
        if (meeting.status === 'pending') return 'Recall AI Pending'
        if (meeting.status === 'in_call_not_recording') return 'In Call Not Recording'
        return 'Unknown Issue'
    }

    const getIssueIcon = (meeting: Meeting) => {
        if (meeting.status === 'fatal') return <XCircle className="w-4 h-4 text-red-600" />
        if (meeting.status === 'in_waiting_room')
            return <Clock className="w-4 h-4 text-yellow-600" />
        return <AlertCircle className="w-4 h-4 text-gray-600" />
    }

    return (
        <div className="space-y-6">
            <div className="flex justify-between items-center">
                <h2 className="text-xl font-bold flex items-center">
                    <AlertCircle className="mr-2 text-red-600" />
                    Trouble Tickets
                </h2>
                <Badge color="gray" className="px-3 py-1.5">
                    {filteredMeetings.length} Issues
                </Badge>
            </div>

            {/* Filters */}
            <div className="flex flex-col md:flex-row gap-4">
                <div className="flex-1 relative">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                        <Search className="w-5 h-5 text-gray-500" />
                    </div>
                    <input
                        type="text"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        placeholder="Search meetings..."
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5"
                    />
                </div>

                <div className="flex items-center">
                    <Filter className="w-5 h-5 text-gray-500 mr-2" />
                    <select
                        value={statusFilter}
                        onChange={(e) => setStatusFilter(e.target.value)}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5"
                    >
                        <option value="all">All Issues</option>
                        <option value="fatal">Failed Joins</option>
                        <option value="in_waiting_room">Waiting Room</option>
                        <option value="in_call_not_recording">In Call Not Recording</option>
                        {/* <option value="recall_issues">Recall AI Issues</option> */}
                    </select>
                </div>
            </div>

            {/* Trouble Tickets Table */}
            {currentMeetings.length > 0 ? (
                <div className="overflow-x-auto">
                    <Table hoverable>
                        <Table.Head>
                            <Table.HeadCell>Meeting</Table.HeadCell>
                            <Table.HeadCell>Host</Table.HeadCell>
                            <Table.HeadCell>Platform</Table.HeadCell>
                            <Table.HeadCell>Date & Time</Table.HeadCell>
                            <Table.HeadCell>Issue Type</Table.HeadCell>
                            <Table.HeadCell>Status</Table.HeadCell>
                            <Table.HeadCell>Actions</Table.HeadCell>
                        </Table.Head>
                        <Table.Body className="divide-y">
                            {currentMeetings.map((meeting) => (
                                <Table.Row key={meeting.id} className="bg-white">
                                    <Table.Cell className="font-medium text-gray-900">
                                        {meeting.title}
                                    </Table.Cell>
                                    <Table.Cell>{meeting.host.name}</Table.Cell>
                                    <Table.Cell>
                                        <Badge
                                            color={
                                                meeting.meeting_type === 'Zoom'
                                                    ? 'info'
                                                    : meeting.meeting_type === 'Google Meet'
                                                    ? 'success'
                                                    : 'purple'
                                            }
                                        >
                                            {meeting.meeting_type}
                                        </Badge>
                                    </Table.Cell>
                                    <Table.Cell>{displayDateTime(meeting.start)}</Table.Cell>
                                    <Table.Cell>
                                        <div className="flex items-center">
                                            {getIssueIcon(meeting)}
                                            <span className="ml-1">{getIssueType(meeting)}</span>
                                        </div>
                                    </Table.Cell>
                                    <Table.Cell>
                                        <MeetingStatusBadge status={meeting.status} />
                                    </Table.Cell>
                                    <Table.Cell>
                                        <Button
                                            size="xs"
                                            color="light"
                                            onClick={() => openMeetingDetails(meeting)}
                                        >
                                            View Details
                                        </Button>
                                    </Table.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table>

                    {/* Pagination */}
                    {totalPages > 1 && (
                        <div className="flex justify-center mt-4">
                            <Pagination
                                currentPage={currentPage}
                                totalPages={totalPages}
                                onPageChange={setCurrentPage}
                                showIcons
                            />
                        </div>
                    )}
                </div>
            ) : (
                <Card>
                    <div className="flex flex-col items-center justify-center p-6">
                        <CheckCircle className="w-12 h-12 text-green-400 mb-2" />
                        <p className="text-gray-500">No issues found</p>
                    </div>
                </Card>
            )}

            {/* Meeting Details Modal */}
            {selectedMeeting && (
                <MeetingDetailsModal
                    meeting={selectedMeeting}
                    isOpen={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                />
            )}
        </div>
    )
}
