import { CalendarApi } from '../../api/calendar'
import { RecallAiMeetingsApi } from '../../api/recallaiMeetings'
//import { mockMeetings, mockCalendarEvents } from '../../data/meetingMockData'
import { CalendarEvent, Meeting } from '../../types/MeetingDashboard'
import CalendarSyncMonitor from './calendar-sync-monitor'
import FlowbiteTab from './flowbite-tab'
import MeetingOverview from './meeting-overview'
import TroubleTickets from './trouble-tickets'
import { Tabs } from 'flowbite-react'
import { Flowbite } from 'flowbite-react'
import { Calendar, Activity, AlertCircle } from 'lucide-react'
import { useEffect, useState } from 'react'

;('use client')

export default function MeetingsDashboard() {
    const [activeTab, setActiveTab] = useState<number>(0)
    const [meetings, setMeetings] = useState<Meeting[]>([])
    const [calendarEvents, setCalendarEvents] = useState<CalendarEvent[]>([])

    useEffect(() => {
        // Fetch calendar events data from backend
        let calendarEventsData: CalendarEvent[] = []
        CalendarApi.getCalendarEvents('toni@toneai.xyz').then((response) => {
            if (response.data) {
                calendarEventsData = response.data

                //loop through the attendees and set the 'host' to the attendee where 'organizer' is true. If none are true, set to the first attendee
                let host = null
                for (let i = 0; i < calendarEventsData.length; i++) {
                    let attendees = calendarEventsData[i].attendees
                    if (attendees && attendees.length > 0) {
                        host = attendees.find((attendee) => attendee.organizer === true)
                        if (!host) {
                            host = attendees[0]
                        }
                        calendarEventsData[i].host = host.name
                    }
                }
            }
            setCalendarEvents(calendarEventsData)
        })

        // Fetch meeting data from backend
        RecallAiMeetingsApi.getMeetingsDataForNumberOfDays('30').then((response) => {
            // eslint-disable-next-line no-console
            //console.log('RESPONSE: ', response)
            if (response.data && response.data.meetings) {
                // Convert RecallaiMeeting to Meeting
                const meetingsData: Meeting[] = response.data.meetings.map((meeting) => {
                    return {
                        id: meeting.id || '',
                        title: meeting.meeting_title || '',
                        start: meeting.join_at || meeting.created_at || '',
                        end: meeting.end_at || '',
                        status: meeting.meeting_status,
                        meeting_type: meeting.meeting_type,
                        meeting_url: meeting.meeting_url || '',
                        host: {
                            id: meeting.author_id,
                            name:
                                (meeting.author?.first_name || '') +
                                ' ' +
                                (meeting.author?.last_name || ''),
                            email: meeting.author?.email || '',
                            avatar: meeting.author?.profile_photo?.url || '',
                        },
                        calendarSynced: true,
                        backendRecord: true,
                        recallStatus: meeting.meeting_status,
                    }
                })
                if (meetingsData) {
                    // eslint-disable-next-line no-console
                    //console.log('Meetings Data: ', meetingsData)
                    setMeetings(meetingsData)
                }
            }
        })
    }, [])

    return (
        <Flowbite>
            <div className="container mx-auto px-4 py-8 h-full overflow-y-auto">
                <header className="mb-8">
                    <div className="flex justify-between items-center">
                        <h1 className="text-3xl font-bold text-gray-800">Toni Meeting Monitor</h1>
                        <div className="flex items-center gap-2">
                            <span className="px-3 py-1 bg-green-100 text-green-800 rounded-full text-sm font-medium">
                                System Status: Online
                            </span>
                        </div>
                    </div>
                    <p className="text-gray-600 mt-2">
                        Track and troubleshoot Toni's meeting attendance issues
                    </p>
                </header>

                <Tabs.Group style="underline" onActiveTabChange={(tab) => setActiveTab(tab)}>
                    <FlowbiteTab
                        active={activeTab === 0}
                        title="Meeting Overview"
                        iconComponent={<Activity className="h-5 w-5" />}
                    >
                        <MeetingOverview meetings={meetings} />
                    </FlowbiteTab>
                    <FlowbiteTab
                        active={activeTab === 1}
                        title="Calendar Sync Monitor"
                        iconComponent={<Calendar className="h-5 w-5" />}
                    >
                        <CalendarSyncMonitor
                            calendarEvents={calendarEvents}
                            backendEvents={meetings}
                        />
                    </FlowbiteTab>
                    <FlowbiteTab
                        active={activeTab === 2}
                        title="Trouble Tickets"
                        iconComponent={<AlertCircle className="h-5 w-5" />}
                    >
                        <TroubleTickets meetings={meetings} />
                    </FlowbiteTab>
                </Tabs.Group>
            </div>
        </Flowbite>
    )
}
