import {
    PostListItemDto,
    PostDetailsDto,
    EngagementType,
    EngagementResponseDto,
} from '../../api/dashboard-api'

export interface DashboardState {
    posts: PostListItemDto[]
    selectedPostId: string | null
    selectedPost: PostDetailsDto | null
    totalPosts: number
    page: number
    limit: number
    loading: {
        posts: boolean
        postDetail: boolean
        updatePost: boolean
        engagement: boolean
    }
    error: {
        posts: string | null
        postDetail: string | null
        updatePost: string | null
        engagement: string | null
    }
    userName: string | null
    userSlug: string | null
}

export enum DashboardActionType {
    SET_USER_INFO = 'SET_USER_INFO',
    FETCH_POSTS_REQUEST = 'FETCH_POSTS_REQUEST',
    FETCH_POSTS_SUCCESS = 'FETCH_POSTS_SUCCESS',
    FETCH_POSTS_FAILURE = 'FETCH_POSTS_FAILURE',
    SELECT_POST = 'SELECT_POST',
    FETCH_POST_DETAIL_REQUEST = 'FETCH_POST_DETAIL_REQUEST',
    FETCH_POST_DETAIL_SUCCESS = 'FETCH_POST_DETAIL_SUCCESS',
    FETCH_POST_DETAIL_FAILURE = 'FETCH_POST_DETAIL_FAILURE',
    UPDATE_POST_REQUEST = 'UPDATE_POST_REQUEST',
    UPDATE_POST_SUCCESS = 'UPDATE_POST_SUCCESS',
    UPDATE_POST_FAILURE = 'UPDATE_POST_FAILURE',
    TRACK_ENGAGEMENT_REQUEST = 'TRACK_ENGAGEMENT_REQUEST',
    TRACK_ENGAGEMENT_SUCCESS = 'TRACK_ENGAGEMENT_SUCCESS',
    TRACK_ENGAGEMENT_FAILURE = 'TRACK_ENGAGEMENT_FAILURE',
}

export interface SetUserInfoAction {
    type: DashboardActionType.SET_USER_INFO
    payload: {
        userName: string
        userSlug: string
    }
}

export interface FetchPostsRequestAction {
    type: DashboardActionType.FETCH_POSTS_REQUEST
}

export interface FetchPostsSuccessAction {
    type: DashboardActionType.FETCH_POSTS_SUCCESS
    payload: {
        posts: PostListItemDto[]
        total: number
        page: number
        limit: number
    }
}

export interface FetchPostsFailureAction {
    type: DashboardActionType.FETCH_POSTS_FAILURE
    payload: string
}

export interface SelectPostAction {
    type: DashboardActionType.SELECT_POST
    payload: string
}

export interface FetchPostDetailRequestAction {
    type: DashboardActionType.FETCH_POST_DETAIL_REQUEST
}

export interface FetchPostDetailSuccessAction {
    type: DashboardActionType.FETCH_POST_DETAIL_SUCCESS
    payload: PostDetailsDto
}

export interface FetchPostDetailFailureAction {
    type: DashboardActionType.FETCH_POST_DETAIL_FAILURE
    payload: string
}

export interface UpdatePostRequestAction {
    type: DashboardActionType.UPDATE_POST_REQUEST
}

export interface UpdatePostSuccessAction {
    type: DashboardActionType.UPDATE_POST_SUCCESS
    payload: PostDetailsDto
}

export interface UpdatePostFailureAction {
    type: DashboardActionType.UPDATE_POST_FAILURE
    payload: string
}

export interface TrackEngagementRequestAction {
    type: DashboardActionType.TRACK_ENGAGEMENT_REQUEST
}

export interface TrackEngagementSuccessAction {
    type: DashboardActionType.TRACK_ENGAGEMENT_SUCCESS
    payload: {
        postId: string
        engagementType: EngagementType
        response: EngagementResponseDto
    }
}

export interface TrackEngagementFailureAction {
    type: DashboardActionType.TRACK_ENGAGEMENT_FAILURE
    payload: string
}

export type DashboardAction =
    | SetUserInfoAction
    | FetchPostsRequestAction
    | FetchPostsSuccessAction
    | FetchPostsFailureAction
    | SelectPostAction
    | FetchPostDetailRequestAction
    | FetchPostDetailSuccessAction
    | FetchPostDetailFailureAction
    | UpdatePostRequestAction
    | UpdatePostSuccessAction
    | UpdatePostFailureAction
    | TrackEngagementRequestAction
    | TrackEngagementSuccessAction
    | TrackEngagementFailureAction
