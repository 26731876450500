import {
    DraftPostViewMode,
    ListDraftPostsQueryDto,
    ListUsersQueryDto,
} from '../../types/admin-types'
import * as actions from './actions'
import { adminReducer, initialState } from './reducer'
import { AdminState } from './types'
import React, { createContext, useContext, useReducer, useCallback } from 'react'

interface AdminContextProps {
    state: AdminState
    fetchUsers: (query?: ListUsersQueryDto) => Promise<void>
    selectUser: (userId: string | null) => void
    fetchDraftPosts: (query?: ListDraftPostsQueryDto) => Promise<void>
    selectDraftPost: (postId: string, currentlySelected?: string) => void
    fetchDraftPostDetail: (postId: string) => Promise<void>
    setViewMode: (viewMode: DraftPostViewMode) => void
    setStatusFilter: (status: string | null) => void
    setSearchTerm: (searchTerm: string) => void
    setCurrentPage: (page: number) => void
    toggleUserList: () => void
    addFeedback: (postId: string, content: string) => Promise<void>
    updateStatus: (postId: string, status: string) => Promise<void>
    // Bulk actions
    selectDraftPostBulk: (postId: string) => void
    deselectDraftPostBulk: (postId: string) => void
    clearBulkSelection: () => void
    bulkUpdateStatus: (ids: string[], status: string) => Promise<void>
}

export const AdminContext = createContext<AdminContextProps>({
    state: initialState,
    fetchUsers: async () => {},
    selectUser: () => {},
    fetchDraftPosts: async () => {},
    selectDraftPost: () => {},
    fetchDraftPostDetail: async () => {},
    setViewMode: () => {},
    setStatusFilter: () => {},
    setSearchTerm: () => {},
    setCurrentPage: () => {},
    toggleUserList: () => {},
    addFeedback: async () => {},
    updateStatus: async () => {},
    // Bulk actions defaults
    selectDraftPostBulk: () => {},
    deselectDraftPostBulk: () => {},
    clearBulkSelection: () => {},
    bulkUpdateStatus: async () => {},
})

export const AdminStoreProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [state, dispatch] = useReducer(adminReducer, initialState)

    const fetchUsers = useCallback(
        (query?: ListUsersQueryDto) => actions.fetchUsers(dispatch, query),
        [dispatch],
    )
    const selectUser = useCallback(
        (userId: string | null) => actions.selectUser(dispatch, userId),
        [dispatch],
    )
    const fetchDraftPosts = useCallback(
        (query?: ListDraftPostsQueryDto) => actions.fetchDraftPosts(dispatch, query),
        [dispatch],
    )
    const selectDraftPost = useCallback(
        (postId: string, currentlySelected?: string) =>
            actions.selectDraftPost(
                dispatch,
                postId,
                currentlySelected !== undefined
                    ? currentlySelected
                    : // Convert null to undefined if needed
                    state.selectedDraftPostId === null
                    ? undefined
                    : state.selectedDraftPostId,
            ),
        [dispatch, state.selectedDraftPostId],
    )
    const fetchDraftPostDetail = useCallback(
        (postId: string) => actions.fetchDraftPostDetail(dispatch, postId),
        [dispatch],
    )
    const setViewMode = useCallback(
        (viewMode: DraftPostViewMode) => actions.setViewMode(dispatch, viewMode),
        [dispatch],
    )
    const setStatusFilter = useCallback(
        (status: string | null) => actions.setStatusFilter(dispatch, status),
        [dispatch],
    )
    const setSearchTerm = useCallback(
        (searchTerm: string) => actions.setSearchTerm(dispatch, searchTerm),
        [dispatch],
    )
    const setCurrentPage = useCallback(
        (page: number) => actions.setCurrentPage(dispatch, page),
        [dispatch],
    )
    const toggleUserList = useCallback(() => actions.toggleUserList(dispatch), [dispatch])
    const addFeedback = useCallback(
        (postId: string, content: string) => actions.addFeedback(dispatch, postId, content),
        [dispatch],
    )
    const updateStatus = useCallback(
        (postId: string, status: string) => actions.updateStatus(dispatch, postId, status),
        [dispatch],
    )
    const selectDraftPostBulk = useCallback(
        (postId: string) => actions.selectDraftPostBulk(dispatch, postId),
        [dispatch],
    )
    const deselectDraftPostBulk = useCallback(
        (postId: string) => actions.deselectDraftPostBulk(dispatch, postId),
        [dispatch],
    )
    const clearBulkSelection = useCallback(() => actions.clearBulkSelection(dispatch), [dispatch])
    const bulkUpdateStatus = useCallback(
        (ids: string[], status: string) => actions.bulkUpdateStatus(dispatch, ids, status),
        [dispatch],
    )

    const value = {
        state,
        fetchUsers,
        selectUser,
        fetchDraftPosts,
        selectDraftPost,
        fetchDraftPostDetail,
        setViewMode,
        setStatusFilter,
        setSearchTerm,
        setCurrentPage,
        toggleUserList,
        addFeedback,
        updateStatus,
        selectDraftPostBulk,
        deselectDraftPostBulk,
        clearBulkSelection,
        bulkUpdateStatus,
    }

    return <AdminContext.Provider value={value}>{children}</AdminContext.Provider>
}

export const useAdminStore = () => useContext(AdminContext)
