import { ListPostsParams, UpdatePostDto, EngagementType } from '../../api/dashboard-api'
import {
    setUserInfo,
    fetchPosts,
    selectPost,
    fetchPostDetail,
    updatePost,
    trackEngagement,
} from './actions'
import { dashboardReducer, initialState } from './reducer'
import { DashboardState } from './types'
import React, { createContext, useContext, useReducer, ReactNode, useCallback } from 'react'

interface DashboardContextType {
    state: DashboardState
    dispatch: React.Dispatch<any>
    setUserInfo: (userName: string, userSlug: string) => void
    fetchPosts: (
        userName?: string,
        userSlug?: string,
        params?: ListPostsParams,
    ) => Promise<string | null>
    selectPost: (postId: string) => void
    fetchPostDetail: (postId: string, userName?: string, userSlug?: string) => Promise<void>
    updatePost: (
        postId: string,
        data: UpdatePostDto,
        userName?: string,
        userSlug?: string,
    ) => Promise<boolean>
    trackEngagement: (
        postId: string,
        type: EngagementType,
        feedback?: string,
        userName?: string,
        userSlug?: string,
    ) => Promise<boolean>
}

const DashboardContext = createContext<DashboardContextType | undefined>(undefined)

export const DashboardStoreProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [state, dispatch] = useReducer(dashboardReducer, initialState)

    const setUserInfoCallback = useCallback((userName: string, userSlug: string) => {
        setUserInfo(dispatch, userName, userSlug)
    }, [])

    const fetchPostsCallback = useCallback(
        async (userNameParam?: string, userSlugParam?: string, params?: ListPostsParams) => {
            // Use provided parameters if available, otherwise fall back to state
            const userName = userNameParam || state.userName
            const userSlug = userSlugParam || state.userSlug

            if (!userName || !userSlug) {
                return null
            }

            return await fetchPosts(dispatch, userName, userSlug, params)
        },
        [state.userName, state.userSlug],
    )

    const selectPostCallback = useCallback((postId: string) => {
        selectPost(dispatch, postId)
    }, [])

    const fetchPostDetailCallback = useCallback(
        async (postId: string, userNameParam?: string, userSlugParam?: string) => {
            // Use provided parameters if available, otherwise fall back to state
            const userName = userNameParam || state.userName
            const userSlug = userSlugParam || state.userSlug

            if (!userName || !userSlug) {
                return
            }

            await fetchPostDetail(dispatch, userName, userSlug, postId)
        },
        [state.userName, state.userSlug],
    )

    const updatePostCallback = useCallback(
        async (
            postId: string,
            data: UpdatePostDto,
            userNameParam?: string,
            userSlugParam?: string,
        ) => {
            // Use provided parameters if available, otherwise fall back to state
            const userName = userNameParam || state.userName
            const userSlug = userSlugParam || state.userSlug

            if (!userName || !userSlug) {
                return false
            }

            return await updatePost(dispatch, userName, userSlug, postId, data)
        },
        [state.userName, state.userSlug],
    )

    const trackEngagementCallback = useCallback(
        async (
            postId: string,
            type: EngagementType,
            feedback?: string,
            userNameParam?: string,
            userSlugParam?: string,
        ) => {
            // Use provided parameters if available, otherwise fall back to state
            const userName = userNameParam || state.userName
            const userSlug = userSlugParam || state.userSlug

            if (!userName || !userSlug) {
                return false
            }

            return await trackEngagement(dispatch, userName, userSlug, postId, type, feedback)
        },
        [state.userName, state.userSlug],
    )

    const value = {
        state,
        dispatch, // Expose dispatch to components
        setUserInfo: setUserInfoCallback,
        fetchPosts: fetchPostsCallback,
        selectPost: selectPostCallback,
        fetchPostDetail: fetchPostDetailCallback,
        updatePost: updatePostCallback,
        trackEngagement: trackEngagementCallback,
    }

    return <DashboardContext.Provider value={value}>{children}</DashboardContext.Provider>
}

export const useDashboardStore = (): DashboardContextType => {
    const context = useContext(DashboardContext)
    if (context === undefined) {
        throw new Error('useDashboardStore must be used within a DashboardStoreProvider')
    }
    return context
}
