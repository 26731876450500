// Meeting types
export interface MeetingInfoDto {
    created_at: string
    title: string
    attendees: string[]
    transcript: string | null
}

// Admin user types
export interface AdminUserDto {
    id: string
    name: string
    email: string
    username: string
    slug: string
    pending_transcript_chapter_count?: number
    last_emailed_at?: string | null
    num_draft_posts_since_last_email?: number
}

export interface ListUsersQueryDto {
    search?: string
    page?: number
    limit?: number
}

// Draft post types
export interface AdminDraftPostDto {
    id: string
    status: string
    idea_title: string
    idea_basis: string
    idea_core_idea: string
    idea_relation_to_target_audience: string
    idea_research_enhancement_explanation: string
    post_content?: string
    admin_feedback?: string
    user_feedback?: string | null
    user_feedback_sentiment?: DraftPostFeedbackSentiment | null
    derived_from?: DraftPostDerivation
    published_at?: string | null
    user: {
        id: string
        name: string
        last_emailed_at?: string | null
    }
    created_at: string
    updated_at: string
    mainMeeting: MeetingInfoDto | null
}

export enum DraftPostDerivation {
    TRANSCRIPT_CHAPTERS_AND_RESEARCH = 'transcript_chapters_and_research',
    ONLY_TRANSCRIPT_CHAPTERS = 'only_transcript_chapters',
}

export interface ResearchResult {
    id: string
    title: string
    summary: string
    url: string
    domain?: string
    connection_status: ChapterConnectionStatus
}

export interface TranscriptChapter {
    id: string
    chapter_title: string
    summary: string
    key_quotes: string[]
    connection_status: ChapterConnectionStatus
    meeting: MeetingInfoDto | null
}

export interface AdminDraftPostDetailedDto extends AdminDraftPostDto {
    research_results: ResearchResult[]
    transcript_chapters: TranscriptChapter[]
    feedback_last_saved?: string // Timestamp when feedback was last saved
}

export interface ListDraftPostsQueryDto {
    user_id?: string
    status?: string
    page?: number
    limit?: number
}

// Feedback types
export interface CreateDraftPostFeedbackDto {
    content: string
}

export interface DraftPostFeedbackResponseDto {
    id: string
    content: string
}

// Status types
export interface UpdateDraftPostStatusDto {
    status: string
}

export interface UpdateDraftPostStatusResponseDto {
    id: string
    status: string
    updated_at: string
}

// Bulk Status Update types
export interface BulkUpdateDraftPostStatusDto {
    ids: string[]
    status: string
}

export interface BulkUpdateDraftPostStatusResponseDto {
    successful_updates: number
    failed_updates: number
    // Optionally, include details about failures if needed later
    // errors: { id: string; error: string }[];
}

// Enums
export enum DraftPostViewMode {
    IDEAS = 'ideas',
    POSTS = 'posts',
    FEEDBACK = 'feedback',
}

export enum DraftPostFeedbackSentiment {
    POSITIVE = 'positive',
    NEGATIVE = 'negative',
}

export enum ChapterConnectionStatus {
    USED = 'connection_found',
    UNUSED = 'no_connection_found',
}

export enum DraftPostStatus {
    PENDING_CREATION = 'pending_creation',
    PENDING_RETRY = 'pending_retry',
    IDEA_UNDER_REVIEW = 'idea_under_review',
    IDEA_APPROVED_WITH_RESEARCH = 'idea_approved_with_research',
    IDEA_APPROVED_WITHOUT_RESEARCH = 'idea_approved_without_research',
    IDEA_REJECTED = 'idea_rejected',
    POST_UNDER_REVIEW = 'post_under_review',
    POST_APPROVED = 'post_approved',
    POST_REJECTED = 'post_rejected',
}
