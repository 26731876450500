import { formatDateTime } from '../../lib/utils'
import type { Meeting } from '../../types/MeetingDashboard'
import MeetingStatusBadge from './meeting-status-badge'
import { Modal, Timeline, Button, Card } from 'flowbite-react'
import {
    Calendar,
    Clock,
    User,
    Video,
    LinkIcon,
    AlertTriangle,
    CheckCircle,
    XCircle,
    RefreshCw,
} from 'lucide-react'

;('use client')

interface MeetingDetailsModalProps {
    meeting: Meeting
    isOpen: boolean
    onClose: () => void
}

export default function MeetingDetailsModal({
    meeting,
    isOpen,
    onClose,
}: MeetingDetailsModalProps) {
    const getStatusIcon = (status: string) => {
        switch (status) {
            case 'calendar_received':
                return <Calendar className="h-5 w-5 text-blue-600" />
            case 'backend_received':
                return <CheckCircle className="h-5 w-5 text-green-600" />
            case 'connecting':
                return <RefreshCw className="h-5 w-5 text-blue-600" />
            case 'waiting_room':
                return <Clock className="h-5 w-5 text-yellow-600" />
            case 'joined':
                return <Video className="h-5 w-5 text-green-600" />
            case 'error':
                return <XCircle className="h-5 w-5 text-red-600" />
            default:
                return <AlertTriangle className="h-5 w-5 text-gray-600" />
        }
    }

    const getTimelineItems = () => {
        const items: any[] = []

        if (meeting.events) {
            return meeting.events.map((event, index) => (
                <Timeline.Item key={index}>
                    <Timeline.Point icon={() => getStatusIcon(event.type)} />
                    <Timeline.Content>
                        <Timeline.Time>{formatDateTime(event.timestamp)}</Timeline.Time>
                        <Timeline.Title>{event.title}</Timeline.Title>
                        {event.description && <Timeline.Body>{event.description}</Timeline.Body>}
                    </Timeline.Content>
                </Timeline.Item>
            ))
        }

        return items
    }

    const handleRetryJoin = () => {
        // This would trigger an API call to retry joining the meeting
        alert('Retry join triggered for meeting: ' + meeting.id)
    }

    return (
        <Modal show={isOpen} onClose={onClose} size="xl">
            <Modal.Header>Meeting Details</Modal.Header>
            <Modal.Body>
                <div className="space-y-6">
                    <div className="flex justify-between items-start">
                        <div>
                            <h3 className="text-xl font-bold">{meeting.title}</h3>
                            <div className="flex items-center mt-2">
                                <User className="w-4 h-4 mr-1 text-gray-500" />
                                <span className="text-gray-600 mr-4">{meeting.host.name}</span>

                                <Video className="w-4 h-4 mr-1 text-gray-500" />
                                <span className="text-gray-600">{meeting.meeting_type}</span>
                            </div>
                        </div>
                        <MeetingStatusBadge status={meeting.status} />
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <Card>
                            <h5 className="text-lg font-bold tracking-tight text-gray-900 mb-2">
                                Meeting Information
                            </h5>
                            <div className="space-y-2">
                                <div className="flex items-center">
                                    <Calendar className="w-4 h-4 mr-2 text-gray-500" />
                                    <span className="text-sm text-gray-700">
                                        Start: {formatDateTime(meeting.start)}
                                    </span>
                                </div>
                                {meeting.end && (
                                    <div className="flex items-center">
                                        <Clock className="w-4 h-4 mr-2 text-gray-500" />
                                        <span className="text-sm text-gray-700">
                                            End: {formatDateTime(meeting.end)}
                                        </span>
                                    </div>
                                )}
                                <div className="flex items-center">
                                    <LinkIcon className="w-4 h-4 mr-2 text-gray-500" />
                                    <a
                                        href={meeting.meeting_url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-sm text-blue-600 hover:underline"
                                    >
                                        Meeting URL
                                    </a>
                                </div>
                            </div>
                        </Card>

                        <Card>
                            <h5 className="text-lg font-bold tracking-tight text-gray-900 mb-2">
                                System Information
                            </h5>
                            <div className="space-y-2 text-sm">
                                <div className="flex justify-between">
                                    <span className="text-gray-500">Meeting ID:</span>
                                    <span className="font-mono">{meeting.id}</span>
                                </div>
                                <div className="flex justify-between">
                                    <span className="text-gray-500">Calendar Sync:</span>
                                    <span
                                        className={
                                            meeting.calendarSynced
                                                ? 'text-green-600'
                                                : 'text-red-600'
                                        }
                                    >
                                        {meeting.calendarSynced ? 'Synced' : 'Not Synced'}
                                    </span>
                                </div>
                                <div className="flex justify-between">
                                    <span className="text-gray-500">Backend Record:</span>
                                    <span
                                        className={
                                            meeting.backendRecord
                                                ? 'text-green-600'
                                                : 'text-red-600'
                                        }
                                    >
                                        {meeting.backendRecord ? 'Created' : 'Missing'}
                                    </span>
                                </div>
                                <div className="flex justify-between">
                                    <span className="text-gray-500">Recall AI Status:</span>
                                    <span
                                        className={
                                            meeting.recallStatus === 'success'
                                                ? 'text-green-600'
                                                : meeting.recallStatus === 'pending'
                                                ? 'text-yellow-600'
                                                : 'text-red-600'
                                        }
                                    >
                                        {meeting.recallStatus}
                                    </span>
                                </div>
                            </div>
                        </Card>
                    </div>

                    <div>
                        <h5 className="text-lg font-bold mb-4">Meeting Timeline</h5>
                        <Timeline>{getTimelineItems()}</Timeline>
                    </div>

                    {(meeting.status === 'failed' || meeting.status === 'waiting_room') && (
                        <div className="flex justify-end">
                            <Button color="blue" onClick={handleRetryJoin}>
                                <RefreshCw className="mr-2 h-5 w-5" />
                                Retry Join
                            </Button>
                        </div>
                    )}
                </div>
            </Modal.Body>
        </Modal>
    )
}
