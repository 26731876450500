import React, { useState, MouseEvent } from 'react'

interface FeedbackModalProps {
    isOpen: boolean
    onSubmit: (feedback: string) => void
    onClose: () => void
}

const FeedbackModal: React.FC<FeedbackModalProps> = ({ isOpen, onSubmit, onClose }) => {
    const [feedback, setFeedback] = useState('')

    const handleSubmit = () => {
        onSubmit(feedback)
        setFeedback('') // Reset feedback after submission
    }

    // Handle clicking outside the modal content
    const handleBackdropClick = (e: MouseEvent<HTMLDivElement>) => {
        // Check if the click target is the backdrop itself
        if (e.target === e.currentTarget) {
            onClose()
        }
    }

    // Prevent clicks inside the modal content from closing the modal
    const handleContentClick = (e: MouseEvent<HTMLDivElement>) => {
        e.stopPropagation()
    }

    if (!isOpen) return null

    return (
        // Add onClick handler to the backdrop
        <div
            className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
            onClick={handleBackdropClick}
        >
            {/* Add onClick handler to the content area to stop propagation */}
            <div
                className="bg-white rounded-lg p-6 w-full max-w-md mx-4 shadow-xl"
                onClick={handleContentClick}
            >
                <h2 className="text-xl font-semibold mb-4">We're sorry this didn't work for you</h2>
                <p className="text-gray-600 mb-4">
                    Your feedback helps us improve. Would you like to tell us more about why this
                    didn't work?
                </p>

                <textarea
                    value={feedback}
                    onChange={(e) => setFeedback(e.target.value)}
                    className="text-area mb-4"
                    rows={4}
                    placeholder="Optional feedback..."
                />

                <div className="flex justify-end space-x-3">
                    <button onClick={handleSubmit} className="btn btn-primary">
                        Submit
                    </button>
                </div>
            </div>
        </div>
    )
}

export default FeedbackModal
