import { DraftPostStatus, DraftPostViewMode } from '../../types/admin-types'
import { AdminAction, AdminActionType, AdminState } from './types'

export const initialState: AdminState = {
    users: [],
    totalUsers: 0,
    selectedUserId: null, // Initialize to null to show all users
    draftPosts: [],
    totalDraftPosts: 0,
    selectedDraftPostId: null,
    selectedDraftPost: null,
    selectedDraftPostIds: [], // Initialize bulk selection state
    viewMode: DraftPostViewMode.IDEAS,
    selectedStatus: null, // Will be set to IDEA_UNDER_REVIEW on component mount
    searchTerm: '',
    currentPage: 1,
    loading: {
        users: false,
        draftPosts: false,
        draftPostDetail: false,
        feedback: false,
        status: false,
        bulkUpdate: false, // Initialize bulk update loading state
    },
    error: {
        users: null,
        draftPosts: null,
        draftPostDetail: null,
        feedback: null,
        status: null,
        bulkUpdate: null, // Initialize bulk update error state
    },
    showUserList: true, // New state to control user list visibility
}

export const adminReducer = (state: AdminState, action: AdminAction): AdminState => {
    switch (action.type) {
        // UI actions
        case AdminActionType.TOGGLE_USER_LIST:
            return {
                ...state,
                showUserList: !state.showUserList,
            }

        // User actions
        case AdminActionType.FETCH_USERS_REQUEST:
            return {
                ...state,
                loading: { ...state.loading, users: true },
                error: { ...state.error, users: null },
            }
        case AdminActionType.FETCH_USERS_SUCCESS:
            return {
                ...state,
                users: action.payload.users,
                totalUsers: action.payload.total,
                loading: { ...state.loading, users: false },
            }
        case AdminActionType.FETCH_USERS_FAILURE:
            return {
                ...state,
                loading: { ...state.loading, users: false },
                error: { ...state.error, users: action.payload },
            }
        case AdminActionType.SELECT_USER:
            return {
                ...state,
                selectedUserId: action.payload,
                selectedDraftPostId: null,
                selectedDraftPost: null,
            }

        // Draft posts actions
        case AdminActionType.FETCH_DRAFT_POSTS_REQUEST:
            return {
                ...state,
                loading: { ...state.loading, draftPosts: true },
                error: { ...state.error, draftPosts: null },
            }
        case AdminActionType.FETCH_DRAFT_POSTS_SUCCESS:
            return {
                ...state,
                draftPosts: action.payload.draftPosts,
                totalDraftPosts: action.payload.pagination.total,
                loading: { ...state.loading, draftPosts: false },
            }
        case AdminActionType.FETCH_DRAFT_POSTS_FAILURE:
            return {
                ...state,
                loading: { ...state.loading, draftPosts: false },
                error: { ...state.error, draftPosts: action.payload },
            }
        case AdminActionType.SELECT_DRAFT_POST:
            return {
                ...state,
                selectedDraftPostId: action.payload,
                selectedDraftPost: null,
            }

        // Draft post detail actions
        case AdminActionType.FETCH_DRAFT_POST_DETAIL_REQUEST:
            return {
                ...state,
                loading: { ...state.loading, draftPostDetail: true },
                error: { ...state.error, draftPostDetail: null },
            }
        case AdminActionType.FETCH_DRAFT_POST_DETAIL_SUCCESS:
            return {
                ...state,
                selectedDraftPost: action.payload,
                loading: { ...state.loading, draftPostDetail: false },
            }
        case AdminActionType.FETCH_DRAFT_POST_DETAIL_FAILURE:
            return {
                ...state,
                loading: { ...state.loading, draftPostDetail: false },
                error: { ...state.error, draftPostDetail: action.payload },
            }

        // UI actions
        case AdminActionType.SET_VIEW_MODE:
            return {
                ...state,
                viewMode: action.payload,
                selectedStatus: null,
            }
        case AdminActionType.SET_STATUS_FILTER:
            return {
                ...state,
                selectedStatus: action.payload as DraftPostStatus,
            }
        case AdminActionType.SET_SEARCH_TERM:
            return {
                ...state,
                searchTerm: action.payload,
            }
        case AdminActionType.SET_CURRENT_PAGE:
            return {
                ...state,
                currentPage: action.payload,
            }

        // Feedback actions
        case AdminActionType.ADD_FEEDBACK_REQUEST:
            return {
                ...state,
                loading: { ...state.loading, feedback: true },
                error: { ...state.error, feedback: null },
            }
        case AdminActionType.ADD_FEEDBACK_SUCCESS:
            return {
                ...state,
                loading: { ...state.loading, feedback: false },
                selectedDraftPost: state.selectedDraftPost
                    ? {
                          ...state.selectedDraftPost,
                          admin_feedback: action.payload.content,
                          feedback_last_saved: action.payload.timestamp,
                      }
                    : null,
            }
        case AdminActionType.ADD_FEEDBACK_FAILURE:
            return {
                ...state,
                loading: { ...state.loading, feedback: false },
                error: { ...state.error, feedback: action.payload },
            }

        // Status actions
        case AdminActionType.UPDATE_STATUS_REQUEST:
            return {
                ...state,
                loading: { ...state.loading, status: true },
                error: { ...state.error, status: null },
            }
        case AdminActionType.UPDATE_STATUS_SUCCESS:
            return {
                ...state,
                loading: { ...state.loading, status: false },
                selectedDraftPost: state.selectedDraftPost
                    ? { ...state.selectedDraftPost, status: action.payload.status }
                    : null,
                draftPosts: state.draftPosts.map((post) =>
                    post.id === action.payload.id
                        ? { ...post, status: action.payload.status }
                        : post,
                ),
            }
        case AdminActionType.UPDATE_STATUS_FAILURE:
            return {
                ...state,
                loading: { ...state.loading, status: false },
                error: { ...state.error, status: action.payload },
            }

        // Bulk Selection Actions
        case AdminActionType.SELECT_DRAFT_POST_BULK:
            // Add postId if not already present
            if (!state.selectedDraftPostIds.includes(action.payload)) {
                return {
                    ...state,
                    selectedDraftPostIds: [...state.selectedDraftPostIds, action.payload],
                }
            }
            return state // If already present, do nothing
        case AdminActionType.DESELECT_DRAFT_POST_BULK:
            return {
                ...state,
                selectedDraftPostIds: state.selectedDraftPostIds.filter(
                    (id) => id !== action.payload,
                ),
            }
        case AdminActionType.CLEAR_BULK_SELECTION:
            return {
                ...state,
                selectedDraftPostIds: [],
            }

        // Bulk Update Actions
        case AdminActionType.BULK_UPDATE_STATUS_REQUEST:
            return {
                ...state,
                loading: { ...state.loading, bulkUpdate: true },
                error: { ...state.error, bulkUpdate: null },
            }
        case AdminActionType.BULK_UPDATE_STATUS_SUCCESS:
            // Clear selection and update loading state
            // Optionally, update the status of affected posts in state.draftPosts if needed immediately,
            // but often a refresh (fetchDraftPosts) is preferred.
            return {
                ...state,
                loading: { ...state.loading, bulkUpdate: false },
                selectedDraftPostIds: [], // Clear selection on success
            }
        case AdminActionType.BULK_UPDATE_STATUS_FAILURE:
            return {
                ...state,
                loading: { ...state.loading, bulkUpdate: false },
                error: { ...state.error, bulkUpdate: action.payload },
            }

        default:
            return state
    }
}
