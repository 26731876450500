import MeetingsDashboard from '../components/MeetingDashboard/dashboard'

const MeetingsDashboardPage = (): JSX.Element => {
    return (
        <div className="flex min-h-screen bg-[#F7F7F7] overflow-auto">
            <MeetingsDashboard />
        </div>
    )
}
export default MeetingsDashboardPage
