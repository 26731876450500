import { DraftPostStatus } from '../../types/admin-types'
import React from 'react'

interface StatusToggleProps {
    currentStatus: DraftPostStatus
    onStatusChange: (status: DraftPostStatus) => void
    hasResearch: boolean
    disabled?: boolean
}

const StatusToggle: React.FC<StatusToggleProps> = ({
    currentStatus,
    onStatusChange,
    hasResearch,
    disabled = false,
}) => {
    // Define status options for ideas
    const ideaStatuses = [
        { value: DraftPostStatus.IDEA_UNDER_REVIEW, label: 'Under Review' },
        {
            value: DraftPostStatus.IDEA_APPROVED_WITH_RESEARCH,
            label: 'Approved with Research',
        },
        {
            value: DraftPostStatus.IDEA_APPROVED_WITHOUT_RESEARCH,
            label: 'Approved without Research',
        },
        { value: DraftPostStatus.IDEA_REJECTED, label: 'Rejected' },
        { value: DraftPostStatus.PENDING_RETRY, label: 'Pending Retry' },
    ]

    // Define status options for posts
    const postStatuses = [
        { value: DraftPostStatus.POST_UNDER_REVIEW, label: 'Ready for Review' },
        { value: DraftPostStatus.POST_APPROVED, label: 'Approved' },
        { value: DraftPostStatus.POST_REJECTED, label: 'Rejected' },
    ]

    // Determine which status options to show based on current status
    const isPostStatus = [
        DraftPostStatus.POST_UNDER_REVIEW,
        DraftPostStatus.POST_APPROVED,
        DraftPostStatus.POST_REJECTED,
    ].includes(currentStatus)

    const statusOptions = isPostStatus ? postStatuses : ideaStatuses

    // Handle status change without automatic view switching
    const handleStatusChange = (status: DraftPostStatus) => {
        onStatusChange(status)
    }

    return (
        <div className="inline-block">
            <select
                className={`form-select border border-gray-300 rounded px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                    disabled ? 'bg-gray-100 text-gray-500 cursor-not-allowed' : ''
                }`}
                value={currentStatus}
                onChange={(e) => handleStatusChange(e.target.value as DraftPostStatus)}
                disabled={disabled}
            >
                {statusOptions.map((status) => (
                    <option
                        key={status.value}
                        value={status.value}
                        disabled={
                            status.value === currentStatus ||
                            (status.value === DraftPostStatus.IDEA_APPROVED_WITH_RESEARCH &&
                                !hasResearch)
                        }
                    >
                        {status.label}
                    </option>
                ))}
            </select>
        </div>
    )
}

export default StatusToggle
