import { useAdminStore } from '../../context/admin/store'
import { DraftPostStatus, DraftPostDerivation, DraftPostViewMode } from '../../types/admin-types'
import SourcesTab from './SourcesTab'
import StatusToggle from './StatusToggle'
import React, { useEffect, useState, useCallback } from 'react'

const DraftPostDetail: React.FC = () => {
    const { state, fetchDraftPostDetail, addFeedback, updateStatus } = useAdminStore()
    const { selectedDraftPostId, selectedDraftPost, loading, viewMode } = state

    // Create flag to check if we're in feedback mode
    const isFeedbackMode = viewMode === DraftPostViewMode.FEEDBACK

    const [feedback, setFeedback] = useState('')
    const [saveButtonState, setSaveButtonState] = useState<'idle' | 'loading' | 'success'>('idle')
    const [pendingStatus, setPendingStatus] = useState<DraftPostStatus | null>(null)
    const [statusSaveState, setStatusSaveState] = useState<'idle' | 'loading' | 'success'>('idle')

    // State for tags
    const [tags, setTags] = useState<string[]>([])
    const [newTag, setNewTag] = useState('')

    // Load tags from localStorage
    const loadTags = useCallback((postId: string) => {
        try {
            const storedTags = localStorage.getItem(`adminTags_${postId}`)
            if (storedTags) {
                setTags(JSON.parse(storedTags))
            } else {
                setTags([])
            }
        } catch (error) {
            console.error('Error loading tags', error)
            setTags([])
        }
    }, [])

    // Save tags to localStorage
    const saveTags = useCallback((postId: string, tagList: string[]) => {
        try {
            localStorage.setItem(`adminTags_${postId}`, JSON.stringify(tagList))
        } catch (error) {
            console.error('Error saving tags', error)
        }
    }, [])

    useEffect(() => {
        if (selectedDraftPostId) {
            fetchDraftPostDetail(selectedDraftPostId)
            setFeedback('') // Reset feedback when changing posts
            setPendingStatus(null) // Reset pending status
            setSaveButtonState('idle')
            setStatusSaveState('idle')
            loadTags(selectedDraftPostId)
        }
    }, [selectedDraftPostId, loadTags])

    // Set initial feedback from selected post when loaded
    useEffect(() => {
        if (selectedDraftPost?.admin_feedback) {
            setFeedback(selectedDraftPost.admin_feedback)
        }
        if (selectedDraftPost?.status) {
            setPendingStatus(selectedDraftPost.status as DraftPostStatus)
        }
    }, [selectedDraftPost?.id, selectedDraftPost?.admin_feedback, selectedDraftPost?.status])

    const handleAddFeedback = async () => {
        if (selectedDraftPostId && feedback.trim()) {
            setSaveButtonState('loading')

            try {
                await addFeedback(selectedDraftPostId, feedback)
                setSaveButtonState('success')

                // Reset to idle after 2 seconds
                setTimeout(() => {
                    setSaveButtonState('idle')
                }, 2000)
            } catch (error) {
                setSaveButtonState('idle')
            }
        }
    }

    const handleStatusChange = (status: DraftPostStatus) => {
        setPendingStatus(status)
    }

    const handleSaveStatus = async () => {
        if (selectedDraftPostId && pendingStatus) {
            setStatusSaveState('loading')

            try {
                await updateStatus(selectedDraftPostId, pendingStatus)
                setStatusSaveState('success')

                // Reset to idle after 2 seconds
                setTimeout(() => {
                    setStatusSaveState('idle')
                }, 2000)
            } catch (error) {
                setStatusSaveState('idle')
            }
        }
    }

    // Helper to get a readable label for derivation types
    const getDerivationLabel = (type?: DraftPostDerivation): string => {
        if (!type) return ''

        switch (type) {
            case DraftPostDerivation.TRANSCRIPT_CHAPTERS_AND_RESEARCH:
                return 'Transcript & Research'
            case DraftPostDerivation.ONLY_TRANSCRIPT_CHAPTERS:
                return 'Transcript Only'
            default:
                return (type as string).replace(/_/g, ' ')
        }
    }

    // Check if this is a post (not an idea)
    const isPostType = (status: string): boolean => {
        return [
            DraftPostStatus.POST_UNDER_REVIEW,
            DraftPostStatus.POST_APPROVED,
            DraftPostStatus.POST_REJECTED,
            DraftPostStatus.IDEA_APPROVED_WITH_RESEARCH,
            DraftPostStatus.IDEA_APPROVED_WITHOUT_RESEARCH,
        ].includes(status as DraftPostStatus)
    }

    // Add a new tag
    const handleAddTag = () => {
        if (newTag.trim() && !tags.includes(newTag.trim()) && selectedDraftPostId) {
            const updatedTags = [...tags, newTag.trim()]
            setTags(updatedTags)
            saveTags(selectedDraftPostId, updatedTags)
            setNewTag('')
        }
    }

    // Remove a tag
    const handleRemoveTag = (tagToRemove: string) => {
        if (selectedDraftPostId) {
            const updatedTags = tags.filter((tag) => tag !== tagToRemove)
            setTags(updatedTags)
            saveTags(selectedDraftPostId, updatedTags)
        }
    }

    if (!selectedDraftPostId || !selectedDraftPost) {
        return (
            <div className="flex-1 p-8 flex items-center justify-center text-gray-500">
                Select a draft post to view details
            </div>
        )
    }

    if (loading.draftPostDetail) {
        return (
            <div className="flex-1 p-8 flex items-center justify-center">
                <p>Loading draft post details...</p>
            </div>
        )
    }

    // Create a combined feedback section for feedback mode
    const FeedbackSection = (
        <div className="bg-white shadow rounded-lg p-6 mb-6">
            <h2 className="text-lg font-semibold mb-4">Feedback Summary</h2>

            {/* Admin Feedback */}
            <div className="mb-6">
                <h3 className="text-md font-medium mb-2">Admin Feedback</h3>
                <textarea
                    className="w-full p-3 border border-gray-300 rounded mb-2"
                    rows={6}
                    placeholder="Enter feedback..."
                    value={feedback || selectedDraftPost.admin_feedback || ''}
                    onChange={(e) => setFeedback(e.target.value)}
                ></textarea>
                <div className="flex justify-between items-center">
                    <button
                        className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 disabled:bg-gray-300 disabled:cursor-not-allowed"
                        onClick={handleAddFeedback}
                        disabled={
                            (!feedback && !selectedDraftPost.admin_feedback) ||
                            saveButtonState === 'loading'
                        }
                    >
                        {saveButtonState === 'loading'
                            ? 'Saving...'
                            : saveButtonState === 'success'
                            ? 'Saved ✓'
                            : 'Save Feedback'}
                    </button>

                    {selectedDraftPost.feedback_last_saved && (
                        <span className="text-sm text-gray-500">
                            Last saved:{' '}
                            {new Date(selectedDraftPost.feedback_last_saved).toLocaleString()}
                        </span>
                    )}
                </div>
            </div>

            {/* User Feedback */}
            {selectedDraftPost.user_feedback && (
                <div>
                    <h3 className="text-md font-medium mb-2 flex items-center">
                        User Feedback
                        {selectedDraftPost.user_feedback_sentiment && (
                            <span className="ml-2 text-lg">
                                {selectedDraftPost.user_feedback_sentiment === 'positive'
                                    ? '👍'
                                    : '👎'}
                            </span>
                        )}
                    </h3>
                    <div className="p-4 bg-blue-50 border border-blue-100 rounded-md">
                        <p className="whitespace-pre-wrap text-gray-800">
                            {selectedDraftPost.user_feedback}
                        </p>
                    </div>
                </div>
            )}
        </div>
    )

    return (
        <div className="flex-1 p-6 overflow-y-auto bg-white">
            <div className="mb-4">
                <div className="flex items-center justify-between">
                    <div>
                        <h1 className="text-2xl font-bold mb-2">{selectedDraftPost.idea_title}</h1>
                    </div>
                    <div className="flex flex-col items-end space-y-2">
                        <StatusToggle
                            currentStatus={
                                pendingStatus || (selectedDraftPost.status as DraftPostStatus)
                            }
                            onStatusChange={handleStatusChange}
                            hasResearch={selectedDraftPost.research_results.length > 0}
                            disabled={isFeedbackMode}
                        />
                        <button
                            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 disabled:bg-gray-300 disabled:cursor-not-allowed"
                            onClick={handleSaveStatus}
                            disabled={
                                isFeedbackMode ||
                                !pendingStatus ||
                                pendingStatus === selectedDraftPost.status ||
                                statusSaveState === 'loading'
                            }
                        >
                            {statusSaveState === 'loading'
                                ? 'Saving...'
                                : statusSaveState === 'success'
                                ? 'Saved ✓'
                                : 'Save Status'}
                        </button>
                    </div>
                </div>
                <div className="flex flex-col text-sm text-gray-500 mt-2">
                    <span>
                        ID: <span className="select-all font-mono">{selectedDraftPost.id}</span>
                    </span>
                    <span>
                        User: <span className="italic">{selectedDraftPost.user.name}</span>
                    </span>
                    {selectedDraftPost.derived_from && (
                        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
                            {getDerivationLabel(selectedDraftPost.derived_from)}
                        </span>
                    )}
                </div>
            </div>

            <div className="grid grid-cols-1 gap-6 mb-8">
                {/* Tags Section in Feedback Mode */}
                {isFeedbackMode && (
                    <div className="bg-white shadow rounded-lg p-6 mb-6">
                        <h2 className="text-lg font-semibold mb-4">Tags</h2>

                        {/* Tag Input */}
                        <div className="flex mb-4">
                            <input
                                type="text"
                                className="flex-grow p-2 border border-gray-300 rounded-l"
                                placeholder="Add a tag..."
                                value={newTag}
                                onChange={(e) => setNewTag(e.target.value)}
                                onKeyPress={(e) => e.key === 'Enter' && handleAddTag()}
                            />
                            <button
                                className="px-4 py-2 bg-blue-500 text-white rounded-r hover:bg-blue-600"
                                onClick={handleAddTag}
                            >
                                Add
                            </button>
                        </div>

                        {/* Tag Display */}
                        <div className="flex flex-wrap gap-2">
                            {tags.map((tag) => (
                                <div
                                    key={tag}
                                    className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-blue-100 text-blue-800"
                                >
                                    {tag}
                                    <button
                                        className="ml-2 text-blue-600 hover:text-blue-800 focus:outline-none"
                                        onClick={() => handleRemoveTag(tag)}
                                    >
                                        ×
                                    </button>
                                </div>
                            ))}
                            {tags.length === 0 && (
                                <p className="text-gray-500 italic">No tags added yet</p>
                            )}
                        </div>
                    </div>
                )}

                {/* Feedback Section - Shown at the top in Feedback Mode */}
                {isFeedbackMode && FeedbackSection}

                {/* Post Content - Only shown for post types */}
                {isPostType(selectedDraftPost.status) && selectedDraftPost.post_content && (
                    <div className="bg-white shadow rounded-lg p-6 mb-6">
                        <h2 className="text-lg font-semibold mb-2">Post Content</h2>
                        <div className="prose max-w-none text-gray-700 whitespace-pre-wrap">
                            {selectedDraftPost.post_content}
                        </div>
                    </div>
                )}

                <div className="bg-white shadow rounded-lg p-6 mb-6">
                    <h2 className="text-lg font-semibold mb-2">Idea Details</h2>

                    <div className="mb-4">
                        <h3 className="text-md font-medium">Basis</h3>
                        <p className="text-gray-700">{selectedDraftPost.idea_basis}</p>
                    </div>

                    <div className="mb-4">
                        <h3 className="text-md font-medium">Core Idea</h3>
                        <p className="text-gray-700">{selectedDraftPost.idea_core_idea}</p>
                    </div>

                    <div className="mb-4">
                        <h3 className="text-md font-medium">Research Enhancement Explanation</h3>
                        <p className="text-gray-700">
                            {selectedDraftPost.idea_research_enhancement_explanation}
                        </p>
                    </div>

                    <div className="mb-4">
                        <h3 className="text-md font-medium">Relation to Target Audience</h3>
                        <p className="text-gray-700">
                            {selectedDraftPost.idea_relation_to_target_audience}
                        </p>
                    </div>

                    {/* Sources Tab */}
                    <SourcesTab
                        researchResults={selectedDraftPost.research_results}
                        transcriptChapters={selectedDraftPost.transcript_chapters}
                    />
                </div>

                {/* Feedback Section - Shown at the bottom in non-Feedback Mode */}
                {!isFeedbackMode && FeedbackSection}
            </div>
        </div>
    )
}

export default DraftPostDetail
