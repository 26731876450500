import { useAdminStore } from '../../context/admin/store'
import { AdminUserDto } from '../../types/admin-types'
import UserDetailsModal from './UserDetailsModal'
import { useState, useEffect } from 'react'

const UserList: React.FC = () => {
    const [searchTerm, setSearchTerm] = useState<string>('')
    const [hoveredUserId, setHoveredUserId] = useState<string | null>(null)
    const [showDetailsModal, setShowDetailsModal] = useState(false)
    const [selectedUserForModal, setSelectedUserForModal] = useState<AdminUserDto | null>(null)
    const [isAllUsersSelected, setIsAllUsersSelected] = useState(false)

    const { state, fetchUsers, selectUser, toggleUserList } = useAdminStore()
    const { users, selectedUserId, loading, showUserList } = state

    // Fetch users only once on component mount
    useEffect(() => {
        fetchUsers()
    }, [])

    const handleUserSelect = (userId: string) => {
        // If clicking on already selected user, deselect (show all users)
        if (selectedUserId === userId) {
            selectUser(null)
        } else {
            selectUser(userId)
        }
    }

    const handleShowDetails = (user: AdminUserDto | null, isAllUsers: boolean = false) => {
        setSelectedUserForModal(user)
        setIsAllUsersSelected(isAllUsers)
        setShowDetailsModal(true)
    }

    // Filter users locally based on search term
    const filteredUsers = users.filter((user) => {
        if (!searchTerm.trim()) return true

        const lowerSearchTerm = searchTerm.toLowerCase()
        return (
            user.name.toLowerCase().includes(lowerSearchTerm) ||
            user.email.toLowerCase().includes(lowerSearchTerm)
        )
    })

    // If user list is completely hidden, render just the toggle button
    if (!showUserList) {
        return (
            <div className="w-10 flex flex-col items-center py-4 bg-white border-r border-gray-200">
                <button
                    onClick={toggleUserList}
                    className="p-2 rounded-full bg-blue-100 hover:bg-blue-200 text-blue-800"
                    title="Show Users"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                    >
                        <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
                        <path
                            fillRule="evenodd"
                            d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
                            clipRule="evenodd"
                        />
                    </svg>
                </button>
            </div>
        )
    }

    return (
        <div className="w-64 border-r border-gray-200 h-full overflow-y-auto bg-white relative">
            <div className="p-4">
                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-lg font-semibold">Users</h2>
                    <button
                        onClick={toggleUserList}
                        className="p-1 rounded hover:bg-gray-200"
                        title="Hide Users"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                        >
                            <path
                                fillRule="evenodd"
                                d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                                clipRule="evenodd"
                            />
                        </svg>
                    </button>
                </div>

                <div className="mb-4">
                    <input
                        type="text"
                        placeholder="Search users..."
                        className="w-full px-3 py-2 border border-gray-300 rounded-md"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </div>

                {loading.users ? (
                    <div className="flex justify-center">
                        <p>Loading users...</p>
                    </div>
                ) : (
                    <ul className="space-y-2">
                        <li
                            className={`p-2 rounded cursor-pointer relative group ${
                                selectedUserId === null || selectedUserId === ''
                                    ? 'bg-blue-100'
                                    : 'hover:bg-gray-100'
                            } hover:pr-16`}
                            onClick={() => selectUser(null)}
                            onMouseEnter={() => setHoveredUserId('all')}
                            onMouseLeave={() => setHoveredUserId(null)}
                        >
                            <div className="font-medium">All Users</div>

                            {hoveredUserId === 'all' && (
                                <button
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        handleShowDetails(null, true)
                                    }}
                                    className="absolute right-2 top-1/2 transform -translate-y-1/2 text-xs px-2 py-1 bg-blue-500 text-white rounded hover:bg-blue-600 transition-opacity duration-150"
                                >
                                    Details
                                </button>
                            )}
                        </li>
                        {filteredUsers.map((user) => (
                            <li
                                key={user.id}
                                className={`p-2 rounded cursor-pointer relative group ${
                                    selectedUserId === user.id ? 'bg-blue-100' : 'hover:bg-gray-100'
                                } hover:pr-16`}
                                onClick={() => handleUserSelect(user.id)}
                                onMouseEnter={() => setHoveredUserId(user.id)}
                                onMouseLeave={() => setHoveredUserId(null)}
                            >
                                <div className="font-medium">{user.name}</div>
                                <div className="text-sm text-gray-500">{user.email}</div>

                                {hoveredUserId === user.id && (
                                    <button
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            handleShowDetails(user)
                                        }}
                                        className="absolute right-2 top-1/2 transform -translate-y-1/2 text-xs px-2 py-1 bg-blue-500 text-white rounded hover:bg-blue-600 transition-opacity duration-150"
                                    >
                                        Details
                                    </button>
                                )}
                            </li>
                        ))}
                        {filteredUsers.length === 0 && (
                            <li className="p-2 text-gray-500 text-center">No users found</li>
                        )}
                    </ul>
                )}
            </div>

            {/* User Details Modal */}
            <UserDetailsModal
                show={showDetailsModal}
                onClose={() => setShowDetailsModal(false)}
                user={selectedUserForModal}
                isAllUsers={isAllUsersSelected}
            />
        </div>
    )
}

export default UserList
