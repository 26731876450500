import DraftPostDetail from '../../components/admin/DraftPostDetail'
import DraftPostList from '../../components/admin/DraftPostList'
import UserList from '../../components/admin/UserList'
import { AdminStoreProvider } from '../../context/admin/store'
import React from 'react'

const AdminDashboardContent: React.FC = () => {
    return (
        <div className="min-h-screen bg-gray-50">
            <div className="py-4 px-6 bg-white shadow-sm border-b border-gray-200">
                <h1 className="text-xl font-semibold text-gray-800">Admin Dashboard</h1>
            </div>
            <div className="h-[calc(100vh-64px)]">
                <div className="flex h-full">
                    <UserList />
                    <DraftPostList />
                    <DraftPostDetail />
                </div>
            </div>
        </div>
    )
}

// Wrapper with provider
const AdminDashboard: React.FC = () => {
    return (
        <AdminStoreProvider>
            <AdminDashboardContent />
        </AdminStoreProvider>
    )
}

export default AdminDashboard
