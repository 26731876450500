import { Tabs } from 'flowbite-react'
import type React from 'react'
import type { ReactNode } from 'react'

interface FlowbiteTabProps {
    active: boolean
    title: string
    iconComponent: ReactNode
    children: ReactNode
}

const FlowbiteTab: React.FC<FlowbiteTabProps> = ({ active, title, iconComponent, children }) => {
    return (
        <Tabs.Item
            active={active}
            title={
                <div className="flex items-center gap-2">
                    {iconComponent}
                    <span>{title}</span>
                </div>
            }
        >
            {children}
        </Tabs.Item>
    )
}

export default FlowbiteTab
