import { ResearchResult, TranscriptChapter } from '../../types/admin-types'
import SourceItem from './SourceItem'
import React from 'react'

interface SourcesTabProps {
    researchResults?: ResearchResult[]
    transcriptChapters?: TranscriptChapter[]
}

const SourcesTab: React.FC<SourcesTabProps> = ({
    researchResults = [],
    transcriptChapters = [],
}) => {
    const totalSources = researchResults.length + transcriptChapters.length

    if (totalSources === 0) return null

    return (
        <div className="mt-4 border-t pt-4">
            <div className="w-full flex items-center justify-between text-left text-md font-medium text-blue-600 hover:text-blue-800">
                <span className="flex items-center">
                    <svg
                        className="w-4 h-4 mr-2"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M9 4.804A7.968 7.968 0 005.5 4c-1.255 0-2.443.29-3.5.804v10A7.969 7.969 0 015.5 14c1.669 0 3.218.51 4.5 1.385A7.962 7.962 0 0114.5 14c1.255 0 2.443.29 3.5.804v-10A7.968 7.968 0 0014.5 4c-1.255 0-2.443.29-3.5.804V12a1 1 0 11-2 0V4.804z"></path>
                    </svg>
                    Sources ({totalSources})
                </span>
            </div>

            <div className="mt-3 space-y-4">
                {/* Research Results Section */}
                {researchResults.length > 0 && (
                    <div>
                        <h4 className="text-sm font-semibold mb-2 text-gray-600">Articles</h4>
                        <div className="space-y-2">
                            {researchResults.map((result) => (
                                <SourceItem
                                    key={result.id}
                                    type="article"
                                    title={result.title}
                                    content={result}
                                />
                            ))}
                        </div>
                    </div>
                )}

                {/* Transcript Chapters Section */}
                {transcriptChapters.length > 0 && (
                    <div>
                        <h4 className="text-sm font-semibold mb-2 text-gray-600">Conversations</h4>
                        <div className="space-y-2">
                            {transcriptChapters.map((chapter) => (
                                <SourceItem
                                    key={chapter.id}
                                    type="conversation"
                                    title={chapter.chapter_title}
                                    content={chapter}
                                />
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default SourcesTab
