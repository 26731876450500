import { UserContentPost } from '../types/UserContent'
import { formatUserContentLinkDate } from '../utils/dateTimeFormatter'
import { getRandomInt } from '../utils/getRandomInt'
import { Button, Textarea } from 'flowbite-react'
import {
    Copy,
    Edit,
    RotateCcw,
    MessageSquare,
    Repeat2,
    ThumbsUp,
    Check,
    X,
    Save,
} from 'lucide-react'
import { useEffect, useState } from 'react'

interface ContentLinkCardProps {
    id: string
    post: UserContentPost
    isShared: boolean
    hasBeenEdited: boolean
    //handleEdit: (post: UserContentPost) => void
    handleShare: (post: UserContentPost) => void
    handleCopy: (post: UserContentPost) => void
    handleBackToOriginal: (post: UserContentPost) => void
    handleSave: (post: UserContentPost) => void
}

export default function ContentLinkCard({
    id,
    post,
    isShared = false,
    hasBeenEdited = true,
    //handleEdit,
    handleShare,
    handleCopy,
    handleBackToOriginal,
    handleSave,
}: ContentLinkCardProps) {
    const [copied, setCopied] = useState(false)
    const [isEditing, setIsEditing] = useState(false)
    const [editedContent, setEditedContent] = useState(post.content)

    const handleCopyClick = (post: UserContentPost) => {
        handleCopy(post)
        setCopied(true)
        setTimeout(() => setCopied(false), 3000)
    }

    const handleEditClick = () => {
        setIsEditing(true)
    }

    const handleCancelEdit = () => {
        setIsEditing(false)
        setEditedContent(post.content)
    }

    const handleSaveEdit = () => {
        post.content = editedContent
        handleSave(post)
        setIsEditing(false)
    }

    useEffect(() => {
        if (post) {
            post.likes = getRandomInt(1, 100)
            post.comments = getRandomInt(1, 100)
            post.shares = getRandomInt(1, 100)
        }
    }, [])

    const calculateRows = (content: string): number => {
        const lineCount = content.split('\n').length
        const estimatedRows = Math.ceil(content.length / 80) // Assuming average 50 characters per line
        return Math.max(lineCount, estimatedRows) + 3 // Add 3 extra rows
    }

    return (
        <div>
            <div
                id={id}
                className="w-[90%] rounded-lg border bg-white shadow-sm px-4 justify-self-center transition-all duration-300 ease-in-out"
            >
                <div className="p-4">
                    <div className="flex items-center gap-3">
                        <img
                            src={
                                post.user?.profile_photo?.url ??
                                'https://thetrustapp.s3.us-east-2.amazonaws.com/profile-icon-96x96.png'
                            }
                            width={40}
                            height={40}
                            className="w-10 h-10 rounded-full object-cover"
                            alt="profile_photo"
                        />
                        <div>
                            <h3 className="text-base font-semibold leading-none">
                                {post.user?.first_name} {post.user?.last_name}
                            </h3>
                            <p className="text-sm text-muted-foreground mt-0.5">
                                {post.user.title} •{' '}
                                {formatUserContentLinkDate(post.created_at.toString())}
                            </p>
                        </div>
                    </div>
                    {isEditing ? (
                        <Textarea
                            className="mt-3"
                            value={editedContent}
                            onChange={(e) => setEditedContent(e.target.value)}
                            rows={calculateRows(editedContent)}
                        />
                    ) : (
                        <p className="text-base mt-3 whitespace-pre-line">{post.content}</p>
                    )}
                </div>
                <div className="flex items-center justify-between border-t px-4 py-2 text-blue-600 text-sm">
                    <div className="flex gap-4">
                        <button className="flex items-center gap-1 text-sm text-muted-foreground hover:text-blue-700">
                            <ThumbsUp className="h-4 w-4" />
                            {post.likes ? (
                                <span>{post.likes}</span>
                            ) : (
                                <span>{getRandomInt(1, 100)}</span>
                            )}
                        </button>
                        <button className="flex items-center gap-1 text-sm text-muted-foreground hover:text-blue-700">
                            <MessageSquare className="h-4 w-4" />
                            {post.comments ? (
                                <span>{post.comments}</span>
                            ) : (
                                <span>{getRandomInt(1, 100)}</span>
                            )}
                        </button>
                        <button className="flex items-center gap-1 text-sm text-muted-foreground hover:text-blue-700">
                            <Repeat2 className="h-4 w-4" />
                            {post.shares ? (
                                <span>{post.shares}</span>
                            ) : (
                                <span>{getRandomInt(1, 100)}</span>
                            )}
                        </button>
                    </div>
                    {isEditing ? (
                        <div className="flex justify-end items-center gap-6">
                            <Button size="xs" color="light" onClick={handleCancelEdit}>
                                <X className="h-4 w-6 mr-1 gap-2" />
                                Cancel
                            </Button>
                            <Button size="xs" color="success" onClick={handleSaveEdit}>
                                <Save className="h-4 w-6 mr-1 gap-2" />
                                Save
                            </Button>
                        </div>
                    ) : (
                        <div className="flex items-center gap-2">
                            <button
                                className="inline-flex items-center justify-center gap-2 rounded-md px-3 py-1.5 text-xs font-medium shadow-sm hover:bg-gray-200 hover:shadow-lg transition duration-300"
                                onClick={() => handleCopyClick(post)}
                            >
                                {copied ? (
                                    <Check className="h-4 w-4 text-green-700 font-bold" />
                                ) : (
                                    <Copy className="h-4 w-4" />
                                )}
                                {copied ? 'Copied' : 'Copy'}
                                {/* <Copy className="h-4 w-4" />
                        Copy */}
                            </button>
                            <button
                                className={`inline-flex items-center justify-center gap-2 rounded-md px-3 py-1.5 text-xs font-medium ${
                                    isShared
                                        ? 'bg-green-100 text-green-700 hover:bg-green-200'
                                        : 'shadow-sm hover:bg-gray-200 hover:shadow-lg transition duration-300'
                                }`}
                                onClick={() => handleShare(post)}
                                //disabled={isShared}
                            >
                                {isShared ? 'Shared' : 'Mark as Shared'}
                            </button>
                            <button
                                className="inline-flex items-center justify-center gap-2 rounded-md px-3 py-1.5 text-xs font-medium shadow-sm hover:bg-gray-200 hover:shadow-lg transition duration-300"
                                onClick={() => handleEditClick()}
                            >
                                <Edit className="h-4 w-4" />
                                Edit
                            </button>
                            <button
                                className="inline-flex items-center justify-center gap-2 rounded-md px-3 py-1.5 text-xs font-medium shadow-sm disabled:bg-white disabled:cursor-not-allowed hover:bg-gray-200 hover:shadow-lg transition duration-300"
                                onClick={() => handleBackToOriginal(post)}
                                disabled={!hasBeenEdited}
                            >
                                <RotateCcw className="h-4 w-4" />
                                Reset
                            </button>
                        </div>
                    )}
                </div>
            </div>
            <div
                id={'alpha-' + id}
                className="w-[90%] mb-8 rounded-lg border bg-sky-50 shadow-sm px-4 justify-self-center transition-all duration-300 ease-in-out"
            >
                {post.alpha_insight && (
                    <div className="flex justify-center items-center p-4">
                        {/* <h4 className="text-sm font-semibold text-muted-foreground">
                            Alpha Insight
                        </h4> */}
                        <p className="text-sm mt-1 whitespace-pre-line italic">
                            {post.alpha_insight.reasoning}
                        </p>
                    </div>
                )}
            </div>
        </div>
    )
}
