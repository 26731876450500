import { Card, Button, Select } from 'flowbite-react'
import { Calendar, Maximize2, Minimize2 } from 'lucide-react'
import { useState } from 'react'

;('use client')

interface GoogleCalendarEmbedProps {
    calendarId?: string
    title?: string
    initialView?: 'day' | 'week' | 'month' | 'agenda'
    height?: number
    className?: string
}

export default function GoogleCalendarEmbed({
    calendarId = 'toni@toneai.xyz',
    title = "Toni's Calendar",
    initialView = 'week',
    height = 600,
    className = '',
}: GoogleCalendarEmbedProps) {
    const [view, setView] = useState<string>(initialView)
    const [isExpanded, setIsExpanded] = useState<boolean>(false)
    const [iframeHeight, setIframeHeight] = useState<number>(height)

    // Base calendar URL
    const baseUrl = `https://calendar.google.com/calendar/embed?src=${encodeURIComponent(
        calendarId,
    )}`

    // Add view mode
    const calendarUrl = `${baseUrl}&mode=${view}&showTitle=0&showNav=1&showDate=1&showPrint=0&showTabs=1&showCalendars=0&showTz=1`
    //const calendarUrl = `https://calendar.google.com/calendar/embed?src=toni%40toneai.xyz&ctz=America%2FNew_York`

    const toggleExpand = () => {
        setIsExpanded(!isExpanded)
        setIframeHeight(isExpanded ? height : height * 1.5)
    }

    return (
        <Card className={`w-full overflow-hidden ${className}`}>
            <div className="flex justify-between items-center mb-4">
                <div className="flex items-center">
                    <Calendar className="mr-2 h-5 w-5 text-blue-600" />
                    <h3 className="text-xl font-semibold">{title}</h3>
                </div>
                <div className="flex items-center gap-2">
                    <Select
                        id="calendar-view"
                        value={view}
                        onChange={(e) => setView(e.target.value)}
                        className="text-sm"
                    >
                        <option value="day">Day</option>
                        <option value="week">Week</option>
                        <option value="month">Month</option>
                        <option value="agenda">Agenda</option>
                    </Select>
                    <Button color="light" size="sm" onClick={toggleExpand}>
                        {isExpanded ? (
                            <>
                                <Minimize2 className="mr-1 h-4 w-4" />
                                <span className="hidden sm:inline">Collapse</span>
                            </>
                        ) : (
                            <>
                                <Maximize2 className="mr-1 h-4 w-4" />
                                <span className="hidden sm:inline">Expand</span>
                            </>
                        )}
                    </Button>
                </div>
            </div>
            <div
                className="w-full transition-all duration-300 ease-in-out"
                style={{ height: `${iframeHeight}px` }}
            >
                <iframe
                    src={calendarUrl}
                    style={{ border: 0 }}
                    width="100%"
                    height="100%"
                    frameBorder="0"
                    scrolling="no"
                    title={title}
                    className="rounded-lg"
                    sandbox="allow-scripts allow-same-origin allow-popups"
                ></iframe>
            </div>
        </Card>
    )
}
