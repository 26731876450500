import {
    AdminApi,
    RunMode,
    PipelineStepName,
    CreatePipelineJobsDto,
    NotificationType,
    TriggerEmailNotificationDto,
} from '../../api/admin'
import { useAdminStore } from '../../context/admin/store'
import { AdminUserDto } from '../../types/admin-types'
import { Modal } from 'flowbite-react'
import { Tooltip } from 'flowbite-react'
import React, { useState, useEffect } from 'react'
import { HiInformationCircle, HiExternalLink } from 'react-icons/hi'

interface UserDetailsModalProps {
    show: boolean
    onClose: () => void
    user: AdminUserDto | null
    isAllUsers: boolean
}

const UserDetailsModal: React.FC<UserDetailsModalProps> = ({ show, onClose, user, isAllUsers }) => {
    const { state } = useAdminStore()
    const { users } = state

    const [runMode, setRunMode] = useState<RunMode>(RunMode.INDIVIDUAL)
    const [transcriptChapterId, setTranscriptChapterId] = useState<string>('')
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [error, setError] = useState<string | null>(null)
    const [success, setSuccess] = useState(false)
    const [totalPendingChapters, setTotalPendingChapters] = useState<number>(0)

    // Email notification states
    const [notificationType, setNotificationType] = useState<NotificationType>(
        NotificationType.NEW_POSTS_AVAILABLE,
    )
    const [dryRun, setDryRun] = useState<boolean>(true)
    const [emailSubmitting, setEmailSubmitting] = useState(false)
    const [emailError, setEmailError] = useState<string | null>(null)
    const [emailSuccess, setEmailSuccess] = useState(false)

    // Calculate total pending chapters from all users
    const calculateTotalPendingChapters = () => {
        if (!users.length) return 0

        return users.reduce((total, user) => {
            return total + (user.pending_transcript_chapter_count || 0)
        }, 0)
    }

    // Reset form when opening the modal
    useEffect(() => {
        if (show) {
            // For "All Users", always set run mode to ALL
            if (isAllUsers) {
                setRunMode(RunMode.ALL)

                // Calculate total pending chapters from all users
                setTotalPendingChapters(calculateTotalPendingChapters())
            } else {
                setRunMode(RunMode.INDIVIDUAL)
            }

            setTranscriptChapterId('')
            setError(null)
            setSuccess(false)

            // Reset email notification form
            setNotificationType(NotificationType.NEW_POSTS_AVAILABLE)
            setDryRun(true)
            setEmailError(null)
            setEmailSuccess(false)
        }
    }, [show, isAllUsers, user, users])

    const handleRunPipeline = async () => {
        setIsSubmitting(true)
        setError(null)
        setSuccess(false)

        try {
            const pipelineDto: CreatePipelineJobsDto = {
                step: 'augment_transcript_chapters',
                context: {
                    // For "All Users", always use RunMode.ALL
                    run_mode: isAllUsers ? RunMode.ALL : runMode,
                    next_step_name: PipelineStepName.CREATE_POST_IDEAS,
                },
            }

            // Add transcript_chapter_id if needed (only for individual user + individual chapter)
            if (!isAllUsers && runMode === RunMode.INDIVIDUAL && transcriptChapterId) {
                pipelineDto.context.transcript_chapter_id = transcriptChapterId
            }

            // Add user_ids if not "All Users"
            if (!isAllUsers && user) {
                pipelineDto.user_ids = [user.id]
            }

            // Call the pipeline API
            await AdminApi.createPipelineJobs(pipelineDto)
            setSuccess(true)
        } catch (err) {
            setError('Failed to start pipeline process')
        } finally {
            setIsSubmitting(false)
        }
    }

    const handleTriggerEmailNotification = async () => {
        setEmailSubmitting(true)
        setEmailError(null)
        setEmailSuccess(false)

        try {
            const notificationDto: TriggerEmailNotificationDto = {
                notification_type: notificationType,
                dry_run: dryRun,
            }

            // Add user_ids if not "All Users"
            if (!isAllUsers && user) {
                notificationDto.user_ids = [user.id]
            }

            // Call the email notification API
            await AdminApi.triggerEmailNotification(notificationDto)
            setEmailSuccess(true)
        } catch (err) {
            setEmailError('Failed to trigger email notification')
        } finally {
            setEmailSubmitting(false)
        }
    }

    const handleClose = () => {
        // Reset form state
        setRunMode(RunMode.INDIVIDUAL)
        setTranscriptChapterId('')
        setError(null)
        setSuccess(false)

        // Reset email notification form state
        setNotificationType(NotificationType.NEW_POSTS_AVAILABLE)
        setDryRun(true)
        setEmailError(null)
        setEmailSuccess(false)

        onClose()
    }
    const handleViewContentAsUser = () => {
        if (user && user.username && user.slug) {
            // Close the modal first
            handleClose()
            // Navigate to the user's content dashboard
            window.open(`/v2/content/${user.username}/${user.slug}`, '_blank')
        }
    }
    const title = isAllUsers ? 'All Users Pipeline Controls' : `User Details: ${user?.name || ''}`

    return (
        <Modal show={show} size="md" onClose={handleClose} className="z-[100000]">
            <Modal.Header>{title}</Modal.Header>
            <Modal.Body>
                <div className="space-y-6">
                    {!isAllUsers && user && (
                        <div className="flex flex-col space-y-3">
                            <div>
                                <p className="font-semibold">{user.name}</p>
                                <p className="text-gray-600">{user.email}</p>
                            </div>
                            <button
                                type="button"
                                onClick={handleViewContentAsUser}
                                className="flex items-center justify-center text-white bg-indigo-600 hover:bg-indigo-700 focus:ring-4 focus:outline-none focus:ring-indigo-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                            >
                                <HiExternalLink className="mr-2" /> View Content as User
                            </button>
                        </div>
                    )}

                    {/* Pipeline Controls Section */}
                    <div className="border-b pb-4">
                        <h3 className="text-lg font-semibold mb-3">Pipeline Controls</h3>
                        <div className="space-y-4">
                            <div className="bg-gray-100 p-3 rounded-md">
                                <h3 className="text-md font-semibold mb-1">
                                    {isAllUsers
                                        ? 'Total Pending Transcript Chapters'
                                        : 'Pending Transcript Chapters'}
                                </h3>
                                <p className="text-lg font-bold text-blue-600">
                                    {isAllUsers
                                        ? totalPendingChapters
                                        : user?.pending_transcript_chapter_count || 0}
                                </p>
                            </div>

                            {/* Show Run Mode selector only for individual users */}
                            {!isAllUsers && (
                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-1">
                                        Run Mode
                                    </label>
                                    <select
                                        className="w-full px-3 py-2 border border-gray-300 rounded-md"
                                        value={runMode}
                                        onChange={(e) => setRunMode(e.target.value as RunMode)}
                                    >
                                        <option value={RunMode.INDIVIDUAL}>
                                            Individual Chapter
                                        </option>
                                        <option value={RunMode.ALL}>All Chapters</option>
                                    </select>
                                </div>
                            )}

                            {/* Show Transcript Chapter ID field only for individual users in individual mode */}
                            {!isAllUsers && runMode === RunMode.INDIVIDUAL && (
                                <div>
                                    <label className="block text-sm font-medium text-gray-700 mb-1">
                                        Transcript Chapter ID
                                    </label>
                                    <input
                                        type="text"
                                        className="w-full px-3 py-2 border border-gray-300 rounded-md"
                                        value={transcriptChapterId}
                                        onChange={(e) => setTranscriptChapterId(e.target.value)}
                                        placeholder="Enter chapter ID"
                                        required
                                    />
                                </div>
                            )}

                            {isAllUsers && (
                                <div className="bg-blue-50 p-3 rounded-md">
                                    <p className="text-sm text-blue-800">
                                        This will process all pending transcript chapters for all
                                        users.
                                    </p>
                                </div>
                            )}

                            {error && (
                                <div className="bg-red-100 text-red-700 p-3 rounded-md">
                                    {error}
                                </div>
                            )}

                            {success && (
                                <div className="bg-green-100 text-green-700 p-3 rounded-md">
                                    Pipeline process started successfully!
                                </div>
                            )}

                            <button
                                type="button"
                                onClick={handleRunPipeline}
                                disabled={
                                    isSubmitting ||
                                    (!isAllUsers &&
                                        runMode === RunMode.INDIVIDUAL &&
                                        !transcriptChapterId)
                                }
                                className="w-full text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center disabled:bg-blue-300"
                            >
                                {isSubmitting ? 'Processing...' : 'Start Pipeline Process'}
                            </button>
                        </div>
                    </div>

                    {/* Email Notification Section */}
                    <div>
                        <h3 className="text-lg font-semibold mb-3">Email Notifications</h3>

                        <div className="space-y-4">
                            <div>
                                <label className="block text-sm font-medium text-gray-700 mb-1">
                                    Notification Type
                                </label>
                                <select
                                    className="w-full px-3 py-2 border border-gray-300 rounded-md"
                                    value={notificationType}
                                    onChange={(e) =>
                                        setNotificationType(e.target.value as NotificationType)
                                    }
                                >
                                    <option value={NotificationType.NEW_POSTS_AVAILABLE}>
                                        New Posts Available
                                    </option>
                                </select>
                            </div>

                            <div className="flex items-center">
                                <input
                                    id="dry-run"
                                    type="checkbox"
                                    checked={dryRun}
                                    onChange={(e) => setDryRun(e.target.checked)}
                                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                                />
                                <label
                                    htmlFor="dry-run"
                                    className="ml-2 text-sm font-medium text-gray-700"
                                >
                                    Dry Run
                                </label>
                                <div className="ml-2">
                                    <Tooltip content="This goes to your email if you check it">
                                        <HiInformationCircle className="text-gray-500" />
                                    </Tooltip>
                                </div>
                            </div>

                            {isAllUsers && (
                                <div className="bg-blue-50 p-3 rounded-md">
                                    <p className="text-sm text-blue-800">
                                        This will send emails to all users.
                                    </p>
                                </div>
                            )}
                            {!isAllUsers && user && (
                                <div className="bg-yellow-50 p-3 text-grey-800 rounded-md">
                                    <p className="text-sm">
                                        New Posts Since Last Email:{' '}
                                        <span className="font-medium">
                                            {user.num_draft_posts_since_last_email || 0}
                                        </span>
                                    </p>
                                    <p className="text-sm">
                                        Last Emailed At:{' '}
                                        <span className="font-medium">
                                            {user.last_emailed_at
                                                ? new Date(user.last_emailed_at).toLocaleString()
                                                : 'Never'}
                                        </span>
                                    </p>
                                </div>
                            )}

                            {emailError && (
                                <div className="bg-red-100 text-red-700 p-3 rounded-md">
                                    {emailError}
                                </div>
                            )}

                            {emailSuccess && (
                                <div className="bg-green-100 text-green-700 p-3 rounded-md">
                                    Email notification triggered successfully!
                                </div>
                            )}

                            <button
                                type="button"
                                onClick={handleTriggerEmailNotification}
                                disabled={emailSubmitting}
                                className="w-full text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center disabled:bg-blue-300"
                            >
                                {emailSubmitting ? 'Sending...' : 'Send Email Notification'}
                            </button>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button
                    type="button"
                    onClick={handleClose}
                    className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10"
                >
                    Close
                </button>
            </Modal.Footer>
        </Modal>
    )
}

export default UserDetailsModal
