import {
    AdminUserDto,
    AdminDraftPostDto,
    AdminDraftPostDetailedDto,
    DraftPostViewMode,
    DraftPostStatus,
} from '../../types/admin-types'

export const ITEMS_PER_PAGE = 20 // Define the number of items per page

export interface AdminState {
    users: AdminUserDto[]
    totalUsers: number
    selectedUserId: string | null
    draftPosts: AdminDraftPostDto[]
    totalDraftPosts: number
    selectedDraftPostId: string | null
    selectedDraftPost: AdminDraftPostDetailedDto | null
    selectedDraftPostIds: string[] // New state for bulk selection
    viewMode: DraftPostViewMode
    selectedStatus: DraftPostStatus | null
    searchTerm: string
    currentPage: number
    loading: {
        users: boolean
        draftPosts: boolean
        draftPostDetail: boolean
        feedback: boolean
        status: boolean
        bulkUpdate: boolean // New loading state for bulk actions
    }
    error: {
        users: string | null
        draftPosts: string | null
        draftPostDetail: string | null
        feedback: string | null
        status: string | null
        bulkUpdate: string | null // New error state for bulk actions
    }
    showUserList: boolean // Control user list visibility
}

export enum AdminActionType {
    FETCH_USERS_REQUEST = 'FETCH_USERS_REQUEST',
    FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS',
    FETCH_USERS_FAILURE = 'FETCH_USERS_FAILURE',
    SELECT_USER = 'SELECT_USER',

    FETCH_DRAFT_POSTS_REQUEST = 'FETCH_DRAFT_POSTS_REQUEST',
    FETCH_DRAFT_POSTS_SUCCESS = 'FETCH_DRAFT_POSTS_SUCCESS',
    FETCH_DRAFT_POSTS_FAILURE = 'FETCH_DRAFT_POSTS_FAILURE',
    SELECT_DRAFT_POST = 'SELECT_DRAFT_POST',

    FETCH_DRAFT_POST_DETAIL_REQUEST = 'FETCH_DRAFT_POST_DETAIL_REQUEST',
    FETCH_DRAFT_POST_DETAIL_SUCCESS = 'FETCH_DRAFT_POST_DETAIL_SUCCESS',
    FETCH_DRAFT_POST_DETAIL_FAILURE = 'FETCH_DRAFT_POST_DETAIL_FAILURE',

    SET_VIEW_MODE = 'SET_VIEW_MODE',
    SET_STATUS_FILTER = 'SET_STATUS_FILTER',
    SET_SEARCH_TERM = 'SET_SEARCH_TERM',
    SET_CURRENT_PAGE = 'SET_CURRENT_PAGE',
    TOGGLE_USER_LIST = 'TOGGLE_USER_LIST',

    ADD_FEEDBACK_REQUEST = 'ADD_FEEDBACK_REQUEST',
    ADD_FEEDBACK_SUCCESS = 'ADD_FEEDBACK_SUCCESS',
    ADD_FEEDBACK_FAILURE = 'ADD_FEEDBACK_FAILURE',

    UPDATE_STATUS_REQUEST = 'UPDATE_STATUS_REQUEST',
    UPDATE_STATUS_SUCCESS = 'UPDATE_STATUS_SUCCESS',
    UPDATE_STATUS_FAILURE = 'UPDATE_STATUS_FAILURE',

    // Bulk selection actions
    SELECT_DRAFT_POST_BULK = 'SELECT_DRAFT_POST_BULK',
    DESELECT_DRAFT_POST_BULK = 'DESELECT_DRAFT_POST_BULK',
    CLEAR_BULK_SELECTION = 'CLEAR_BULK_SELECTION',

    // Bulk update actions
    BULK_UPDATE_STATUS_REQUEST = 'BULK_UPDATE_STATUS_REQUEST',
    BULK_UPDATE_STATUS_SUCCESS = 'BULK_UPDATE_STATUS_SUCCESS',
    BULK_UPDATE_STATUS_FAILURE = 'BULK_UPDATE_STATUS_FAILURE',
}

export type AdminAction =
    | { type: AdminActionType.FETCH_USERS_REQUEST }
    | {
          type: AdminActionType.FETCH_USERS_SUCCESS
          payload: { users: AdminUserDto[]; total: number }
      }
    | { type: AdminActionType.FETCH_USERS_FAILURE; payload: string }
    | { type: AdminActionType.SELECT_USER; payload: string | null }
    | { type: AdminActionType.FETCH_DRAFT_POSTS_REQUEST }
    | {
          type: AdminActionType.FETCH_DRAFT_POSTS_SUCCESS
          payload: {
              draftPosts: AdminDraftPostDto[]
              pagination: { total: number; page: number; limit: number }
          }
      }
    | { type: AdminActionType.FETCH_DRAFT_POSTS_FAILURE; payload: string }
    | { type: AdminActionType.SELECT_DRAFT_POST; payload: string }
    | { type: AdminActionType.FETCH_DRAFT_POST_DETAIL_REQUEST }
    | { type: AdminActionType.FETCH_DRAFT_POST_DETAIL_SUCCESS; payload: AdminDraftPostDetailedDto }
    | { type: AdminActionType.FETCH_DRAFT_POST_DETAIL_FAILURE; payload: string }
    | { type: AdminActionType.SET_VIEW_MODE; payload: DraftPostViewMode }
    | { type: AdminActionType.SET_STATUS_FILTER; payload: string | null }
    | { type: AdminActionType.SET_SEARCH_TERM; payload: string }
    | { type: AdminActionType.SET_CURRENT_PAGE; payload: number }
    | { type: AdminActionType.TOGGLE_USER_LIST }
    | { type: AdminActionType.ADD_FEEDBACK_REQUEST }
    | {
          type: AdminActionType.ADD_FEEDBACK_SUCCESS
          payload: { id: string; content: string; timestamp: string }
      }
    | { type: AdminActionType.ADD_FEEDBACK_FAILURE; payload: string }
    | { type: AdminActionType.UPDATE_STATUS_REQUEST }
    | { type: AdminActionType.UPDATE_STATUS_SUCCESS; payload: { id: string; status: string } }
    | { type: AdminActionType.UPDATE_STATUS_FAILURE; payload: string }
    // Bulk selection actions
    | { type: AdminActionType.SELECT_DRAFT_POST_BULK; payload: string } // payload is postId
    | { type: AdminActionType.DESELECT_DRAFT_POST_BULK; payload: string } // payload is postId
    | { type: AdminActionType.CLEAR_BULK_SELECTION }
    // Bulk update actions
    | { type: AdminActionType.BULK_UPDATE_STATUS_REQUEST }
    | { type: AdminActionType.BULK_UPDATE_STATUS_SUCCESS; payload: { count: number } } // Example payload
    | { type: AdminActionType.BULK_UPDATE_STATUS_FAILURE; payload: string }
