import ApiService from './apiService'
import { AxiosResponse } from 'axios'

export interface ListPostsParams {
    page?: number
    limit?: number
}

export interface MeetingInfoDto {
    created_at: string
    title: string
    attendees: string[]
    transcript: string | null
}

export interface PostListItemDto {
    id: string
    created_at: string
    updated_at: string
    idea_title: string
    idea_basis: string
    idea_core_idea: string
    idea_research_enhancement_explanation: string
    idea_relation_to_target_audience: string
    post_content: string
    user_feedback_sentiment?: string
    user_feedback?: string
    meeting: MeetingInfoDto
}

export interface PostListResponseDto {
    posts: PostListItemDto[]
    total: number
    page: number
    limit: number
}

export interface ResearchResultDto {
    id: string
    title: string
    summary: string
    url: string
}

export interface TranscriptChapterDto {
    id: string
    title: string
    summary: string
    key_quotes: string[]
    meeting: MeetingInfoDto
}

export interface PostDetailsDto extends PostListItemDto {
    research_results: ResearchResultDto[]
    transcript_chapters: TranscriptChapterDto[]
}

export interface UpdatePostDto {
    post_content: string
}

export enum EngagementType {
    COPY = 'copy',
    THUMBS_UP = 'thumbs_up',
    THUMBS_DOWN = 'thumbs_down',
}

export interface CreateEngagementDto {
    type: EngagementType
    feedback?: string
}

export interface EngagementResponseDto {
    id: string
    type: EngagementType
    feedback?: string
    created_at: string
    copied_at?: string
    user_feedback?: string
    user_feedback_sentiment?: string
}

export interface DashboardApiInterface {
    listPosts(
        userName: string,
        userSlug: string,
        params?: ListPostsParams,
    ): Promise<AxiosResponse<PostListResponseDto>>
    getPost(
        userName: string,
        userSlug: string,
        postId: string,
    ): Promise<AxiosResponse<PostDetailsDto>>
    updatePost(
        userName: string,
        userSlug: string,
        postId: string,
        data: UpdatePostDto,
    ): Promise<AxiosResponse<PostDetailsDto>>
    trackEngagement(
        userName: string,
        userSlug: string,
        postId: string,
        data: CreateEngagementDto,
    ): Promise<AxiosResponse<EngagementResponseDto>>
}

export const dashboardApi: DashboardApiInterface = {
    listPosts: (
        userName: string,
        userSlug: string,
        params?: ListPostsParams,
    ): Promise<AxiosResponse<PostListResponseDto>> => {
        return ApiService.get(`v2/dashboard/posts`, null, params, {
            'x-user-name': userName,
            'x-user-slug': userSlug,
        })
    },

    getPost: (userName: string, userSlug: string, postId: string) => {
        return ApiService.get(
            `v2/dashboard/posts/${postId}`,
            null,
            {},
            {
                'x-user-name': userName,
                'x-user-slug': userSlug,
            },
        )
    },

    updatePost: (userName: string, userSlug: string, postId: string, data: UpdatePostDto) => {
        return ApiService.patch(
            `v2/dashboard/posts/${postId}`,
            data,
            {},
            {
                'x-user-name': userName,
                'x-user-slug': userSlug,
            },
        )
    },

    trackEngagement: (
        userName: string,
        userSlug: string,
        postId: string,
        data: CreateEngagementDto,
    ): Promise<AxiosResponse<EngagementResponseDto>> => {
        return ApiService.post(`v2/dashboard/posts/${postId}/engagement`, data, {}, undefined, {
            'x-user-name': userName,
            'x-user-slug': userSlug,
        })
    },
}
