import {
    AdminUserDto,
    AdminDraftPostDto,
    AdminDraftPostDetailedDto,
    ListUsersQueryDto,
    ListDraftPostsQueryDto,
    DraftPostFeedbackResponseDto,
    UpdateDraftPostStatusResponseDto,
    BulkUpdateDraftPostStatusDto,
    BulkUpdateDraftPostStatusResponseDto,
} from '../types/admin-types'
import ApiService from './apiService'
import { AxiosResponse } from 'axios'

// Email notification types
export enum NotificationType {
    NEW_POSTS_AVAILABLE = 'new_posts_available',
}

export interface TriggerEmailNotificationDto {
    notification_type: NotificationType
    dry_run?: boolean
    user_ids?: string[]
}

export interface NotificationResultDto {
    usersProcessed: number
    emailsSent: number
}

// Pipeline types
export enum RunMode {
    INDIVIDUAL = 'individual',
    ALL = 'all',
}

export enum PipelineStepName {
    RESEARCH = 'research',
    AUGMENT_TRANSCRIPT_CHAPTERS = 'augment_transcript_chapters',
    CREATE_POST_IDEAS = 'create_post_ideas',
    CREATE_DRAFT_FROM_IDEA = 'create_draft_from_idea',
}

export interface StepContext {
    run_mode: RunMode
    next_step_name?: PipelineStepName
    transcript_chapter_id?: string
}

export interface CreatePipelineJobsDto {
    user_ids?: string[]
    step: string
    context: StepContext
}

export interface PipelineJob {
    job_id: string
    user_id: string
    user_name: string
    status: string
    current_step: string
    created_at: string
}

export interface AdminApi {
    listUsers(
        query?: ListUsersQueryDto,
    ): Promise<AxiosResponse<{ users: AdminUserDto[]; total: number }>>
    listDraftPosts(query?: ListDraftPostsQueryDto): Promise<
        AxiosResponse<{
            draft_posts: AdminDraftPostDto[]
            pagination: { total: number; page: number; limit: number }
        }>
    >
    getDraftPost(id: string): Promise<AxiosResponse<{ draft_post: AdminDraftPostDetailedDto }>>
    addFeedback(id: string, content: string): Promise<AxiosResponse<DraftPostFeedbackResponseDto>>
    updateStatus(
        id: string,
        status: string,
    ): Promise<AxiosResponse<UpdateDraftPostStatusResponseDto>>
    createPipelineJobs(
        config: CreatePipelineJobsDto,
    ): Promise<AxiosResponse<{ jobs: PipelineJob[] }>>
    triggerEmailNotification(
        config: TriggerEmailNotificationDto,
    ): Promise<AxiosResponse<NotificationResultDto>>
    bulkUpdateStatus(
        data: BulkUpdateDraftPostStatusDto,
    ): Promise<AxiosResponse<BulkUpdateDraftPostStatusResponseDto>>
}

export const AdminApi: AdminApi = Object.freeze({
    listUsers(): Promise<AxiosResponse<{ users: AdminUserDto[]; total: number }>> {
        return ApiService.get('/admin/draft-posts/users', null, {})
    },

    listDraftPosts(query?: ListDraftPostsQueryDto): Promise<
        AxiosResponse<{
            draft_posts: AdminDraftPostDto[]
            pagination: { total: number; page: number; limit: number }
        }>
    > {
        return ApiService.get('/admin/draft-posts', null, query)
    },

    getDraftPost(id: string): Promise<AxiosResponse<{ draft_post: AdminDraftPostDetailedDto }>> {
        return ApiService.get(`/admin/draft-posts/${id}`)
    },

    addFeedback(id: string, content: string): Promise<AxiosResponse<DraftPostFeedbackResponseDto>> {
        return ApiService.post(`/admin/draft-posts/${id}/feedback`, { content })
    },

    updateStatus(
        id: string,
        status: string,
    ): Promise<AxiosResponse<UpdateDraftPostStatusResponseDto>> {
        return ApiService.post(`/admin/draft-posts/${id}/status`, { status })
    },

    async bulkUpdateStatus(
        data: BulkUpdateDraftPostStatusDto,
    ): Promise<AxiosResponse<BulkUpdateDraftPostStatusResponseDto>> {
        const { ids, status } = data
        let successful_updates = 0
        let failed_updates = 0

        // Use Promise.allSettled to handle all updates concurrently and gather results
        const results = await Promise.allSettled(
            ids.map((id) => ApiService.post(`/admin/draft-posts/${id}/status`, { status })),
        )

        results.forEach((result) => {
            if (result.status === status) {
                successful_updates++
            } else {
                failed_updates++
            }
        })

        // Construct a response object similar to what AxiosResponse expects
        // This is a mock response structure; the actual API might return something different.
        const responseData: BulkUpdateDraftPostStatusResponseDto = {
            successful_updates,
            failed_updates,
        }

        // Simulate an AxiosResponse. Adjust status code/headers as needed.
        // If any update failed, we might want to indicate a partial success or failure status code.
        const simulatedAxiosResponse: AxiosResponse<BulkUpdateDraftPostStatusResponseDto> = {
            data: responseData,
            status: failed_updates > 0 ? 207 : 200, // 207 Multi-Status or 200 OK
            statusText: failed_updates > 0 ? 'Multi-Status' : 'OK',
            headers: {},
            config: { headers: undefined as any }, // Mock config as Axios requires it
        }

        return Promise.resolve(simulatedAxiosResponse)
    },

    createPipelineJobs(
        config: CreatePipelineJobsDto,
    ): Promise<AxiosResponse<{ jobs: PipelineJob[] }>> {
        return ApiService.post('/pipeline/jobs', config)
    },

    triggerEmailNotification(
        config: TriggerEmailNotificationDto,
    ): Promise<AxiosResponse<NotificationResultDto>> {
        return ApiService.post('/email-notifications/trigger', config)
    },
})
