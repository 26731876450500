import { CreateApiClient } from './createApiClient'

const apiService = {
    async get(
        resource: string,
        id: string | null = null,
        queryParams: any = {},
        headers: any = {},
    ) {
        const apiClient = await CreateApiClient(undefined, headers)
        if (id) {
            resource += '/' + id
        }
        return apiClient.get(resource, { params: queryParams })
    },
    async delete(resource: string, data: any = {}, headers: any = {}) {
        const apiClient = await CreateApiClient(undefined, headers)
        return apiClient.delete(resource, { data })
    },
    async post(resource: string, data: any, config?: any, timeout?: number, headers: any = {}) {
        const apiClient = await CreateApiClient(timeout, headers)
        return apiClient.post(
            resource,
            Object.keys(data).reduce(
                (acc, key) => (data[key] === undefined ? acc : { ...acc, [key]: data[key] }),
                {},
            ), // remove all properties with undefined value
            config,
        )
    },
    async postFormData(resource: string, formData: any, config?: any, headers: any = {}) {
        const apiClient = await CreateApiClient(undefined, headers)
        return apiClient.post(resource, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            ...config,
        })
    },
    async patch(resource: string, data: any, config?: any, headers: any = {}) {
        const apiClient = await CreateApiClient(undefined, headers)
        return apiClient.patch(resource, data, config)
    },
    async patchFormData(resource: string, formData: any, config?: any, headers: any = {}) {
        const apiClient = await CreateApiClient(undefined, headers)
        return apiClient.patch(resource, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            ...config,
        })
    },
    async download(resource: string, headers: any = {}) {
        const apiClient = await CreateApiClient(undefined, headers)
        return apiClient.get(resource, {
            responseType: 'blob',
            timeout: 600000, // 10 minutes
        })
    },
    async put(resource: string, data: any = {}, config?: any, headers: any = {}) {
        const apiClient = await CreateApiClient(undefined, headers)
        return apiClient.put(resource, data, config)
    },
}

export default apiService
