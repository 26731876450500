import { clsx, type ClassValue } from 'clsx'
// eslint-disable-next-line unused-imports/no-unused-imports, no-unused-vars
import * as React from 'react'
import { twMerge } from 'tailwind-merge'

/**
 * Format a date string to display both date and time
 */
export function formatDateTime(dateString: string): string {
    const date = new Date(dateString)

    // Format date: Mon, Jan 1
    const dateFormatted = date.toLocaleDateString('en-US', {
        weekday: 'short',
        month: 'short',
        day: 'numeric',
    })

    // Format time: 10:30 AM
    const timeFormatted = date.toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
    })

    return `${dateFormatted}, ${timeFormatted}`
}

/**
 * Format a date string to display only time
 */
export function formatTime(dateString: string): string {
    const date = new Date(dateString)
    return date.toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
    })
}

/**
 * Format a date string to display only date
 */
export function formatDateLocale(dateString: string): string {
    const date = new Date(dateString)
    return date.toLocaleDateString('en-US', {
        weekday: 'short',
        month: 'short',
        day: 'numeric',
    })
}

/**
 * Check if a date is today
 */
export function isToday(dateString: string): boolean {
    const date = new Date(dateString)
    const today = new Date()
    return (
        date.getDate() === today.getDate() &&
        date.getMonth() === today.getMonth() &&
        date.getFullYear() === today.getFullYear()
    )
}

export function cn(...inputs: ClassValue[]) {
    return twMerge(clsx(inputs))
}

export function formatDate(input: string | number): string {
    const date = new Date(input)
    return date.toLocaleDateString('en-US', {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
    })
}

export function absoluteUrl(path: string) {
    return `${process.env.NEXT_PUBLIC_APP_URL}${path}`
}
