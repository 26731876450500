import { EngagementType } from '../../api/dashboard-api'
import { useDashboardStore } from '../../context/dashboard/store'
import FeedbackModal from './FeedbackModal'
import SourcesTab from './SourcesTab'
import React, { useState, useRef, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

// Simple CopyButton component
const CopyButton: React.FC<{ text: string; onCopy?: () => void }> = ({ text, onCopy }) => {
    const [copied, setCopied] = useState(false)

    const handleCopy = async () => {
        await navigator.clipboard.writeText(text)
        setCopied(true)
        if (onCopy) onCopy()
        setTimeout(() => setCopied(false), 2000)
    }

    return (
        <button onClick={handleCopy} className="btn btn-secondary flex items-center">
            {copied ? '✓ Copied!' : '📋 Copy'}
        </button>
    )
}

const PostDetail: React.FC = () => {
    const { state, updatePost, trackEngagement } = useDashboardStore()
    const { selectedPost, loading } = state
    const [searchParams] = useSearchParams()

    const [editMode, setEditMode] = useState(false)
    const [postContent, setPostContent] = useState('')
    const [saveState, setSaveState] = useState<'idle' | 'loading' | 'success'>('idle')
    const [feedbackModalOpen, setFeedbackModalOpen] = useState(false)

    // Use this ref to track if we've shown the feedback modal for deeplink
    const feedbackModalShownRef = useRef(false)

    const textareaRef = useRef<HTMLTextAreaElement>(null)

    // Check if we need to open the feedback modal from deeplink
    useEffect(() => {
        const action = searchParams.get('action')
        const postId = searchParams.get('post')

        // If action is thumbs_down and we have the post loaded and haven't shown the modal yet
        if (
            action === 'thumbs_down' &&
            selectedPost &&
            postId === selectedPost.id &&
            !feedbackModalShownRef.current &&
            !loading.engagement
        ) {
            feedbackModalShownRef.current = true
            setFeedbackModalOpen(true)
        }
    }, [searchParams, selectedPost, loading.engagement])

    // Enter edit mode
    const handleEdit = () => {
        if (selectedPost) {
            setPostContent(selectedPost.post_content)
            setEditMode(true)
            // Focus and auto-size the textarea after it becomes visible
            setTimeout(() => {
                if (textareaRef.current) {
                    textareaRef.current.focus()
                    textareaRef.current.style.height = 'auto'
                    textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`
                }
            }, 0)
        }
    }

    // Cancel edit mode
    const handleCancel = () => {
        setEditMode(false)
        setSaveState('idle')
    }

    // Save edited content
    const handleSave = async () => {
        if (selectedPost) {
            setSaveState('loading')
            const success = await updatePost(selectedPost.id, { post_content: postContent })

            if (success) {
                setSaveState('success')
                setTimeout(() => {
                    setEditMode(false)
                    setSaveState('idle')
                }, 1500)
            } else {
                setSaveState('idle')
            }
        }
    }

    // Handle text area input
    const handleTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setPostContent(e.target.value)
        // Auto-resize the textarea as content changes
        e.target.style.height = 'auto'
        e.target.style.height = `${e.target.scrollHeight}px`
    }

    // Handle thumbs up/down
    const handleThumbsUp = async () => {
        if (selectedPost) {
            await trackEngagement(selectedPost.id, EngagementType.THUMBS_UP)
        }
    }

    const handleThumbsDown = async () => {
        if (selectedPost) {
            setFeedbackModalOpen(true)
        }
    }

    // Handle feedback submission from modal
    const handleFeedbackSubmit = async (feedback: string) => {
        if (selectedPost) {
            await trackEngagement(selectedPost.id, EngagementType.THUMBS_DOWN, feedback)
            setFeedbackModalOpen(false)
        }
    }

    // Track copy engagement
    const handleCopy = async () => {
        if (selectedPost) {
            await trackEngagement(selectedPost.id, EngagementType.COPY)
        }
    }

    if (loading.postDetail) {
        return (
            <div className="flex items-center justify-center h-full">
                <div className="text-center">
                    <div className="spin w-8 h-8 border-4 border-blue-500 border-t-transparent rounded-full mx-auto"></div>
                    <p className="mt-4 text-gray-600">Loading post details...</p>
                </div>
            </div>
        )
    }

    if (!selectedPost) {
        return (
            <div className="flex items-center justify-center h-full">
                <p className="text-gray-500">Select a post to view details</p>
            </div>
        )
    }

    return (
        <div className="p-6 max-w-4xl mx-auto">
            <h1 className="mb-6">{selectedPost.idea_title}</h1>

            <div className="card mb-8">
                <div className="flex justify-between items-center mb-4">
                    <h3>Insight Premise</h3>
                    <div className="flex space-x-3">
                        <CopyButton text={selectedPost.idea_basis} onCopy={handleCopy} />
                    </div>
                </div>
                <div className="p-4 bg-gray-50 rounded-md mb-4">{selectedPost.idea_basis}</div>
                <div className="flex justify-between items-center mb-4">
                    <h3>Post Idea</h3>
                    <div className="flex space-x-3">
                        <CopyButton text={selectedPost.idea_core_idea} onCopy={handleCopy} />
                    </div>
                </div>
                <div className="p-4 bg-gray-50 rounded-md mb-4">{selectedPost.idea_core_idea}</div>
                <div className="flex justify-between items-center mb-4">
                    <h3>Relevance to Target Audience</h3>
                    <div className="flex space-x-3">
                        <CopyButton
                            text={selectedPost.idea_relation_to_target_audience}
                            onCopy={handleCopy}
                        />
                    </div>
                </div>
                <div className="p-4 bg-gray-50 rounded-md">
                    {selectedPost.idea_relation_to_target_audience}
                </div>

                {/* User feedback UI moved from post content to basis */}
                <div className="feedback-buttons mt-4 mb-4 flex items-center justify-between">
                    <div className="flex space-x-4">
                        <button
                            onClick={handleThumbsUp}
                            className={`thumbs-button ${
                                selectedPost.user_feedback_sentiment === 'thumbs_up'
                                    ? 'active-positive'
                                    : ''
                            }`}
                            disabled={loading.engagement}
                        >
                            <span className="text-lg mr-2">👍</span>
                            <span>Like</span>
                        </button>
                        <button
                            onClick={handleThumbsDown}
                            className={`thumbs-button ${
                                selectedPost.user_feedback_sentiment === 'thumbs_down'
                                    ? 'active-negative'
                                    : ''
                            }`}
                            disabled={loading.engagement}
                        >
                            <span className="text-lg mr-2">👎</span>
                            <span>Dislike</span>
                        </button>
                    </div>
                </div>
                {/* Sources Section - Only show if either research or transcripts exist */}
                {(selectedPost.research_results?.length > 0 ||
                    selectedPost.transcript_chapters?.length > 0) && (
                    <SourcesTab
                        researchResults={selectedPost.research_results}
                        transcriptChapters={selectedPost.transcript_chapters}
                    />
                )}
            </div>

            {/* Post content as an example (editable) */}
            <div className="card mb-8">
                <div className="flex justify-between items-center mb-4">
                    <h2>Example Post Content</h2>
                    <div className="flex space-x-3">
                        {editMode ? (
                            <>
                                <button
                                    onClick={handleCancel}
                                    className="btn btn-secondary"
                                    disabled={saveState === 'loading'}
                                >
                                    Cancel
                                </button>
                                <button
                                    onClick={handleSave}
                                    className={`btn ${
                                        saveState === 'loading'
                                            ? 'bg-blue-400'
                                            : saveState === 'success'
                                            ? 'bg-green-500'
                                            : 'btn-primary'
                                    }`}
                                    disabled={saveState === 'loading'}
                                >
                                    {saveState === 'loading'
                                        ? 'Saving...'
                                        : saveState === 'success'
                                        ? 'Saved ✓'
                                        : 'Save'}
                                </button>
                            </>
                        ) : (
                            <>
                                <CopyButton text={selectedPost.post_content} onCopy={handleCopy} />
                                <button
                                    onClick={handleEdit}
                                    className="btn btn-secondary flex items-center"
                                >
                                    ✏️ Edit
                                </button>
                            </>
                        )}
                    </div>
                </div>

                {editMode ? (
                    <textarea
                        ref={textareaRef}
                        value={postContent}
                        onChange={handleTextareaChange}
                        className="text-area"
                        placeholder="Edit post content..."
                    />
                ) : (
                    <div className="p-4 bg-gray-50 rounded-md whitespace-pre-wrap">
                        {selectedPost.post_content}
                    </div>
                )}
            </div>

            {/* Feedback Modal */}
            <FeedbackModal
                isOpen={feedbackModalOpen}
                onClose={() => setFeedbackModalOpen(false)}
                onSubmit={handleFeedbackSubmit}
            />
        </div>
    )
}

export default PostDetail
