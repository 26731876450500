import { EngagementType } from '../../api/dashboard-api'
import { DashboardStoreProvider, useDashboardStore } from '../../context/dashboard/store'
import './Dashboard.css'
import PostDetail from './PostDetail'
import PostList from './PostList'
import React, { useEffect, useRef } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'

// Import dashboard-specific styles

interface UserContentDashboardProps {}

const DashboardContent: React.FC = () => {
    const { userName, userSlug } = useParams<{ userName: string; userSlug: string }>()
    const [searchParams] = useSearchParams()
    const { setUserInfo, fetchPosts, fetchPostDetail, trackEngagement, selectPost, state } =
        useDashboardStore()
    const { selectedPostId, selectedPost, loading, error } = state

    // Get query parameters
    const postId = searchParams.get('post')
    const action = searchParams.get('action')

    // Use a ref to track whether we've performed the action
    const actionPerformedRef = useRef(false)

    // Initial load - fetch posts and handle deeplink parameters
    useEffect(() => {
        if (userName && userSlug) {
            setUserInfo(userName, userSlug)

            // Always fetch the list of posts first
            fetchPosts(userName, userSlug).then((firstPostId) => {
                // If we have a specific post ID from the query params, select and fetch it
                if (postId) {
                    selectPost(postId)
                    fetchPostDetail(postId, userName, userSlug)
                }
                // Otherwise load the first post as usual
                else if (firstPostId) {
                    fetchPostDetail(firstPostId, userName, userSlug)
                }
            })
        }
    }, [userName, userSlug, postId, fetchPosts, fetchPostDetail, selectPost, setUserInfo])

    // Handle action after post is loaded
    useEffect(() => {
        // Only perform the action if:
        // 1. We have a loaded post
        // 2. The post ID matches the one from the URL
        // 3. We have an action parameter
        // 4. We haven't performed the action yet
        if (
            selectedPost &&
            postId &&
            selectedPost.id === postId &&
            action &&
            !actionPerformedRef.current &&
            !loading.engagement
        ) {
            // Mark the action as performed to prevent repeated executions
            actionPerformedRef.current = true

            // Perform the requested action
            if (action === 'thumbs_up') {
                trackEngagement(postId, EngagementType.THUMBS_UP, undefined, userName, userSlug)
                fetchPostDetail(postId, userName, userSlug)
            } else if (action === 'thumbs_down') {
                // For thumbs_down, the feedback modal should open automatically
                // We'll have to rely on the PostDetail component to handle this
                trackEngagement(postId, EngagementType.THUMBS_DOWN, undefined, userName, userSlug)
                fetchPostDetail(postId, userName, userSlug)
            }
        }
    }, [selectedPost, postId, action, loading.engagement, trackEngagement, userName, userSlug])

    if (error.posts) {
        return (
            <div className="h-screen flex items-center justify-center">
                <div className="text-center p-6 rounded-lg bg-red-50 border border-red-200">
                    <h2 className="text-xl font-semibold text-red-700 mb-2">Error</h2>
                    <p className="text-red-600">{error.posts}</p>
                </div>
            </div>
        )
    }

    if (loading.posts && !state.posts.length) {
        return (
            <div className="h-screen flex items-center justify-center">
                <div className="text-center">
                    <div className="w-12 h-12 border-4 border-blue-400 border-t-transparent rounded-full animate-spin mx-auto"></div>
                    <p className="mt-4 text-gray-600">Loading content...</p>
                </div>
            </div>
        )
    }

    return (
        <div className="v2-dashboard flex h-screen">
            <div className="sidebar w-1/4">
                <PostList />
            </div>
            <div className="main-content w-3/4">
                {selectedPostId ? (
                    <PostDetail />
                ) : (
                    <div className="h-full flex items-center justify-center text-gray-500">
                        No posts available
                    </div>
                )}
            </div>
        </div>
    )
}

const UserContentDashboard: React.FC<UserContentDashboardProps> = () => {
    return (
        <DashboardStoreProvider>
            <DashboardContent />
        </DashboardStoreProvider>
    )
}

export default UserContentDashboard
