/**
 * Formats content containing Q&A patterns to enhance readability
 * Adds proper line breaks between questions and answers
 *
 * @param content - The content to format
 * @returns Formatted content with enhanced Q&A formatting
 */
export const formatQAContent = (content: string): string => {
    if (!content) return ''

    // Step 1: Detect Q1/A1 pattern and add line breaks
    let formattedContent = content.replace(/\b(Q\d+:)\s*(.*?)(\s*)(A\d+:)/g, '$1 $2\n\n$4')

    // Step 2: Add line break after answers when followed by another question
    formattedContent = formattedContent.replace(/\b(A\d+:)\s*(.*?)(\s*)(?=Q\d+:)/g, '$1 $2\n\n$3')

    // Step 3: Ensure numbered lists in markdown have a proper line break
    formattedContent = formattedContent.replace(/(\d+\.)\s+(\*\*.*?\*\*)/g, '$1 $2')

    return formattedContent
}
