//import type { MeetingStatus } from '../../types/MeetingDashboard'
import { Badge } from 'flowbite-react'
import { CheckCircle, XCircle, Clock, AlertTriangle, Calendar, Loader2 } from 'lucide-react'

interface MeetingStatusBadgeProps {
    status: string
}

export default function MeetingStatusBadge({ status }: MeetingStatusBadgeProps) {
    switch (status) {
        case 'ready':
            return (
                <Badge color="purple" className="flex items-center gap-1">
                    <Calendar className="w-3 h-3" />
                    <span>Scheduled</span>
                </Badge>
            )
        case 'joining_call':
            return (
                <Badge color="info" className="flex items-center gap-1">
                    <Loader2 className="w-3 h-3 animate-spin" />
                    <span>Connecting</span>
                </Badge>
            )
        case 'in_call_recording':
            return (
                <Badge color="success" className="flex items-center gap-1">
                    <CheckCircle className="w-3 h-3" />
                    <span>Recording</span>
                </Badge>
            )
        case 'in_call_not_recording':
            return (
                <Badge color="warning" className="flex items-center gap-1">
                    <Clock className="w-3 h-3" />
                    <span>In Call Not Recording</span>
                </Badge>
            )
        case 'in_waiting_room':
            return (
                <Badge color="warning" className="flex items-center gap-1">
                    <Clock className="w-3 h-3" />
                    <span>Waiting Room</span>
                </Badge>
            )
        case 'call_ended':
            return (
                <Badge color="success" className="flex items-center gap-1">
                    <CheckCircle className="w-3 h-3" />
                    <span>Call Ended</span>
                </Badge>
            )
        case 'done':
            return (
                <Badge color="success" className="flex items-center gap-1">
                    <CheckCircle className="w-3 h-3" />
                    <span>Done</span>
                </Badge>
            )
        case 'fatal':
            return (
                <Badge color="failure" className="flex items-center gap-1">
                    <XCircle className="w-3 h-3" />
                    <span>Failed</span>
                </Badge>
            )
        case 'calendar_only':
            return (
                <Badge color="purple" className="flex items-center gap-1">
                    <Calendar className="w-3 h-3" />
                    <span>Calendar Only</span>
                </Badge>
            )
        case 'backend_only':
            return (
                <Badge color="indigo" className="flex items-center gap-1">
                    <AlertTriangle className="w-3 h-3" />
                    <span>Backend Only</span>
                </Badge>
            )
        default:
            return (
                <Badge color="gray" className="flex items-center gap-1">
                    <AlertTriangle className="w-3 h-3" />
                    <span>Unknown</span>
                </Badge>
            )
    }
}
