import { ResearchResultDto, TranscriptChapterDto } from '../../api/dashboard-api'
import { formatQAContent } from '../../utils/formatQAContent'
import React, { useState } from 'react'
import ReactMarkdown from 'react-markdown'

interface SourceItemProps {
    type: 'article' | 'conversation'
    content: ResearchResultDto | TranscriptChapterDto
}

const SourceItem: React.FC<SourceItemProps> = ({ type, content }) => {
    const [expanded, setExpanded] = useState(false)

    const isArticle = type === 'article'
    const article = isArticle ? (content as ResearchResultDto) : null
    const conversation = !isArticle ? (content as TranscriptChapterDto) : null

    // Format meeting date for conversations
    const formatDate = (dateString: string) => {
        const date = new Date(dateString)
        return date.toLocaleDateString('en-US', {
            month: 'long',
            day: 'numeric',
            year: 'numeric',
        })
    }

    const title = isArticle ? article!.title : conversation!.meeting.title

    return (
        <div className="border border-gray-200 rounded-lg mb-3">
            <div
                className="flex items-center p-3 cursor-pointer"
                onClick={() => setExpanded(!expanded)}
            >
                <span
                    className={`inline-flex mr-2 items-center px-2 py-1 rounded-full text-xs font-medium ${
                        isArticle ? 'bg-blue-100 text-blue-800' : 'bg-purple-100 text-purple-800'
                    }`}
                >
                    {isArticle ? 'Article' : 'Conversation'}
                </span>
                <div className="flex-1">
                    <h4 className="text-sm font-medium">{title}</h4>
                    {!isArticle && (
                        <div className="text-xs text-gray-500">
                            {formatDate(conversation!.meeting.created_at)}
                        </div>
                    )}
                </div>
                <span className="text-xl">{expanded ? '−' : '+'}</span>
            </div>

            {expanded && (
                <div className="px-4 pb-4 border-t border-gray-100 pt-3">
                    {isArticle ? (
                        // Article content
                        <>
                            <div className="mb-3">
                                <h4 className="text-sm font-medium text-gray-700 mb-1">Summary</h4>
                                <div className="text-gray-600 text-sm prose prose-sm max-w-none">
                                    <ReactMarkdown>
                                        {formatQAContent(article!.summary)}
                                    </ReactMarkdown>
                                </div>
                            </div>

                            {article!.url && (
                                <div className="mt-2">
                                    <a
                                        href={article!.url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-blue-600 hover:text-blue-800 text-sm inline-flex items-center"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-4 w-4 mr-1"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth={2}
                                                d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                                            />
                                        </svg>
                                        View Source
                                    </a>
                                </div>
                            )}
                        </>
                    ) : (
                        // Conversation content
                        <>
                            {conversation!.meeting.attendees &&
                                conversation!.meeting.attendees.length > 0 && (
                                    <div className="mb-3">
                                        <h4 className="text-sm font-medium text-gray-700 mb-1">
                                            Participants
                                        </h4>
                                        <div className="flex flex-wrap gap-1">
                                            {conversation!.meeting.attendees.map((attendee, i) => (
                                                <span
                                                    key={i}
                                                    className="text-xs px-2 py-1 rounded-full bg-blue-100 text-blue-700"
                                                >
                                                    {attendee}
                                                </span>
                                            ))}
                                        </div>
                                    </div>
                                )}

                            <div className="mb-3">
                                <h4 className="text-sm font-medium text-gray-700 mb-1">Summary</h4>
                                <div className="text-gray-600 text-sm prose prose-sm max-w-none">
                                    <ReactMarkdown>
                                        {formatQAContent(conversation!.summary)}
                                    </ReactMarkdown>
                                </div>
                            </div>

                            {conversation!.key_quotes && conversation!.key_quotes.length > 0 && (
                                <div className="mb-3">
                                    <h4 className="text-sm font-medium text-gray-700 mb-1">
                                        Key Quotes
                                    </h4>
                                    <div className="pl-3 border-l-2 border-gray-300 italic text-gray-600 text-sm prose prose-sm max-w-none">
                                        <div className="space-y-2">
                                            {conversation!.key_quotes.map((quote, index) => (
                                                <p key={index} className="text-sm text-gray-700">
                                                    {quote}
                                                </p>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                </div>
            )}
        </div>
    )
}

export default SourceItem
