import { formatDateTime, formatTime, isToday, formatDate } from '../../lib/utils'
import type { Meeting } from '../../types/MeetingDashboard'
import MeetingDetailsModal from './meeting-details-modal'
import MeetingStatusBadge from './meeting-status-badge'
import { Card, Badge, Button, Table, Avatar } from 'flowbite-react'
import { Clock, CheckCircle, XCircle, Video, VideoOff, UserPlus, Clock3 } from 'lucide-react'
import { useState } from 'react'

;('use client')

interface MeetingOverviewProps {
    meetings: Meeting[]
}

export default function MeetingOverview({ meetings }: MeetingOverviewProps) {
    const [selectedMeeting, setSelectedMeeting] = useState<Meeting | null>(null)
    const [isModalOpen, setIsModalOpen] = useState(false)

    const openMeetingDetails = (meeting: Meeting) => {
        setSelectedMeeting(meeting)
        setIsModalOpen(true)
    }

    const activeMeetings = meetings.filter(
        (m) =>
            m.status === 'joining_call' ||
            m.status === 'in_waiting_room' ||
            m.status === 'in_call_not_recording' ||
            m.status === 'in_call_recording',
    )

    const recentMeetings = meetings
        .filter(
            (m) =>
                m.status === 'done' ||
                m.status === 'call_ended' ||
                m.status === 'recording_done' ||
                m.status === 'failed',
        )
        .sort((a, b) => new Date(b.end || b.start).getTime() - new Date(a.end || a.start).getTime())
        .slice(0, 50)

    const upcomingMeetings = meetings
        .filter(
            (m) =>
                (m.status === 'ready' || m.status === 'scheduled') &&
                new Date(m.start).getTime() > Date.now(),
        )
        .sort((a, b) => new Date(a.start).getTime() - new Date(b.start).getTime())
        .slice(0, 50)

    // Function to display date/time with "Today" label if applicable
    const displayDateTime = (dateString: string) => {
        if (isToday(dateString)) {
            return (
                <div>
                    <span className="text-xs font-medium text-blue-600 bg-blue-100 rounded px-1.5 py-0.5 mr-1">
                        Today
                    </span>
                    <span>{formatTime(dateString)}</span>
                </div>
            )
        }
        return formatDateTime(dateString)
    }

    return (
        <div className="space-y-8">
            {/* Stats Overview */}
            <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
                <Card className="flex flex-col items-center justify-center p-4">
                    <div className="flex items-center justify-center w-12 h-12 rounded-full bg-blue-100 mb-4">
                        <Video className="w-6 h-6 text-blue-600" />
                    </div>
                    <h3 className="text-xl font-bold">{activeMeetings.length}</h3>
                    <p className="text-gray-500">Active Meetings</p>
                </Card>

                <Card className="flex flex-col items-center justify-center p-4">
                    <div className="flex items-center justify-center w-12 h-12 rounded-full bg-green-100 mb-4">
                        <CheckCircle className="w-6 h-6 text-green-600" />
                    </div>
                    <h3 className="text-xl font-bold">
                        {meetings.filter((m) => m.status === 'done').length}
                    </h3>
                    <p className="text-gray-500">Successful Joins</p>
                </Card>

                <Card className="flex flex-col items-center justify-center p-4">
                    <div className="flex items-center justify-center w-12 h-12 rounded-full bg-red-100 mb-4">
                        <XCircle className="w-6 h-6 text-red-600" />
                    </div>
                    <h3 className="text-xl font-bold">
                        {
                            meetings.filter((m) => m.status === 'failed' || m.status === 'fatal')
                                .length
                        }
                    </h3>
                    <p className="text-gray-500">Failed Joins</p>
                </Card>

                <Card className="flex flex-col items-center justify-center p-4">
                    <div className="flex items-center justify-center w-12 h-12 rounded-full bg-yellow-100 mb-4">
                        <Clock className="w-6 h-6 text-yellow-600" />
                    </div>
                    <h3 className="text-xl font-bold">
                        {meetings.filter((m) => m.status === 'in_waiting_room').length}
                    </h3>
                    <p className="text-gray-500">In Waiting Room</p>
                </Card>
            </div>

            {/* Active Meetings */}
            <div>
                <h2 className="text-xl font-bold mb-4 flex items-center">
                    <Video className="mr-2 text-blue-600" />
                    Active Meetings
                </h2>

                {activeMeetings.length > 0 ? (
                    <div className="overflow-x-auto">
                        <Table hoverable>
                            <Table.Head>
                                <Table.HeadCell>Meeting</Table.HeadCell>
                                <Table.HeadCell>Host</Table.HeadCell>
                                <Table.HeadCell>Platform</Table.HeadCell>
                                <Table.HeadCell>Date & Time</Table.HeadCell>
                                <Table.HeadCell>Status</Table.HeadCell>
                                <Table.HeadCell>Actions</Table.HeadCell>
                            </Table.Head>
                            <Table.Body className="divide-y">
                                {activeMeetings.map((meeting) => (
                                    <Table.Row key={meeting.id} className="bg-white">
                                        <Table.Cell className="font-medium text-gray-900">
                                            {meeting.title}
                                        </Table.Cell>
                                        <Table.Cell>
                                            <div className="flex items-center">
                                                <Avatar
                                                    size="xs"
                                                    img={
                                                        meeting?.host?.user?.profile_photo?.url ??
                                                        'https://thetrustapp.s3.us-east-2.amazonaws.com/profile-icon-96x96.png'
                                                    }
                                                    rounded
                                                    className="mr-2"
                                                />
                                                {meeting.host.name}
                                            </div>
                                        </Table.Cell>
                                        <Table.Cell>
                                            <Badge
                                                color={
                                                    meeting.meeting_type === 'Zoom'
                                                        ? 'info'
                                                        : meeting.meeting_type === 'Google Meet'
                                                        ? 'success'
                                                        : 'purple'
                                                }
                                            >
                                                {meeting.meeting_type}
                                            </Badge>
                                        </Table.Cell>
                                        <Table.Cell>{displayDateTime(meeting.start)}</Table.Cell>
                                        <Table.Cell>
                                            <MeetingStatusBadge status={meeting.status} />
                                        </Table.Cell>
                                        <Table.Cell>
                                            <Button
                                                size="xs"
                                                color="light"
                                                onClick={() => openMeetingDetails(meeting)}
                                            >
                                                Details
                                            </Button>
                                        </Table.Cell>
                                    </Table.Row>
                                ))}
                            </Table.Body>
                        </Table>
                    </div>
                ) : (
                    <Card>
                        <div className="flex flex-col items-center justify-center p-6">
                            <VideoOff className="w-12 h-12 text-gray-400 mb-2" />
                            <p className="text-gray-500">No active meetings at the moment</p>
                        </div>
                    </Card>
                )}
            </div>

            {/* Upcoming and Recent Meetings */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                {/* Upcoming Meetings */}
                <div>
                    <h2 className="text-xl font-bold mb-4 flex items-center">
                        <Clock3 className="mr-2 text-purple-600" />
                        Upcoming Meetings
                    </h2>

                    {upcomingMeetings.length > 0 ? (
                        <div className="max-h-[480px] overflow-auto">
                            <Card>
                                <div className="flow-root">
                                    <ul className="divide-y divide-gray-200">
                                        {upcomingMeetings.map((meeting) => (
                                            <li key={meeting.id} className="py-3 sm:py-4">
                                                <div className="flex items-center space-x-4">
                                                    <div className="flex-shrink-0">
                                                        <Avatar
                                                            img={
                                                                meeting?.host?.user?.profile_photo
                                                                    ?.url ??
                                                                'https://thetrustapp.s3.us-east-2.amazonaws.com/profile-icon-96x96.png'
                                                            }
                                                            rounded
                                                        />
                                                    </div>
                                                    <div className="flex-1 min-w-0">
                                                        <p className="text-sm font-medium text-gray-900 truncate">
                                                            {meeting.title}
                                                        </p>
                                                        <p className="text-sm text-gray-500 truncate">
                                                            {meeting.host.name} •{' '}
                                                            {meeting.meeting_type}
                                                        </p>
                                                    </div>
                                                    <div className="inline-flex items-center text-base font-semibold text-gray-900">
                                                        <div className="text-right">
                                                            <div className="text-sm font-medium">
                                                                {formatDate(meeting.start)}
                                                            </div>
                                                            <div className="text-sm text-gray-500">
                                                                {formatTime(meeting.start)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </Card>
                        </div>
                    ) : (
                        <Card>
                            <div className="flex flex-col items-center justify-center p-6">
                                <UserPlus className="w-12 h-12 text-gray-400 mb-2" />
                                <p className="text-gray-500">No upcoming meetings</p>
                            </div>
                        </Card>
                    )}
                </div>

                {/* Recent Meetings */}
                <div>
                    <h2 className="text-xl font-bold mb-4 flex items-center">
                        <Clock className="mr-2 text-gray-600" />
                        Recent Meetings
                    </h2>

                    {recentMeetings.length > 0 ? (
                        <div className="max-h-[480px] overflow-auto">
                            <Card>
                                <div className="flow-root">
                                    <ul className="divide-y divide-gray-200">
                                        {recentMeetings.map((meeting) => (
                                            <li key={meeting.id} className="py-3 sm:py-4">
                                                <div className="flex items-center space-x-4">
                                                    <div className="flex-shrink-0">
                                                        <Avatar
                                                            img={
                                                                meeting?.host?.user?.profile_photo
                                                                    ?.url ??
                                                                'https://thetrustapp.s3.us-east-2.amazonaws.com/profile-icon-96x96.png'
                                                            }
                                                            rounded
                                                        />
                                                    </div>
                                                    <div className="flex-1 min-w-0">
                                                        <p className="text-sm font-medium text-gray-900 truncate">
                                                            {meeting.title}
                                                        </p>
                                                        <p className="text-sm text-gray-500 truncate">
                                                            {meeting.host.name} •{' '}
                                                            {meeting.meeting_type}
                                                        </p>
                                                        <p className="text-xs text-gray-400">
                                                            {formatDateTime(meeting.start)}
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <MeetingStatusBadge
                                                            status={meeting.status}
                                                        />
                                                    </div>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </Card>
                        </div>
                    ) : (
                        <Card>
                            <div className="flex flex-col items-center justify-center p-6">
                                <Clock className="w-12 h-12 text-gray-400 mb-2" />
                                <p className="text-gray-500">No recent meetings</p>
                            </div>
                        </Card>
                    )}
                </div>
            </div>

            {/* Meeting Details Modal */}
            {selectedMeeting && (
                <MeetingDetailsModal
                    meeting={selectedMeeting}
                    isOpen={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                />
            )}
        </div>
    )
}
