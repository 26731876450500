import { EngagementType } from '../../api/dashboard-api'
import { DashboardState, DashboardAction, DashboardActionType } from './types'

export const initialState: DashboardState = {
    posts: [],
    selectedPostId: null,
    selectedPost: null,
    totalPosts: 0,
    page: 1,
    limit: 100,
    loading: {
        posts: false,
        postDetail: false,
        updatePost: false,
        engagement: false,
    },
    error: {
        posts: null,
        postDetail: null,
        updatePost: null,
        engagement: null,
    },
    userName: null,
    userSlug: null,
}

export const dashboardReducer = (
    state: DashboardState = initialState,
    action: DashboardAction,
): DashboardState => {
    switch (action.type) {
        case DashboardActionType.SET_USER_INFO:
            return {
                ...state,
                userName: action.payload.userName,
                userSlug: action.payload.userSlug,
            }

        case DashboardActionType.FETCH_POSTS_REQUEST:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    posts: true,
                },
                error: {
                    ...state.error,
                    posts: null,
                },
            }

        case DashboardActionType.FETCH_POSTS_SUCCESS:
            return {
                ...state,
                posts: action.payload.posts,
                totalPosts: action.payload.total,
                page: action.payload.page,
                limit: action.payload.limit,
                loading: {
                    ...state.loading,
                    posts: false,
                },
                // If we don't have a selected post but we just received posts, select the first one
                selectedPostId:
                    state.selectedPostId ||
                    (action.payload.posts.length > 0 ? action.payload.posts[0].id : null),
            }

        case DashboardActionType.FETCH_POSTS_FAILURE:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    posts: false,
                },
                error: {
                    ...state.error,
                    posts: action.payload,
                },
            }

        case DashboardActionType.SELECT_POST:
            return {
                ...state,
                selectedPostId: action.payload,
                selectedPost: state.selectedPost?.id === action.payload ? state.selectedPost : null,
            }

        case DashboardActionType.FETCH_POST_DETAIL_REQUEST:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    postDetail: true,
                },
                error: {
                    ...state.error,
                    postDetail: null,
                },
            }

        case DashboardActionType.FETCH_POST_DETAIL_SUCCESS:
            return {
                ...state,
                selectedPost: action.payload,
                loading: {
                    ...state.loading,
                    postDetail: false,
                },
            }

        case DashboardActionType.FETCH_POST_DETAIL_FAILURE:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    postDetail: false,
                },
                error: {
                    ...state.error,
                    postDetail: action.payload,
                },
            }

        case DashboardActionType.UPDATE_POST_REQUEST:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    updatePost: true,
                },
                error: {
                    ...state.error,
                    updatePost: null,
                },
            }

        case DashboardActionType.UPDATE_POST_SUCCESS:
            return {
                ...state,
                selectedPost: action.payload,
                loading: {
                    ...state.loading,
                    updatePost: false,
                },
            }

        case DashboardActionType.UPDATE_POST_FAILURE:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    updatePost: false,
                },
                error: {
                    ...state.error,
                    updatePost: action.payload,
                },
            }

        case DashboardActionType.TRACK_ENGAGEMENT_REQUEST:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    engagement: true,
                },
                error: {
                    ...state.error,
                    engagement: null,
                },
            }

        case DashboardActionType.TRACK_ENGAGEMENT_SUCCESS:
            // Only update the selected post if the engagement is for the currently selected post
            if (state.selectedPost && state.selectedPost.id === action.payload.postId) {
                let sentiment = null

                // Set sentiment based on engagement type
                if (action.payload.engagementType === EngagementType.THUMBS_UP) {
                    sentiment = 'thumbs_up'
                } else if (action.payload.engagementType === EngagementType.THUMBS_DOWN) {
                    sentiment = 'thumbs_down'
                }

                return {
                    ...state,
                    loading: {
                        ...state.loading,
                        engagement: false,
                    },
                    selectedPost: {
                        ...state.selectedPost,
                        user_feedback_sentiment: sentiment ?? undefined,
                        user_feedback: action.payload.response.feedback,
                    },
                }
            }

            return {
                ...state,
                loading: {
                    ...state.loading,
                    engagement: false,
                },
            }

        case DashboardActionType.TRACK_ENGAGEMENT_FAILURE:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    engagement: false,
                },
                error: {
                    ...state.error,
                    engagement: action.payload,
                },
            }

        default:
            return state
    }
}
