import { MeetingInfoDto } from '../../api/dashboard-api'
import { useDashboardStore } from '../../context/dashboard/store'
import React from 'react'

interface Post {
    id: string
    created_at: string
    idea_title: string
    meeting: MeetingInfoDto | null
}

interface GroupedPosts {
    [key: string]: Post[]
}

const PostList: React.FC = () => {
    const { state, selectPost, fetchPostDetail } = useDashboardStore()
    const { posts, selectedPostId, loading } = state

    const handleSelectPost = (postId: string) => {
        selectPost(postId)
        fetchPostDetail(postId)
    }

    // Function to truncate text with ellipsis
    const truncateText = (text: string, maxLength: number) => {
        if (text.length <= maxLength) return text
        return text.substring(0, maxLength) + '...'
    }

    // Format date in a readable format
    const formatDate = (dateString: string) => {
        const date = new Date(dateString)
        return date.toLocaleDateString('en-US', {
            month: 'short',
            day: 'numeric',
            year: 'numeric',
        })
    }

    // Group posts by meeting
    const groupPostsByMeeting = (posts: Post[]): GroupedPosts => {
        return posts.reduce((groups: GroupedPosts, post: Post) => {
            // If post has no meeting, use "Other Content" as group key
            if (!post.meeting) {
                const groupKey = 'Other Content'
                if (!groups[groupKey]) {
                    groups[groupKey] = []
                }
                groups[groupKey].push(post)
                return groups
            }

            // Use meeting title as the group key
            const meeting = post.meeting
            const groupKey = meeting.title || 'Untitled Meeting'

            if (!groups[groupKey]) {
                groups[groupKey] = []
            }
            groups[groupKey].push(post)
            return groups
        }, {})
    }

    const groupedPosts = groupPostsByMeeting(posts)

    // Sort the groups by meeting date (newest first), placing "Other Content" last
    const sortedGroupEntries = Object.entries(groupedPosts).sort(([, postsA], [, postsB]) => {
        const dateA = postsA[0]?.meeting?.created_at
        const dateB = postsB[0]?.meeting?.created_at

        // Handle cases where one or both groups are "Other Content" (no date)
        if (!dateA && !dateB) return 0 // Both lack dates, keep original relative order
        if (!dateA) return 1 // Place group A ("Other Content") after group B
        if (!dateB) return -1 // Place group A (with date) before group B ("Other Content")

        // Both groups have dates, sort by date descending (newest first)
        return new Date(dateB).getTime() - new Date(dateA).getTime()
    })

    return (
        <div className="h-full">
            <div className="p-6 border-b border-gray-200">
                <h2 className="text-xl font-semibold">Your Content</h2>
            </div>

            {loading.posts ? (
                <div className="flex items-center justify-center h-24">
                    <div className="spin w-6 h-6 border-2 border-blue-500 border-t-transparent rounded-full"></div>
                </div>
            ) : posts.length === 0 ? (
                <div className="p-6 text-center text-gray-500">No content available</div>
            ) : (
                <div className="pb-2">
                    {/* Iterate over the sorted entries */}
                    {sortedGroupEntries.map(([meetingGroup, groupPosts]) => {
                        // Get date from the first post in the group (if available)
                        const firstPost = groupPosts[0]
                        const meetingDate = firstPost.meeting
                            ? formatDate(firstPost.meeting.created_at)
                            : ''

                        return (
                            <div key={meetingGroup} className="mb-6">
                                <div className="px-4 pt-2 bg-gray-100 border-y border-gray-200">
                                    <p className="text-sm font-semibold pt-1 text-gray-800">
                                        {meetingGroup !== 'Other Content'
                                            ? `📅 ${meetingGroup}`
                                            : '📄 Other Content'}
                                    </p>
                                    {meetingDate && (
                                        <p className="text-xs text-gray-500">{meetingDate}</p>
                                    )}
                                </div>
                                <ul>
                                    {groupPosts.map((post) => (
                                        <li
                                            key={post.id}
                                            className={`px-6 py-3 cursor-pointer hover:bg-gray-50 ${
                                                selectedPostId === post.id ? 'bg-gray-50' : ''
                                            }`}
                                            onClick={() => handleSelectPost(post.id)}
                                        >
                                            <div className="text-sm font-medium text-gray-600">
                                                {truncateText(post.idea_title || 'Untitled', 40)}
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )
                    })}
                </div>
            )}
        </div>
    )
}

export default PostList
