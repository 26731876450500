import {
    dashboardApi,
    ListPostsParams,
    UpdatePostDto,
    EngagementType,
    CreateEngagementDto,
} from '../../api/dashboard-api'
import { DashboardAction, DashboardActionType } from './types'
import { Dispatch } from 'react'

// Set user information
export const setUserInfo = (
    dispatch: Dispatch<DashboardAction>,
    userName: string,
    userSlug: string,
) => {
    dispatch({
        type: DashboardActionType.SET_USER_INFO,
        payload: { userName, userSlug },
    })
}

// Fetch posts for a user
export const fetchPosts = async (
    dispatch: Dispatch<DashboardAction>,
    userName: string,
    userSlug: string,
    params?: ListPostsParams,
) => {
    dispatch({ type: DashboardActionType.FETCH_POSTS_REQUEST })
    try {
        const response = await dashboardApi.listPosts(userName, userSlug, params)
        dispatch({
            type: DashboardActionType.FETCH_POSTS_SUCCESS,
            payload: {
                posts: response.data.posts,
                total: response.data.total,
                page: response.data.page,
                limit: response.data.limit,
            },
        })

        // If there are posts and this is the initial load, automatically select the first post
        if (response.data.posts.length > 0) {
            return response.data.posts[0].id
        }

        return null
    } catch (error) {
        dispatch({
            type: DashboardActionType.FETCH_POSTS_FAILURE,
            payload: error instanceof Error ? error.message : 'Failed to fetch posts',
        })
        return null
    }
}

// Select a post
export const selectPost = (dispatch: Dispatch<DashboardAction>, postId: string) => {
    dispatch({
        type: DashboardActionType.SELECT_POST,
        payload: postId,
    })
}

// Fetch post details
export const fetchPostDetail = async (
    dispatch: Dispatch<DashboardAction>,
    userName: string,
    userSlug: string,
    postId: string,
) => {
    dispatch({ type: DashboardActionType.FETCH_POST_DETAIL_REQUEST })

    try {
        const response = await dashboardApi.getPost(userName, userSlug, postId)
        dispatch({
            type: DashboardActionType.FETCH_POST_DETAIL_SUCCESS,
            payload: response.data,
        })
    } catch (error) {
        dispatch({
            type: DashboardActionType.FETCH_POST_DETAIL_FAILURE,
            payload: error instanceof Error ? error.message : 'Failed to fetch post details',
        })
    }
}

// Update post content
export const updatePost = async (
    dispatch: Dispatch<DashboardAction>,
    userName: string,
    userSlug: string,
    postId: string,
    data: UpdatePostDto,
) => {
    dispatch({ type: DashboardActionType.UPDATE_POST_REQUEST })

    try {
        const response = await dashboardApi.updatePost(userName, userSlug, postId, data)
        dispatch({
            type: DashboardActionType.UPDATE_POST_SUCCESS,
            payload: response.data,
        })
        return true
    } catch (error) {
        dispatch({
            type: DashboardActionType.UPDATE_POST_FAILURE,
            payload: error instanceof Error ? error.message : 'Failed to update post',
        })
        return false
    }
}

// Track engagement (thumbs up/down, copy)
export const trackEngagement = async (
    dispatch: Dispatch<DashboardAction>,
    userName: string,
    userSlug: string,
    postId: string,
    type: EngagementType,
    feedback?: string,
) => {
    dispatch({ type: DashboardActionType.TRACK_ENGAGEMENT_REQUEST })

    const data: CreateEngagementDto = {
        type,
        feedback,
    }

    try {
        const response = await dashboardApi.trackEngagement(userName, userSlug, postId, data)
        dispatch({
            type: DashboardActionType.TRACK_ENGAGEMENT_SUCCESS,
            payload: {
                postId,
                engagementType: type,
                response: response.data,
            },
        })
        return true
    } catch (error) {
        dispatch({
            type: DashboardActionType.TRACK_ENGAGEMENT_FAILURE,
            payload: error instanceof Error ? error.message : 'Failed to track engagement',
        })
        return false
    }
}
