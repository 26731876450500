import { formatDateTime, formatTime, isToday } from '../../lib/utils'
import type { CalendarEvent, Meeting } from '../../types/MeetingDashboard'
import GoogleCalendarEmbed from './google-calendar-embed'
import MeetingStatusBadge from './meeting-status-badge'
import { Card, Table, Button, Badge, Tabs } from 'flowbite-react'
import {
    Calendar,
    RefreshCw,
    AlertTriangle,
    CheckCircle,
    Search,
    Filter,
    List,
    Grid,
} from 'lucide-react'
import { useState } from 'react'

;('use client')

interface CalendarSyncMonitorProps {
    calendarEvents: CalendarEvent[]
    backendEvents: Meeting[]
}

export default function CalendarSyncMonitor({
    calendarEvents,
    backendEvents,
}: CalendarSyncMonitorProps) {
    const [dateFilter, setDateFilter] = useState<string>(new Date().toISOString().split('T')[0])
    const [statusFilter, setStatusFilter] = useState<string>('all')
    const [isRefreshing, setIsRefreshing] = useState(false)
    const [activeTab, setActiveTab] = useState<number>(0)

    // Find events that are in calendar but not in backend
    const calendarOnlyEvents = calendarEvents.filter(
        (calEvent) =>
            !backendEvents.some((beEvent) => beEvent.calendarEventId === calEvent.event_id),
    )

    // Find events that are in backend but not in calendar
    const backendOnlyEvents = backendEvents.filter(
        (beEvent) =>
            !calendarEvents.some((calEvent) => calEvent.event_id === beEvent.calendarEventId),
    )

    // Find events that are in both systems
    const syncedEvents = calendarEvents
        .filter((calEvent) =>
            backendEvents.some((beEvent) => beEvent.calendarEventId === calEvent.event_id),
        )
        .map((calEvent) => {
            const backendEvent = backendEvents.find(
                (beEvent) => beEvent.calendarEventId === calEvent.event_id,
            )
            return {
                calendarEvent: calEvent,
                backendEvent: backendEvent,
            }
        })

    const handleRefresh = () => {
        setIsRefreshing(true)
        // Simulate API call
        setTimeout(() => {
            setIsRefreshing(false)
        }, 1500)
    }

    const filterEventsByDate = (events: any[]) => {
        if (!dateFilter) return events

        return events.filter((event) => {
            if (event.start === '') {
                if (event.calendarEvent) {
                    return event.calendarEvent.start === dateFilter
                } else {
                    return false
                }
            }
            const eventDate = new Date('start' in event ? event.start : event.calendarEvent.start)
                .toISOString()
                .split('T')[0]
            return eventDate === dateFilter
        })
    }

    const filteredCalendarOnly = filterEventsByDate(calendarOnlyEvents)
    const filteredBackendOnly = filterEventsByDate(backendOnlyEvents)
    const filteredSynced = filterEventsByDate(syncedEvents)

    const getDisplayEvents = () => {
        switch (statusFilter) {
            case 'calendar_only':
                return {
                    calendarOnly: filteredCalendarOnly,
                    backendOnly: [],
                    synced: [],
                }
            case 'backend_only':
                return {
                    calendarOnly: [],
                    backendOnly: filteredBackendOnly,
                    synced: [],
                }
            case 'synced':
                return {
                    calendarOnly: [],
                    backendOnly: [],
                    synced: filteredSynced,
                }
            case 'all':
            default:
                return {
                    calendarOnly: filteredCalendarOnly,
                    backendOnly: filteredBackendOnly,
                    synced: filteredSynced,
                }
        }
    }

    const { calendarOnly, backendOnly, synced } = getDisplayEvents()

    // Function to display date/time with "Today" label if applicable
    const displayDateTime = (dateString: string) => {
        if (!dateString) return ''

        if (isToday(dateString)) {
            return (
                <div>
                    <span className="text-xs font-medium text-blue-600 bg-blue-100 rounded px-1.5 py-0.5 mr-1">
                        Today
                    </span>
                    <span>{formatTime(dateString)}</span>
                </div>
            )
        }
        return formatDateTime(dateString)
    }

    return (
        <div className="space-y-6">
            <div className="flex justify-between items-center">
                <h2 className="text-xl font-bold flex items-center">
                    <Calendar className="mr-2 text-blue-600" />
                    Calendar Sync Monitor
                </h2>
                <Button color="light" onClick={handleRefresh} disabled={isRefreshing}>
                    <RefreshCw className={`mr-2 h-4 w-4 ${isRefreshing ? 'animate-spin' : ''}`} />
                    Refresh
                </Button>
            </div>

            {/* Stats Cards */}
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                <Card className="flex flex-col items-center justify-center p-4">
                    <div className="flex items-center justify-center w-12 h-12 rounded-full bg-yellow-100 mb-4">
                        <AlertTriangle className="w-6 h-6 text-yellow-600" />
                    </div>
                    <h3 className="text-xl font-bold">{filteredCalendarOnly.length}</h3>
                    <p className="text-gray-500">Calendar Only</p>
                    <p className="text-xs text-gray-400 mt-1">Not sent to backend</p>
                </Card>

                <Card className="flex flex-col items-center justify-center p-4">
                    <div className="flex items-center justify-center w-12 h-12 rounded-full bg-purple-100 mb-4">
                        <AlertTriangle className="w-6 h-6 text-purple-600" />
                    </div>
                    <h3 className="text-xl font-bold">{filteredBackendOnly.length}</h3>
                    <p className="text-gray-500">Backend Only</p>
                    <p className="text-xs text-gray-400 mt-1">Missing calendar record</p>
                </Card>

                <Card className="flex flex-col items-center justify-center p-4">
                    <div className="flex items-center justify-center w-12 h-12 rounded-full bg-green-100 mb-4">
                        <CheckCircle className="w-6 h-6 text-green-600" />
                    </div>
                    <h3 className="text-xl font-bold">{filteredSynced.length}</h3>
                    <p className="text-gray-500">Synced Events</p>
                    <p className="text-xs text-gray-400 mt-1">Present in both systems</p>
                </Card>
            </div>

            {/* View Tabs */}
            <Tabs.Group style="underline" onActiveTabChange={(tab) => setActiveTab(tab)}>
                <Tabs.Item
                    active={activeTab === 0}
                    title={
                        <div className="flex items-center gap-2">
                            <List className="h-5 w-5" />
                            <span>List View</span>
                        </div>
                    }
                >
                    {/* Filters */}
                    <div className="flex flex-col md:flex-row gap-4 mb-6">
                        <div className="flex items-center">
                            <Calendar className="w-5 h-5 text-gray-500 mr-2" />
                            <input
                                type="date"
                                value={dateFilter}
                                onChange={(e) => setDateFilter(e.target.value)}
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5"
                            />
                        </div>

                        <div className="flex items-center">
                            <Filter className="w-5 h-5 text-gray-500 mr-2" />
                            <select
                                value={statusFilter}
                                onChange={(e) => setStatusFilter(e.target.value)}
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5"
                            >
                                <option value="all">All Events</option>
                                <option value="calendar_only">Calendar Only</option>
                                <option value="backend_only">Backend Only</option>
                                <option value="synced">Synced Events</option>
                            </select>
                        </div>
                    </div>

                    {/* Calendar Only Events */}
                    {calendarOnly.length > 0 && (
                        <div className="mb-8">
                            <h3 className="text-lg font-semibold mb-2 flex items-center">
                                <AlertTriangle className="w-4 h-4 text-yellow-600 mr-2" />
                                Calendar Events Not Sent to Backend
                            </h3>
                            <div className="overflow-x-auto">
                                <Table hoverable>
                                    <Table.Head>
                                        <Table.HeadCell>Meeting Title</Table.HeadCell>
                                        <Table.HeadCell>Organizer</Table.HeadCell>
                                        <Table.HeadCell>Platform</Table.HeadCell>
                                        <Table.HeadCell>Date & Time</Table.HeadCell>
                                        <Table.HeadCell>Status</Table.HeadCell>
                                        <Table.HeadCell>Actions</Table.HeadCell>
                                    </Table.Head>
                                    <Table.Body className="divide-y">
                                        {calendarOnly.map((event) => (
                                            <Table.Row key={event.id} className="bg-white">
                                                <Table.Cell className="font-medium text-gray-900">
                                                    {event.title}
                                                </Table.Cell>
                                                <Table.Cell>{event.host}</Table.Cell>
                                                <Table.Cell>
                                                    <Badge
                                                        color={
                                                            event.platform === 'Zoom'
                                                                ? 'info'
                                                                : event.platform === 'Google Meet'
                                                                ? 'success'
                                                                : 'purple'
                                                        }
                                                    >
                                                        {event.platform}
                                                    </Badge>
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {displayDateTime(event.start)}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    <MeetingStatusBadge status="calendar_only" />
                                                </Table.Cell>
                                                <Table.Cell>
                                                    <Button size="xs" color="blue">
                                                        Send to Backend
                                                    </Button>
                                                </Table.Cell>
                                            </Table.Row>
                                        ))}
                                    </Table.Body>
                                </Table>
                            </div>
                        </div>
                    )}

                    {/* Backend Only Events */}
                    {backendOnly.length > 0 && (
                        <div className="mb-8">
                            <h3 className="text-lg font-semibold mb-2 flex items-center">
                                <AlertTriangle className="w-4 h-4 text-purple-600 mr-2" />
                                Backend Events Missing Calendar Record
                            </h3>
                            <div className="overflow-x-auto">
                                <Table hoverable>
                                    <Table.Head>
                                        <Table.HeadCell>Meeting Title</Table.HeadCell>
                                        <Table.HeadCell>Host</Table.HeadCell>
                                        <Table.HeadCell>Platform</Table.HeadCell>
                                        <Table.HeadCell>Date & Time</Table.HeadCell>
                                        <Table.HeadCell>Status</Table.HeadCell>
                                        <Table.HeadCell>Actions</Table.HeadCell>
                                    </Table.Head>
                                    <Table.Body className="divide-y">
                                        {backendOnly.map((meeting) => (
                                            <Table.Row key={meeting.id} className="bg-white">
                                                <Table.Cell className="font-medium text-gray-900">
                                                    {meeting.title}
                                                </Table.Cell>
                                                <Table.Cell>{meeting.host.name}</Table.Cell>
                                                <Table.Cell>
                                                    <Badge
                                                        color={
                                                            meeting.platform === 'Zoom'
                                                                ? 'info'
                                                                : meeting.platform === 'Google Meet'
                                                                ? 'success'
                                                                : 'purple'
                                                        }
                                                    >
                                                        {meeting.platform}
                                                    </Badge>
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {displayDateTime(meeting.start)}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    <MeetingStatusBadge status="backend_only" />
                                                </Table.Cell>
                                                <Table.Cell>
                                                    <Button size="xs" color="light">
                                                        View Details
                                                    </Button>
                                                </Table.Cell>
                                            </Table.Row>
                                        ))}
                                    </Table.Body>
                                </Table>
                            </div>
                        </div>
                    )}

                    {/* Synced Events */}
                    {synced.length > 0 && (
                        <div>
                            <h3 className="text-lg font-semibold mb-2 flex items-center">
                                <CheckCircle className="w-4 h-4 text-green-600 mr-2" />
                                Synced Events
                            </h3>
                            <div className="overflow-x-auto">
                                <Table hoverable>
                                    <Table.Head>
                                        <Table.HeadCell>Meeting Title</Table.HeadCell>
                                        <Table.HeadCell>Organizer/Host</Table.HeadCell>
                                        <Table.HeadCell>Platform</Table.HeadCell>
                                        <Table.HeadCell>Date & Time</Table.HeadCell>
                                        <Table.HeadCell>Backend Status</Table.HeadCell>
                                        <Table.HeadCell>Actions</Table.HeadCell>
                                    </Table.Head>
                                    <Table.Body className="divide-y">
                                        {synced.map((item) => (
                                            <Table.Row
                                                key={item.calendarEvent.id}
                                                className="bg-white"
                                            >
                                                <Table.Cell className="font-medium text-gray-900">
                                                    {item.calendarEvent.title}
                                                </Table.Cell>
                                                <Table.Cell>{item.calendarEvent.host}</Table.Cell>
                                                <Table.Cell>
                                                    <Badge
                                                        color={
                                                            item.calendarEvent.platform === 'Zoom'
                                                                ? 'info'
                                                                : item.calendarEvent.platform ===
                                                                  'Google Meet'
                                                                ? 'success'
                                                                : 'purple'
                                                        }
                                                    >
                                                        {item.calendarEvent.platform}
                                                    </Badge>
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {displayDateTime(item.calendarEvent.start)}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    <MeetingStatusBadge
                                                        status={
                                                            item.backendEvent?.status || 'unknown'
                                                        }
                                                    />
                                                </Table.Cell>
                                                <Table.Cell>
                                                    <Button size="xs" color="light">
                                                        View Details
                                                    </Button>
                                                </Table.Cell>
                                            </Table.Row>
                                        ))}
                                    </Table.Body>
                                </Table>
                            </div>
                        </div>
                    )}

                    {calendarOnly.length === 0 &&
                        backendOnly.length === 0 &&
                        synced.length === 0 && (
                            <Card>
                                <div className="flex flex-col items-center justify-center p-6">
                                    <Search className="w-12 h-12 text-gray-400 mb-2" />
                                    <p className="text-gray-500">
                                        No events found for the selected filters
                                    </p>
                                </div>
                            </Card>
                        )}
                </Tabs.Item>

                <Tabs.Item
                    active={activeTab === 1}
                    title={
                        <div className="flex items-center gap-2">
                            <Grid className="h-5 w-5" />
                            <span>Calendar View</span>
                        </div>
                    }
                >
                    <GoogleCalendarEmbed
                        calendarId="toni@toneai.xyz"
                        title="Toni's Calendar"
                        initialView="week"
                        height={600}
                    />
                </Tabs.Item>
            </Tabs.Group>
        </div>
    )
}
