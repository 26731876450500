import { ClipsApi } from '../api/clips'
import { ClipContentType } from '../enums/ClipContentType'
import { Clip } from '../types/Clip'
import { Tooltip } from 'flowbite-react'
import moment from 'moment'
import { useState } from 'react'

interface ClipCardProps {
    clipData: Clip
    selectedClipId: string
    onClipSelected: () => void
    isPlaying: boolean
    onClipPlayingStopped: () => void
}

const ClipCard = ({
    clipData,
    isPlaying,
    onClipSelected,
    onClipPlayingStopped,
}: ClipCardProps): JSX.Element => {
    const [isSelected, setIsSelected] = useState<boolean>(clipData?.is_selected || false)
    const [isHovered, setHovered] = useState(false)
    const isMobile = window.innerWidth < 768

    const convertDurationToMinutesSeconds = (duration: number) => {
        const minutes = Math.floor(duration / 60)
        const seconds = Math.round(duration % 60)
        let minutesString = minutes.toString()
        let secondsString = seconds.toString()
        if (minutes < 10) {
            minutesString = `0${minutes}`
        }
        if (seconds < 10) {
            secondsString = `0${seconds}`
        }

        return `${minutesString}:${secondsString}s`
    }

    const updateIsSelectedState = async (value: boolean) => {
        setIsSelected(value)
        try {
            await ClipsApi.updateSelectedStatus(clipData?.id || clipData.id, value)
        } catch (err) {
            // eslint-disable-next-line no-console
            console.error('updateIsSelectedState error: ', err)
        }
    }

    let tooltipContent = ''
    if (clipData?.source?.type === ClipContentType.SURVEY_RESPONSE) {
        tooltipContent = `This clip is from a survey response titled ${
            clipData?.source?.source_title
        } from ${clipData?.source?.author} recorder ${
            moment(new Date()).diff(clipData?.source?.created_at, 'hours') > 72
                ? `on ${moment(clipData?.source?.created_at).format('LL')}`
                : moment(clipData?.source?.created_at).fromNow()
        }`
    } else if (clipData?.source?.type === ClipContentType.CONVERSATION) {
        tooltipContent = `This clip is from a conversation titled ${
            clipData?.source?.source_title
        } with ${clipData?.source?.author} recorder ${
            moment(new Date()).diff(clipData?.source?.created_at, 'hours') > 72
                ? `on ${moment(clipData?.source?.created_at).format('LL')}`
                : moment(clipData?.source?.created_at).fromNow()
        }`
    }

    const downloadButtonClicked = async () => {
        try {
            //setLoading(true)
            // Allow user to download the clip using the clipData.url
            clipData.video_url
                ? window.open(clipData.video_url, '_blank')
                : window.open(clipData.url, '_blank')
        } catch (err) {
        } finally {
            //setLoading(false)
        }
    }

    return (
        <div
            className="flex flex-row justify-between items-center"
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
        >
            <div className="flex flex-row w-[40%] justify-start items-center">
                <button onClick={downloadButtonClicked} title="Download clip" className="">
                    <img src="/asset/download-icon.svg" alt="download_button" />
                </button>
                {!isPlaying ? (
                    <button onClick={onClipSelected} className="p-2.5">
                        <img src="/asset/play-circle-outline.svg" />
                    </button>
                ) : (
                    <button onClick={onClipPlayingStopped} className="p-2.5">
                        <img src="/asset/playing-bars.svg" />
                    </button>
                )}
                <p
                    className={`text-sm 2xl:text-base ellipsis-1 break-all ${
                        isPlaying ? 'text-[#007AFF]' : 'text-black'
                    }`}
                >
                    {clipData?.title}
                </p>
            </div>
            <div className="flex w-[10%] justify-start items-center">
                <p className="text-[#001F40] text-sm 2xl:text-base text-black">
                    {convertDurationToMinutesSeconds(clipData?.duration || 0)}
                </p>
            </div>
            <div className="flex w-[10%] justify-start items-center">
                {clipData?.source && !isMobile && (
                    <Tooltip
                        content={tooltipContent}
                        className="text-sm p-5 py-2 max-w-[300px] text-black font-bold font-lato bg-[#f7f7f7]"
                        placement="right"
                        theme={{
                            base: 'absolute inline-block z-10 rounded-lg py-2 px-3 shadow-sm',
                            arrow: {
                                base: 'display-none',
                            },
                        }}
                    >
                        <p className="text-[#001F40] text-sm text-black rounded-[30px] bg-[#D9D9D969] py-2 px-4">
                            Source
                        </p>
                    </Tooltip>
                )}
            </div>
            <div className="flex flex-row w-[10%] gap-10 justify-start items-center">
                <p className="text-[#8092A5] text-sm">
                    {moment(new Date()).diff(clipData.created_at, 'hours') > 72
                        ? moment(clipData.created_at).format('LL')
                        : moment(clipData.created_at).fromNow()}
                </p>
            </div>
            <div
                className="flex justify-start items-center"
                title={clipData?.url ? 'Audio' : 'Video'}
            >
                {clipData?.video_url &&
                    (clipData?.thumbnail_url ? (
                        <img src={clipData?.thumbnail_url} className="w-[48px] h-[36px]" />
                    ) : (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            width="20"
                            height="20"
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="m15.75 10.5 4.72-4.72a.75.75 0 0 1 1.28.53v11.38a.75.75 0 0 1-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 0 0 2.25-2.25v-9a2.25 2.25 0 0 0-2.25-2.25h-9A2.25 2.25 0 0 0 2.25 7.5v9a2.25 2.25 0 0 0 2.25 2.25Z"
                            />
                        </svg>
                    ))}
                {clipData?.url && !!!clipData?.video_url && (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        width="20"
                        height="20"
                        stroke="currentColor"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M19.114 5.636a9 9 0 0 1 0 12.728M16.463 8.288a5.25 5.25 0 0 1 0 7.424M6.75 8.25l4.72-4.72a.75.75 0 0 1 1.28.53v15.88a.75.75 0 0 1-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.009 9.009 0 0 1 2.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75Z"
                        />
                    </svg>
                )}
            </div>
            <div className="flex flex-row w-[10%] justify-start items-center">
                {isSelected && (
                    <button
                        onClick={() => {
                            updateIsSelectedState(false)
                        }}
                        className="p-2.5"
                    >
                        <img src="/asset/check-blue-circle.svg" />
                    </button>
                )}
                {isMobile && !isSelected && (
                    <button
                        onClick={() => {
                            updateIsSelectedState(true)
                        }}
                        className="p-2.5"
                    >
                        <img src="/asset/add-gray-circle-outline.svg" />
                    </button>
                )}
                {!isSelected && isHovered && !isMobile && (
                    <button
                        onClick={() => {
                            updateIsSelectedState(true)
                        }}
                        className="p-2.5"
                    >
                        <img src="/asset/add-gray-circle-outline.svg" />
                    </button>
                )}
            </div>
        </div>
    )
}

export default ClipCard
