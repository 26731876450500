import { CalendarEvent } from '../types/MeetingDashboard'
import ApiService from './apiService'
import { AxiosResponse } from 'axios'

export interface CalendarApi {
    getCalendarEvents(calendarId: string): Promise<AxiosResponse<CalendarEvent[]>>
}

export const CalendarApi: CalendarApi = Object.freeze({
    getCalendarEvents(calendarId: string): Promise<AxiosResponse<CalendarEvent[]>> {
        return ApiService.get(`/calendar/events/${calendarId}`)
    },
})
