import { useAdminStore } from '../../context/admin/store'
import { ITEMS_PER_PAGE } from '../../context/admin/types'
import { DraftPostViewMode, DraftPostStatus, AdminDraftPostDto } from '../../types/admin-types'
import React, { useEffect, useState, useCallback } from 'react'

interface GroupedPosts {
    [key: string]: AdminDraftPostDto[]
}

const DraftPostList: React.FC = () => {
    const {
        state,
        fetchDraftPosts,
        selectDraftPost,
        setViewMode,
        setStatusFilter,
        setCurrentPage,
        selectDraftPostBulk,
        deselectDraftPostBulk,
        clearBulkSelection,
        bulkUpdateStatus,
    } = useAdminStore()

    const {
        draftPosts,
        selectedDraftPostId,
        selectedDraftPostIds,
        viewMode,
        selectedUserId,
        selectedStatus,
        loading,
        totalDraftPosts,
        currentPage,
    } = state

    // State for the status selected in the bulk action dropdown
    const [selectedBulkStatus, setSelectedBulkStatus] = useState<DraftPostStatus | ''>('')

    // Tag filter state
    const [tagFilters, setTagFilters] = useState<string[]>([])
    const [availableTags, setAvailableTags] = useState<string[]>([])

    // Get all available tags from localStorage
    const getAvailableTags = useCallback(() => {
        const tags = new Set<string>()

        // Iterate through all items in localStorage
        for (let i = 0; i < localStorage.length; i++) {
            const key = localStorage.key(i)
            // Check if the key matches our tag pattern
            if (key && key.startsWith('adminTags_')) {
                try {
                    const postTags = JSON.parse(localStorage.getItem(key) || '[]')
                    // Add each tag to our set
                    postTags.forEach((tag: string) => tags.add(tag))
                } catch (error) {
                    console.error('Error parsing tags from localStorage', error)
                }
            }
        }

        return Array.from(tags).sort()
    }, [])

    // Update available tags whenever posts change
    useEffect(() => {
        setAvailableTags(getAvailableTags())
    }, [draftPosts, getAvailableTags])

    // Status filters for the Ideas view
    const ideaStatusFilters = [
        { value: DraftPostStatus.IDEA_UNDER_REVIEW, label: 'To Review' },
        { value: DraftPostStatus.IDEA_REJECTED, label: 'Rejected' },
        { value: DraftPostStatus.PENDING_RETRY, label: 'Flagged for Retry' },
    ]

    // Status filters for the Posts view
    const postStatusFilters = [
        {
            value: 'pending',
            label: 'Pending',
            isSpecial: true,
            statuses: [
                DraftPostStatus.IDEA_APPROVED_WITH_RESEARCH,
                DraftPostStatus.IDEA_APPROVED_WITHOUT_RESEARCH,
            ],
        },
        { value: DraftPostStatus.POST_UNDER_REVIEW, label: 'Ready for Review' },
        { value: DraftPostStatus.POST_APPROVED, label: 'Approved' },
        { value: DraftPostStatus.POST_REJECTED, label: 'Rejected' },
    ]
    const feedbackStatusFilters = [
        { value: DraftPostStatus.IDEA_REJECTED, label: 'Idea Rejected' },
        { value: DraftPostStatus.POST_APPROVED, label: 'Post Approved' },
        { value: DraftPostStatus.POST_REJECTED, label: 'Post Rejected' },
    ]

    // Set default filter based on view mode
    useEffect(() => {
        if (selectedStatus === null) {
            if (viewMode === DraftPostViewMode.IDEAS) {
                setStatusFilter(DraftPostStatus.IDEA_UNDER_REVIEW)
            } else {
                setStatusFilter(DraftPostStatus.POST_UNDER_REVIEW)
            }
        }
    }, [viewMode, selectedStatus])

    // Reset bulk selection and pagination when view mode, user, or status filter changes
    useEffect(() => {
        clearBulkSelection()
        setSelectedBulkStatus('')
        setCurrentPage(1)
    }, [viewMode, selectedUserId, selectedStatus])

    // Format date in a readable format
    const formatDate = (dateString: string) => {
        const date = new Date(dateString)
        return date.toLocaleDateString('en-US', {
            month: 'short',
            day: 'numeric',
            year: 'numeric',
        })
    }

    // Calculate total pages
    const totalPages = Math.ceil(totalDraftPosts / ITEMS_PER_PAGE)

    // Group posts by meeting
    const groupPostsByMeeting = (posts: AdminDraftPostDto[]): GroupedPosts => {
        return posts.reduce((groups: GroupedPosts, post: AdminDraftPostDto) => {
            // If post has no meeting, use "Other Content" as group key
            if (!post.mainMeeting) {
                const groupKey = 'Other Content'
                if (!groups[groupKey]) {
                    groups[groupKey] = []
                }
                groups[groupKey].push(post)
                return groups
            }

            // Use meeting title as the group key
            const meeting = post.mainMeeting
            const groupKey = meeting.title || 'Untitled Meeting'

            if (!groups[groupKey]) {
                groups[groupKey] = []
            }
            groups[groupKey].push(post)
            return groups
        }, {})
    }

    // Handle special filters like "Pending"
    const handleSelectFilter = (filter: any) => {
        if (filter.isSpecial && filter.statuses) {
            // For now, we'll use the first status value when selecting special filters
            setStatusFilter(filter.statuses[0])
        } else {
            setStatusFilter(filter.value)
        }
    }

    // Check if a filter is currently selected
    const isFilterSelected = (filter: any) => {
        if (filter.isSpecial && filter.statuses) {
            // Special case: check if any of the statuses are selected
            return filter.statuses.includes(selectedStatus)
        }
        // Regular case
        return selectedStatus === filter.value
    }

    // Get friendly status label for display
    const getStatusLabel = (status: string): string => {
        switch (status) {
            case DraftPostStatus.IDEA_APPROVED_WITH_RESEARCH:
            case DraftPostStatus.IDEA_APPROVED_WITHOUT_RESEARCH:
                return 'Pending'
            case DraftPostStatus.POST_UNDER_REVIEW:
                return 'Under Review'
            case DraftPostStatus.POST_APPROVED:
                return 'Approved'
            case DraftPostStatus.POST_REJECTED:
                return 'Rejected'
            default:
                return status.replace(/_/g, ' ')
        }
    }

    // Handle page change
    const handlePageChange = (newPage: number) => {
        if (newPage >= 1 && newPage <= totalPages) {
            setCurrentPage(newPage)
        }
    }

    useEffect(() => {
        fetchDraftPosts({
            ...(selectedUserId && { user_id: selectedUserId }),
            ...(selectedStatus && { status: selectedStatus }),
            page: currentPage, // Add current page
            limit: ITEMS_PER_PAGE, // Add limit
        })
    }, [selectedUserId, selectedStatus, viewMode, currentPage])

    // Function to check if a post has any of the selected tags
    const postHasSelectedTags = useCallback((postId: string, selectedTagFilters: string[]) => {
        if (selectedTagFilters.length === 0) return true // If no tag filters, return true

        try {
            const storedTags = localStorage.getItem(`adminTags_${postId}`)
            if (!storedTags) return false

            const postTags = JSON.parse(storedTags) as string[]
            // Check if post has at least one of the selected tags
            return selectedTagFilters.some((tag) => postTags.includes(tag))
        } catch (error) {
            console.error('Error checking post tags', error)
            return false
        }
    }, [])

    // Filter posts according to selected filters
    const filteredPosts = draftPosts.filter((post) => {
        // Filter by user if selected
        if (selectedUserId && post.user.id !== selectedUserId) {
            return false
        }

        // Filter by tags if tag filters are set
        if (!postHasSelectedTags(post.id, tagFilters)) {
            return false
        }

        // For Feedback view, first show posts with any feedback
        if (viewMode === DraftPostViewMode.FEEDBACK) {
            // If there's no feedback at all, don't show the post
            if (!post.admin_feedback && !post.user_feedback) {
                return false
            }
        }

        // For regular statuses, exact match
        if (post.status === selectedStatus) return true

        // For special case "pending" filter
        if (
            viewMode === DraftPostViewMode.POSTS &&
            [
                DraftPostStatus.IDEA_APPROVED_WITH_RESEARCH,
                DraftPostStatus.IDEA_APPROVED_WITHOUT_RESEARCH,
            ].includes(post.status as DraftPostStatus) &&
            [
                DraftPostStatus.IDEA_APPROVED_WITH_RESEARCH,
                DraftPostStatus.IDEA_APPROVED_WITHOUT_RESEARCH,
            ].includes(selectedStatus as DraftPostStatus)
        ) {
            return true
        }

        return false
    })

    // Toggle bulk selection for a post
    const handleToggleBulkSelect = (postId: string, isSelected: boolean) => {
        if (isSelected) {
            deselectDraftPostBulk(postId)
        } else {
            selectDraftPostBulk(postId)
        }
    }

    // Handle selecting a post for detail view
    const handleSelectSinglePost = (postId: string) => {
        selectDraftPost(postId, selectedDraftPostId || '')
        clearBulkSelection() // Clear bulk selection when selecting a single post
    }

    // Handle performing the bulk status update
    const handleBulkUpdate = async () => {
        if (selectedDraftPostIds.length > 0 && selectedBulkStatus) {
            await bulkUpdateStatus(selectedDraftPostIds, selectedBulkStatus)
            // Optional: Reset bulk status selection after initiating update
            setSelectedBulkStatus('')
            // Refetch with current filters after successful update
            fetchDraftPosts({
                ...(selectedUserId && { user_id: selectedUserId }),
                ...(selectedStatus && { status: selectedStatus }),
                page: currentPage,
                limit: ITEMS_PER_PAGE,
            })
        }
    }

    // Group the filtered posts by meeting
    const groupedPosts = groupPostsByMeeting(filteredPosts)

    const sortedGroupEntries = Object.entries(groupedPosts).sort(([, postsA], [, postsB]) => {
        const dateA = postsA[0]?.mainMeeting?.created_at
        const dateB = postsB[0]?.mainMeeting?.created_at

        // Handle cases where one or both groups are "Other Content" (no date)
        if (!dateA && !dateB) return 0 // Both lack dates, keep original relative order
        if (!dateA) return 1 // Place group A ("Other Content") after group B
        if (!dateB) return -1 // Place group A (with date) before group B ("Other Content")

        // Both groups have dates, sort by date descending (newest first)
        return new Date(dateB).getTime() - new Date(dateA).getTime()
    })

    return (
        <div className="w-72 overflow-y-auto border-r border-gray-200 bg-white">
            <div className="p-4 border-b border-gray-200">
                <div className="flex space-x-4 mb-4">
                    <button
                        className={`px-4 py-2 rounded ${
                            viewMode === DraftPostViewMode.IDEAS
                                ? 'bg-blue-500 text-white'
                                : 'bg-gray-200'
                        }`}
                        onClick={() => setViewMode(DraftPostViewMode.IDEAS)}
                    >
                        Ideas
                    </button>
                    <button
                        className={`px-4 py-2 rounded ${
                            viewMode === DraftPostViewMode.POSTS
                                ? 'bg-blue-500 text-white'
                                : 'bg-gray-200'
                        }`}
                        onClick={() => setViewMode(DraftPostViewMode.POSTS)}
                    >
                        Posts
                    </button>
                    <button
                        className={`px-4 py-2 rounded ${
                            viewMode === DraftPostViewMode.FEEDBACK
                                ? 'bg-blue-500 text-white'
                                : 'bg-gray-200'
                        }`}
                        onClick={() => setViewMode(DraftPostViewMode.FEEDBACK)}
                    >
                        Feedback
                    </button>
                </div>

                {viewMode === DraftPostViewMode.IDEAS && (
                    <div className="flex flex-wrap gap-2 mb-4">
                        {ideaStatusFilters.map((filter) => (
                            <button
                                key={filter.value}
                                className={`px-4 py-2 rounded-full text-sm font-medium transition-colors ${
                                    isFilterSelected(filter)
                                        ? 'bg-blue-500 text-white'
                                        : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                                }`}
                                onClick={() => handleSelectFilter(filter)}
                            >
                                {filter.label}
                            </button>
                        ))}
                    </div>
                )}

                {viewMode === DraftPostViewMode.POSTS && (
                    <div className="flex flex-wrap gap-2 mb-4">
                        {postStatusFilters.map((filter) => (
                            <button
                                key={filter.value}
                                className={`px-4 py-2 rounded-full text-sm font-medium transition-colors ${
                                    isFilterSelected(filter)
                                        ? 'bg-blue-500 text-white'
                                        : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                                }`}
                                onClick={() => handleSelectFilter(filter)}
                            >
                                {filter.label}
                            </button>
                        ))}
                    </div>
                )}

                {viewMode === DraftPostViewMode.FEEDBACK && (
                    <div className="flex flex-wrap gap-2 mb-4">
                        {feedbackStatusFilters.map((filter) => (
                            <button
                                key={filter.value}
                                className={`px-4 py-2 rounded-full text-sm font-medium transition-colors ${
                                    isFilterSelected(filter)
                                        ? 'bg-blue-500 text-white'
                                        : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                                }`}
                                onClick={() => handleSelectFilter(filter)}
                            >
                                {filter.label}
                            </button>
                        ))}
                    </div>
                )}

                {/* Tag Filter */}
                {availableTags.length > 0 && (
                    <div className="mb-4">
                        <div className="text-sm font-medium text-gray-700 mb-2">Filter by Tags</div>
                        <div className="flex flex-wrap gap-2 mb-2">
                            {tagFilters.map((tag) => (
                                <div
                                    key={tag}
                                    className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-blue-100 text-blue-800"
                                >
                                    {tag}
                                    <button
                                        className="ml-2 text-blue-600 hover:text-blue-800 focus:outline-none"
                                        onClick={() =>
                                            setTagFilters((current) =>
                                                current.filter((t) => t !== tag),
                                            )
                                        }
                                    >
                                        ×
                                    </button>
                                </div>
                            ))}
                        </div>
                        <select
                            className="w-full px-3 py-2 border border-gray-300 rounded-md"
                            value=""
                            onChange={(e) => {
                                if (e.target.value && !tagFilters.includes(e.target.value)) {
                                    setTagFilters((current) => [...current, e.target.value])
                                }
                                e.target.value = '' // Reset select after selection
                            }}
                        >
                            <option value="">Add a tag filter...</option>
                            {availableTags
                                .filter((tag) => !tagFilters.includes(tag))
                                .map((tag) => (
                                    <option key={tag} value={tag}>
                                        {tag}
                                    </option>
                                ))}
                        </select>
                        {tagFilters.length > 0 && (
                            <button
                                className="mt-2 text-sm text-blue-500 hover:text-blue-700"
                                onClick={() => setTagFilters([])}
                            >
                                Clear all filters
                            </button>
                        )}
                    </div>
                )}

                {/* Bulk Actions Panel - Renders if items are selected */}
                {selectedDraftPostIds.length > 0 && (
                    <div className="p-4 border-y border-gray-200 bg-blue-50 mt-4">
                        <div className="flex justify-between items-center mb-2">
                            <span className="text-sm font-medium text-blue-800">
                                Selected: {selectedDraftPostIds.length} post(s)
                            </span>
                            <button
                                className="text-sm text-blue-600 hover:text-blue-800"
                                onClick={clearBulkSelection}
                            >
                                Clear Selection
                            </button>
                        </div>
                        <div className="flex items-center space-x-2">
                            {/* Simple status select for bulk actions */}
                            <select
                                className={`flex-grow form-select border border-gray-300 rounded px-3 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                                    loading.bulkUpdate ? 'bg-gray-100' : ''
                                }`}
                                value={selectedBulkStatus}
                                onChange={(e) =>
                                    setSelectedBulkStatus(e.target.value as DraftPostStatus)
                                }
                                disabled={loading.bulkUpdate}
                            >
                                <option value="">Change status to...</option>
                                {/* Use status lists similar to StatusToggle */}
                                {(viewMode === DraftPostViewMode.IDEAS
                                    ? [
                                          {
                                              value: DraftPostStatus.IDEA_UNDER_REVIEW,
                                              label: 'Under Review',
                                          },
                                          {
                                              value: DraftPostStatus.IDEA_APPROVED_WITH_RESEARCH,
                                              label: 'Approved with Research',
                                          },
                                          {
                                              value: DraftPostStatus.IDEA_APPROVED_WITHOUT_RESEARCH,
                                              label: 'Approved without Research',
                                          },
                                          {
                                              value: DraftPostStatus.IDEA_REJECTED,
                                              label: 'Rejected',
                                          },
                                          {
                                              value: DraftPostStatus.PENDING_RETRY,
                                              label: 'Pending Retry',
                                          },
                                      ]
                                    : [
                                          {
                                              value: DraftPostStatus.POST_UNDER_REVIEW,
                                              label: 'Ready for Review',
                                          },
                                          {
                                              value: DraftPostStatus.POST_APPROVED,
                                              label: 'Approved',
                                          },
                                          {
                                              value: DraftPostStatus.POST_REJECTED,
                                              label: 'Rejected',
                                          },
                                      ]
                                ).map((status) => (
                                    <option key={status.value} value={status.value}>
                                        {status.label}
                                    </option>
                                ))}
                            </select>
                            <button
                                className="px-3 py-2 bg-blue-500 text-white rounded text-sm hover:bg-blue-600 disabled:bg-gray-400"
                                onClick={handleBulkUpdate}
                                disabled={!selectedBulkStatus || loading.bulkUpdate}
                            >
                                {loading.bulkUpdate ? 'Updating...' : 'Update'}
                            </button>
                        </div>
                        {state.error.bulkUpdate && (
                            <p className="text-xs text-red-500 mt-1">
                                Error: {state.error.bulkUpdate}
                            </p>
                        )}
                    </div>
                )}
            </div>

            {loading.draftPosts ? (
                <div className="flex justify-center p-8">
                    <p>Loading draft posts...</p>
                </div>
            ) : filteredPosts.length === 0 ? (
                <div className="p-4 text-center text-gray-500">No posts match the criteria</div>
            ) : (
                <div>
                    {/* Iterate over the sorted entries */}
                    {sortedGroupEntries.map(([meetingGroup, groupPosts]) => {
                        // Get date from the first post in the group (if available)
                        const firstPost = groupPosts[0]
                        const meetingDate = firstPost?.mainMeeting
                            ? formatDate(firstPost.mainMeeting.created_at)
                            : '' // No date for "Other Content"

                        return (
                            <div key={meetingGroup} className="mb-6">
                                <div className="px-4 py-2 bg-gray-100 border-y border-gray-200">
                                    <h3 className="text-sm font-semibold text-gray-800">
                                        {meetingGroup !== 'Other Content'
                                            ? `📅 ${meetingGroup}`
                                            : '📄 Other Content'}
                                    </h3>
                                    {meetingDate && (
                                        <p className="text-xs text-gray-500">{meetingDate}</p>
                                    )}
                                </div>
                                <ul className="divide-y divide-gray-100 bg-white">
                                    {groupPosts.map((post) => {
                                        const isSelected = selectedDraftPostIds.includes(post.id)
                                        return (
                                            <li
                                                key={post.id}
                                                className={`flex items-center px-4 py-3 cursor-pointer ${
                                                    isSelected
                                                        ? 'bg-yellow-50' // Highlight for bulk selection
                                                        : selectedDraftPostId === post.id
                                                        ? 'bg-blue-50' // Highlight for single selection
                                                        : 'hover:bg-gray-50'
                                                } ${
                                                    // Existing opacity logic (unchanged)
                                                    post.status === DraftPostStatus.POST_APPROVED &&
                                                    post.published_at &&
                                                    post.user?.last_emailed_at &&
                                                    new Date(post.user.last_emailed_at) >
                                                        new Date(post.published_at)
                                                        ? 'opacity-50'
                                                        : ''
                                                }`}
                                                // Remove direct onClick here, handle clicks on children
                                            >
                                                <input
                                                    type="checkbox"
                                                    className="mr-3 h-4 w-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                                                    checked={isSelected}
                                                    // Use onClick and ensure propagation is stopped
                                                    onClick={(e) => {
                                                        e.stopPropagation()
                                                        handleToggleBulkSelect(post.id, isSelected)
                                                    }}
                                                />
                                                <div
                                                    className="flex-grow"
                                                    // Use this div for single selection click
                                                    onClick={() => handleSelectSinglePost(post.id)}
                                                >
                                                    <div className="text-sm font-medium text-gray-600 truncate">
                                                        {post.idea_title}
                                                    </div>
                                                    <div className="text-xs text-gray-500">
                                                        Status: {getStatusLabel(post.status)}
                                                    </div>
                                                </div>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </div>
                        )
                    })}
                </div>
            )}

            {/* Pagination Controls */}
            {totalPages > 1 && (
                <div className="p-4 border-t border-gray-200 flex justify-between items-center">
                    <button
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1 || loading.draftPosts}
                        className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
                    >
                        Previous
                    </button>
                    <span className="text-sm text-gray-700">
                        Page {currentPage} of {totalPages}
                    </span>
                    <button
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage === totalPages || loading.draftPosts}
                        className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
                    >
                        Next
                    </button>
                </div>
            )}
        </div>
    )
}

export default DraftPostList
