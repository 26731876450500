import { User } from './User'

export enum RecallaiMeetingType {
    ZOOM = 'zoom',
    MEET = 'meet',
    TEAMS = 'teams',
    SLACK = 'slack',
}

export interface RecallaiMeeting {
    id?: string
    event_id?: string
    recurring_event_id?: string
    meeting_type: RecallaiMeetingType
    meeting_status: string
    meeting_url: string
    join_at?: string | null
    end_at?: string | null
    meeting_title?: string
    recallai_bot_id?: string
    campaign_id?: string
    author_id: string
    author?: User
    interview_id?: string
    created_at?: string
    updated_at?: string
}

export interface RecallaiMeetings {
    meetings: RecallaiMeeting[]
}
